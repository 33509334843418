import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import SetPassword from "../../components/auth/SetPassword";
import logo from "../../assets/img/logo_dark.png";

const { REACT_APP_BACKEND_URL } = process.env;

const SetPasswordPage = () => {
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let { token } = useParams();

  useEffect(() => {
    fetch(REACT_APP_BACKEND_URL + "auth/resetpassword/" + token, {credentials: "include"})
    .then(res => {
        if (res.ok)
        {
            return res.json();
        }
  
        throw Error(res.status);
    })
    .then(
      (result) => {
        setIsLoaded(true);
        setData(result);
      },
      (error) => {
        setIsLoaded(true);
        if (parseInt(error.message) === 400 || parseInt(error.message) === 404)
        {
          setNotFound(true);
        }
        else if (parseInt(error.message) === 422)
        {
          setExpired(true);
        }
        
        setError(error);
      }
    )
  }, [token])

  if (!isLoaded)
  {
    return (
      <React.Fragment>
        <Helmet title="Reset Password" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <p className="lead">Loading...</p>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (notFound)
  {
    return (
      <React.Fragment>
        <Helmet title="Reset Password" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <p className="lead">That link doesn't appear to be valid. Please make sure to copy and paste the full URL from your email inbox.</p>
          <br />
        </div>
      </React.Fragment>
    );  
  }
  else if (expired)
  {
    return (
      <React.Fragment>
        <Helmet title="Reset Password" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <p className="lead">That link has expired. <a href="/auth/reset-password">Request a new one here</a>.</p>
          <br />
        </div>
      </React.Fragment>
    );  
  }
  else if (error || !data)
  {
    return (
      <React.Fragment>
        <Helmet title="Reset Password" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <p className="lead">Something went wrong; please reload the page...</p>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else 
  {
    return (
      <React.Fragment>
        <Helmet title="Reset Password" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <p className="lead">Hi, <b>{data.firstName}</b>. You can {data.isReset ? "reset" : "set"} your password here.</p>
          <br />
        </div>

        <Card>
          <Card.Body>
            <div className="m-sm-4">
              <SetPassword token={token} isReset={data.isReset} />
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  }
};

export default SetPasswordPage;
