const rawTaskCategories =[
    {"value":"", "label":""},
    {"value":"INITIAL_ENROLLMENT", "label":"Initial Enrollment"},
    {"value":"ANNUAL_ENROLLMENT", "label":"Re-enrollment"},
    {"value": "ATTESTATION", "label":"Attestation"},
    {"value": "INQUIRY", "label":"Inquiry"},
    {"value": "TERMINATION", "label":"Termination"},
    {"value": "OTHER", "label":"Other"},
  ];

export default rawTaskCategories;