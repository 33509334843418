import React, { useState, useEffect } from "react";

import { Card } from "react-bootstrap";

import doctor1 from "../../../assets/img/avatars/logo_unknown_doctor.jpg";

import authenticatedFetch from "../../../utils/fetch"
import getActivityDateTimeDisplay from "../../../utils/datetime"


const Feed = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "task/recentactivity/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  function handleTaskClicked(e, taskId)
  {
    e.preventDefault();
    window.location = "/tasks?task=" + taskId;
  }

  var activityList;
  if (error || !data)
  {
    activityList = <div className="d-flex">Something went wrong... please reload the page...</div>
  }
  else if (!isLoaded)
  {
    activityList = <div className="d-flex">Loading...</div>
  }
  else
  {
    activityList = data.map((activity, i) => {
      var notes = activity.notes;
      if (notes && notes.length > 512)
      {
        notes = notes.substring(0, 510) + "...";
      }
      if (notes && notes.length > 0) {
        notes = notes.split("\n").map(function(item, idx) {
          return ( 
              <span key={idx}>
                  {item}
                  <br />
              </span>
            )
        });
      }

      return (
        <div key={activity.id}> 
            <div style={{cursor: "pointer"}} className="d-flex" onClick={(e) => {handleTaskClicked(e, activity.taskId)}}>
              <img
                src={activity.userPhotoUrl == null || activity.userPhotoUrl === '' ? doctor1 : activity.userPhotoUrl}
                width="36"
                height="36"
                className="rounded-circle me-2"
                alt={activity.userFormattedName}
              />
              <div className="flex-grow-1">
                {/* <small className="float-end text-navy">5m ago</small> */}
                <span style={{fontSize: '0.9em'}}>
                {activity.providerFormattedName == null ? "All Providers" : activity.providerFormattedName}&nbsp;|&nbsp; 
                <span>{activity.carrierName}</span>&nbsp;|&nbsp;
                {activity.practiceName}&nbsp;|&nbsp;{activity.category.label}
                </span>
                <br />
                <strong><i>{notes}</i></strong>
                <br />
                <small className="text-muted">{getActivityDateTimeDisplay(activity.created)} by {activity.userFormattedName}</small>
                <br />
              </div>
            </div>
          <hr />
        </div>
      )
    }, this);
  }

return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <Badge bg="info" className="float-end">
          Today
        </Badge> */}
        <Card.Title className="mb-0">Recent activity</Card.Title>
      </Card.Header>
      <Card.Body>
        {activityList}
      </Card.Body>
    </Card>
  );
}
export default Feed;
