const onboardingStages = [
    "demographics",
    "peer-references",
    "training",
    "work-history",
    "state-licenses",
    "malpractice-insurance",
    "board-certifications",
    "immunizations",
    "documents",
    "signing"
  ];
  
const getOnboardingStages = (preferences) => {
  var stages = onboardingStages;
  if (preferences && !preferences.showTrainingPage) {
    stages = stages.filter((stage) => stage !== "training");
  }
  if (preferences && !preferences.showWorkHistoryPage) {
    stages = stages.filter((stage) => stage !== "work-history");
  }

  return stages;
}

export default getOnboardingStages;
