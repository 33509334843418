import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { useSearchParams } from "react-router-dom";

const { REACT_APP_BACKEND_URL } = process.env;

function SignIn() {
  const navigate = useNavigate();

  const { setIsH3Admin, setAuthorities, setEnvironment, setIsAuthenticated } = useContext(UserSettingsContext);

  const [searchParams] = useSearchParams();

  const [mfaRequired, setMfaRequired] = useState(false);

  const doSignInRequest = async (values, setErrors, setStatus, setSubmitting) => {
    const requestMetadata = {
      method: 'POST',
      headers: {
          'Authorization': 'Basic ' + btoa(values.email + ":" + values.password)
      },
      credentials: 'include'
    };

    if (mfaRequired && values.mfaToken)
    {
      var formData = new FormData();
      formData.append("mfaToken", values.mfaToken);
      formData.append("rememberThisDevice", values.rememberThisDevice);
      requestMetadata.body = formData;
    }
    
    try
    {
      const res = await fetch(REACT_APP_BACKEND_URL + "auth/login", requestMetadata);
      if (res.status === 406)
      {
        setMfaRequired(true);
      }
      else if (res.status === 409 && mfaRequired)
      {
        values.mfaToken = "";
        throw Error("Invalid security code; please check your email and try again");
      }
      else if (res.status === 422 && mfaRequired)
      {
        values.mfaToken = "";
        setMfaRequired(false);  // Start them over - assume maybe re-used and just expired, so let them easily trigger a new one
        throw Error("Security code has expired; please try again" );
      }
      else if (res.status >= 200 && res.status <= 299)
      {
        // Spring doesn't like the login URL returning a body, so do a second call on success here
        const settingsRes = await fetch(REACT_APP_BACKEND_URL + "user/settings", {credentials: 'include'});

        if (settingsRes.status !== 200)
        {
          throw Error(res.statusText);
        }

        const jsonBody = await settingsRes.json();
        setIsAuthenticated(true);
        setIsH3Admin(jsonBody.isH3Admin); // TODO probably treat this like any other role/authority
        setAuthorities(jsonBody.authorities);
        setEnvironment(jsonBody.environment);

        if (jsonBody.isH3Admin)
        {
          if (searchParams.get("destination") && searchParams.get("destination") !== '')
          {
            navigate("/h3admin/revenueteams?destination=" + encodeURIComponent(searchParams.get("destination")));
          }
          else
          {
            navigate("/h3admin/revenueteams");
          }
        }
        else
        {
          if (searchParams.get("destination") && searchParams.get("destination") !== '')
          {
            navigate("/?destination=" + encodeURIComponent(searchParams.get("destination")));
          }
          else
          {
            navigate("/");
          }
        }
      }
      else
      {
        if (res.status >= 400 && res.status < 500)
        {
          throw Error("Email / password did not match");
        }

        throw Error(res.statusText);
      }
      
    }
    catch (error)
    {
      const message = error.message || "Something went wrong";
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        mfaToken: "",
        rememberThisDevice: true,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
        mfaToken: mfaRequired ? Yup.string().max(255).required("Security code is required") : Yup.string().max(0),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setIsAuthenticated(false);
          setIsH3Admin(false);
          setAuthorities([]);
          setEnvironment("dev");

          if (mfaRequired)
          {
            await doSignInRequest(values, setErrors, setStatus, setSubmitting);
          }
          else
          {
            await doSignInRequest(values, setErrors, setStatus, setSubmitting);
          }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              // placeholder="Enter your email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              autoFocus={true}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              // placeholder="Enter your password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
          </Form.Group>
            
          {mfaRequired &&
          <>
          <Form.Group className="mb-3">
            <Form.Label>A security code was emailed to you; enter it here to continue</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="mfaToken"
              value={values.mfaToken}
              isInvalid={Boolean(touched.mfaToken && errors.mfaToken)}
              onBlur={handleBlur}
              onChange={handleChange}
              autoFocus={true}
            />
            {!!touched.mfaToken && (
              <Form.Control.Feedback type="invalid">
                {errors.mfaToken}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div>
            <Form.Check
              name="rememberThisDevice"
              label="Remember this browser for 30 days"
              value={values.rememberThisDevice}
              checked={values.rememberThisDevice}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          </>
          }

          <br />
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
