import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const { REACT_APP_BACKEND_URL } = process.env;

const recaptchaRef = React.createRef();

const ResetPassword = () => {
  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        const reCaptchaToken = await recaptchaRef.current.executeAsync();

        if (reCaptchaToken == null)
        {
          setStatus({ success: false });
          setErrors({ submit: "Please confirm that you are, in fact, not a robot!" });
          setSubmitting(false);
          return;
        }
        
        try {
          const requestMetadata = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              {
                email: values.email,
                reCaptchaToken: reCaptchaToken
              }
            )
          };

          await fetch(REACT_APP_BACKEND_URL + "auth/resetpassword", requestMetadata);

          setStatus({success: "If a matching account was found, an email with a link to reset your password was sent to " + values.email})
        } catch (error) {
          const message = "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          {!status || !status.success ? 
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <br />
            <ReCAPTCHA
              ref={recaptchaRef}
              //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              sitekey="6LfbF9MeAAAAADeTdz_w4_8HsyGwJiBN2N0nTMFR"
              size="invisible"
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          : ''
          }
          {status && status.success ? 
            <div id="success" className="text-center mt-3">
              <h4>Check your inbox!</h4>
              {status.success}<br /><br />
              <a href="/auth/sign-in">Go back</a>
            </div>
          :
          <div id="submitButton" className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset password
            </Button>
          </div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;
