import React, { useState, useContext } from "react";
import { Button, Card, Badge } from "react-bootstrap";
import { Paperclip } from "react-feather";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import FppeTaskModal from "./FppeTaskModal";
import LastActivityDisplay from "./LastActivityDisplay";
import rawTaskStatuses from "./taskStatuses";

const FppeTask = ({ reloadTaskLists, task, showModal=false, showDue=true, hideProviderName=false, hidePracticeName=false, allowModal=true, shortLastActivity=false }) => {  
    const category = task.category.value;
    const badge = task.category.label;
  
    const { isH3Admin, authorities } = useContext(UserSettingsContext);
    const canEdit = isH3Admin || authorities.includes("TASK_EDIT");
  
    const [show, setShow] = useState(showModal);
  
    if (showModal)
    {
      // Clear the task param... note that this will wipe any other url params too; and gets called every time the task is opened until the page is reloaded
      window.history.pushState({}, document.title, window.location.pathname);
    }
  
    const handleShow = () => setShow(true);
    const handleCancel = () => {
      setShow(false);
    }
  
    const handleSave = () => {
      setShow(false);
      reloadTaskLists();
    }
  
    const handleDelete = () => {
      setShow(false);
      reloadTaskLists();
    }

    const handleTaskCardClicked = (e) => {
        if (!allowModal) {
            // Assume coming from a read-only view (i.e. provider screen), take to tasks screen
            window.location = "/tasks?task=" + task.id;
        }
    }
  
    var dueDateDisplay = '';
    var dueBg = 'primary';
    if (showDue && task.deadline != null)
    {
      var dueParts = task.deadline.split("-");
      var dueDate = new Date(dueParts[0], dueParts[1] - 1, dueParts[2]);
      dueDateDisplay = dueDate.toLocaleDateString("en-US");

      const diffInMs   = dueDate - new Date()
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays <= -1)
      {
        dueBg = 'danger';
      }
      else if (diffInDays <= 4)
      {
        dueBg= 'warning';
      }
    }
  
    var statusDisplay = "";
    for (var i = 0; i < rawTaskStatuses.length; i++) {
      if (rawTaskStatuses[i].value === task.status) {
        statusDisplay = rawTaskStatuses[i].shortLabel;
        break;
      }
    }

    var descriptionDisplay = '';
    if (task.description !== null && task.description !== "") {
        descriptionDisplay = task.description.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
          )
      });
    }

    return (
      <Card style={{cursor: !allowModal ? "pointer" : "default"}} onClick={(e) => handleTaskCardClicked(e)} className="mb-3 bg-light border" id={task.id} data-ordering={task.ordering}>
        <Card.Header className="p-3 bg-light ">
        {task.status === "BLOCKED" || task.status === "BLOCKED_INTERNALLY" || task.status === "BLOCKED_SUBMITTED" ?
          <Badge pill className="float-start" bg={task.status === "BLOCKED_INTERNALLY" || task.status === "BLOCKED_SUBMITTED" ? "warning" : "danger"}>
            {statusDisplay}
          </Badge> : ''
          }
         { showDue && task.deadline != null ?
          <>
            <Badge pill className="float-end" bg={dueBg}>{dueDateDisplay}</Badge><div style={{marginBottom: '8px', clear: 'right'}}></div>
          </>
          : <div style={{marginBottom: '8px', clear: "both"}}></div>
         }
          <Card.Title className="mb-0">
            {!hideProviderName && task.providerId !== 0 ? 
              (task.providerIsDeleted ? <>{task.providerFormattedName} <br /></>
              :
              <a href={"/provider/" + task.providerId} style={{color: "#495057"}}>{task.providerFormattedName}<br /></a>)
              
              : ""}
            <span style={{color: '#2B7A78', fontSize: '0.9em'}}>{task.facilityId !== 0 ? <>{task.facilityName}<br /></>: ""}</span>
            <span style={{color: '#6c757d', fontSize: '0.9em'}}>{descriptionDisplay}</span>
            
            {!hidePracticeName ? <div style={{fontSize: "0.8em", fontWeight: "normal", width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}><a href={"/practice/" + task.practiceId} style={{color: "#495057"}}>{task.practiceName}</a></div>: ""}

            { badge != null ?
              <>
                <Badge pill className="float-start" bg="secondary" style={{marginTop: '2px'}}>
                  {badge}
                </Badge> 
                <span className="float-start">&nbsp;</span>
              </>
                : ''
            }
            {
              task.patientCount != null && task.patientCount > 0 ?
              <Badge pill className="float-start" bg="secondary" style={{marginTop: '2px'}}>
                {task.patientCount} Patient{task.patientCount > 1 ? "s" : ""}
              </Badge> : ''
            }
          </Card.Title>
          
        </Card.Header>
        <Card.Body className="p-3" style={{marginTop: '-15px'}}>
          <hr style={{marginTop: '-10px'}} />
          <LastActivityDisplay task={task} shortLastActivity={shortLastActivity} />
          { allowModal ?
          <Button onClick={(e) => handleShow()} variant="primary" size="sm">
            {canEdit ? "Open" : "View Details"}
          </Button>
          : <></>
         }
        <br />
        { allowModal ?
        <FppeTaskModal show={show} onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} id={task.id} category={category} providerId={task.providerIsDeleted ? 0 : task.providerId} 
          facilityId={task.facilityId} description={task.description} patientCount={task.patientCount} 
          reviewerFirstName={task.reviewerFirstName} reviewerLastName={task.reviewerLastName} reviewerEmail={task.reviewerEmail} reviewerCredentials={task.reviewerCredentials} eSignatureTemplateId={task.eSignatureTemplateId}
          providerFormsComplete={task.providerFormsComplete} reviewerFormsComplete={task.reviewerFormsComplete} activeProviderUrl={task.activeProviderUrl} activeReviewerUrl={task.activeReviewerUrl} 
          status={task.status} deadline={task.deadline} archived={task.archived} activityList={task.activity} providerIsDeleted={task.providerIsDeleted}/>
        : <></>
        }
        </Card.Body>
      </Card>
    )
  };

  export default FppeTask;
