import React, { useState, useEffect } from "react";
import {  Form } from "react-bootstrap";
import Select from "react-select";

import authenticatedFetch from "../utils/fetch"


const TaskAssigneeSelect = ({ disabled, handleAssigneesChanged, selectedAssignees, placeholder="Select...", autoSelectFirstAssignee=false, taskId=null}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [options, setOptions] = useState([]);


    useEffect(() => {
        authenticatedFetch({
        path: "task/availableassignees",
        additionalHeaders: {"taskId": taskId},
        successHandler: function(result) {
            setIsLoaded(true);

            const newOptions = [];
            for (var i=0; i<result.length; i++)
            {
                var label = result[i].firstName + ' ' + result[i].lastName;
                if (result[i].isH3Admin)
                {
                    label = label + " (H3)";
                }
    
                newOptions.push({value: result[i].userId, label:label});
                setOptions(newOptions)
            }
    
            // Have to do this after the select is rendered
            if (selectedAssignees === null)
            {
                var defaultSelectedAssignees = [];
                if (autoSelectFirstAssignee && newOptions.length > 0)
                {
                    defaultSelectedAssignees = [newOptions[0]];
                }
    
                handleAssigneesChanged(defaultSelectedAssignees);
            }
        },
        errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
        }
        });
    }, [selectedAssignees])

    if (!isLoaded)
    {
        return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !options)
    {
        return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const customStyles = {
            valueContainer: (styles) => ({
                ...styles,
                maxHeight: "125px",
                overflowY: "auto"
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                padding: "0 2px 0 0",
            }),
            clearIndicator: (styles) => ({
                ...styles,
                padding: 0,
            }),
        }

        return (
        <Select
            options={options}
            styles={customStyles}
            isDisabled={disabled} 
            onChange={(e) => {handleAssigneesChanged(e)}}
            value={selectedAssignees}
            placeholder={placeholder}
            isMulti
            theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
        />
        )
    }
}

export default TaskAssigneeSelect;