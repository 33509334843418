import React, { useContext  } from "react";
import { Navigate } from "react-router-dom";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  // const { isAuthenticated, isInitialized } = useAuth();

  const { isAuthenticated } = useContext(UserSettingsContext);

  // Could make a similar guard for H3 admin only pages as we add those

  if (!isAuthenticated) {
    if (window.location.pathname != null && window.location.pathname.length > 1)
    {
      return <Navigate to={"/auth/sign-in?destination=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)} />;
    }
    else
    {
      return <Navigate to={"/auth/sign-in"}/>;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
