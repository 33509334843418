import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Badge, Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import authenticatedFetch from "../../utils/fetch"
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { ExternalLink } from "react-feather";
import CopyToClipboardToggle from "../../utils/CopyToClipboardToggle";
import getActivityDateTimeDisplay from "../../utils/datetime";

import doctor1 from "../../assets/img/avatars/logo_unknown_doctor.jpg";


const User = ({ photoUrl, id, lastName, firstName, email, cellPhone, authorities, restrictedToPractices, activePasswordResetUrl, lastLogin, refreshUserList }) => {
  const notyf = useContext(NotyfContext);
  const { isH3Admin } = useContext(UserSettingsContext);

  const [isGeneratingPasswordResetLink, setIsGeneratingPasswordResetLink] = useState(false);

  const handleGeneratePasswordResetLinkClicked = (e) => {
    e.preventDefault();
    setIsGeneratingPasswordResetLink(true);

    authenticatedFetch({
      method: "POST",
      path: "auth/generateresetpasswordtoken/" + id,
      successHandler: function() {
        setIsGeneratingPasswordResetLink(false);
        refreshUserList();
        notyf.open({type: "success", message: "URL generated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        setIsGeneratingPasswordResetLink(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleCopyPasswordResetLinkClicked = () => {
    // No op
  }

  var permissionsBadges;
  if (authorities)
  {
    permissionsBadges = authorities.map((authority) => {
      return (
        <span key={authority}>
          <Badge bg={authority.toLowerCase().indexOf("edit") > 0 ? "primary" : "secondary"} pill>{authority}</Badge>&nbsp;
        </span>
      )
    }, this);
  }

  var restrictedToPracticesBadges;
  if (restrictedToPractices)
  {
    restrictedToPracticesBadges = restrictedToPractices.map((practiceName, i) => {
      return (
        <span key={i}>
          <Badge bg="warning" pill>{practiceName}</Badge>&nbsp;
        </span>
      )
    }, this);
  }
  
  return (
    <tr>
        <td>
        <img
            src={photoUrl == null || photoUrl === '' ? doctor1 : photoUrl}
            width="48"
            height="48"
            className="rounded-circle me-2"
            alt="Avatar"
        />
        </td>
        <td>{lastName + ", "}{firstName}
        </td>
        <td>{email}</td>
        <td style={{fontFamily: "monospace", whiteSpace: "nowrap"}}>{cellPhone}</td>
        <td>{permissionsBadges}
        { restrictedToPractices && restrictedToPractices.length > 0 ?
        <><br /><b>Restricted to:</b> {restrictedToPracticesBadges}</>
        :<></>
        }
        </td>
        <td>{lastLogin ? getActivityDateTimeDisplay(lastLogin) : "(n/a)"}</td>
        {isH3Admin ?
        <>
          {isGeneratingPasswordResetLink ?
          <td><Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/></td>
          :
          <td>{activePasswordResetUrl != null && activePasswordResetUrl.length > 0 ? <><a href={activePasswordResetUrl} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/></a>&nbsp;&nbsp;<CopyToClipboardToggle text={activePasswordResetUrl} onCopyValue={handleCopyPasswordResetLinkClicked} placement="bottom"/></>: <Button onClick={(e) => {handleGeneratePasswordResetLinkClicked(e)}} size="sm">Generate</Button>}</td>
          }
        </>
        : <></>
        }
    </tr>
  );
}

const UserList = ({practiceId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { isH3Admin } = useContext(UserSettingsContext);

  useEffect(() => {
    refreshUserList();
  }, [practiceId])

  const refreshUserList = () => {
    authenticatedFetch({
      path: "user/revenueteam/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  var userList;

  if (error)
  {
    userList = <tr><td colSpan="5">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    userList = <tr><td colSpan="5">Loading...</td></tr>;
  }
  else if (!data || data.length === 0)
  {
    userList = <tr><td colSpan="5">No users found...</td></tr>;
  }
  else
  {
    userList = data.map((user) => {
      return (
        <User key={user.id} id={user.id} photoUrl={user.photoUrl} lastName={user.lastName} firstName={user.firstName} email={user.email} cellPhone={user.cellPhone} authorities={user.authorities} restrictedToPractices={user.restrictedToPractices} activePasswordResetUrl={user.activePasswordResetUrl} lastLogin={user.lastLogin} refreshUserList={refreshUserList}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
      </Card.Header>
      <Table striped hover>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td>Name</td>
            <td>Email</td>
            <td>Cell Phone</td>
            <td>Permissions</td>
            <td>Last Login</td>
            {isH3Admin && <td>Active Password Reset Link (H3-only)</td>}
          </tr>
        </thead>
        <tbody>
          {userList}
        </tbody>
      </Table>
    </Card>
  )
};


const UserManagement = () => {

  return (
    <Row>
      <Helmet title="Users"/>
      <h1 className="h3 mb-3">User Management</h1>
      <Col lg="12">
        <UserList />
      </Col>
    </Row>
  )
};

export default UserManagement;
