const rawCMECategories =[
    {"value":"", "label":""},
    {"value":"CARDIOLOGY", "label":"Cardiology"},
    {"value":"CARDIOTHORACIC", "label":"Cardiothoracic"},
    {"value": "CT", "label":"CT"},
    {"value": "ECHO", "label":"Echo"},
    {"value": "GENERAL", "label":"General"},
    {"value": "MRI", "label":"MRI"},
    {"value": "MULTI", "label":"Multi"},
    {"value": "NUCLEAR", "label":"Nuclear"},
    {"value": "ONCOLOGY", "label":"Oncology"},
    {"value": "OPHTHALMOLOGY", "label":"Ophthalmology"},
    {"value": "OPIOID", "label":"Opioid"},
    {"value": "OTHER", "label":"Other"},
    {"value": "PATIENT_SAFETY", "label":"Patient Safety"},
    {"value": "SURGERY", "label":"Surgery"}
];

export default rawCMECategories;