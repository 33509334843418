import React, { useState } from "react";
import Select from "react-select";
import usStateOptions from "./usStateOptions";

const UsStateSelect = ({originalValue, name, onValueChanged }) => {
  const formattedLocalValue = []
  if (originalValue != null && originalValue !== "")
  {
    formattedLocalValue.push({ value: originalValue, label: originalValue });
  }

  const [localValue, setLocalValue] = useState(formattedLocalValue);


  const onLocalValueChanged = (newValue) => {
    setLocalValue(newValue)
    onValueChanged(name, newValue.value)
  }


  return (
    <div style={{width: "95%"}}>
      <Select
        options={usStateOptions}
        value={localValue}
        onChange={(e) => {onLocalValueChanged(e)}} 
        isSearchable
        theme={(theme) => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary50: '#DEF2F1',
            primary25: '#DEF2F1',
            primary: '#3AAFA9',
          },
        })}
      />
    </div>
  );
}

export default UsStateSelect;
