import React, { useState, useEffect } from "react";


import authenticatedFetch from "../utils/fetch"


const RevenueTeamLabel = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "revenueteam/current",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<>Loading...</>);
  }
  else if (error || !data)
  {
    return (<>Error... Please reload the page...</>);
  }
  else
  {
    return (
      <>
      {data.name}
      </>
    );
  }
};

export default RevenueTeamLabel;
