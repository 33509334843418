import React, {useState} from "react";
import Select from "react-select";

const TableMultiSelectColumnFilter = ({filterValue, setFilter, rawCategories, showNoCategory=true, width="215px"}) => {

    const[maxHeight, setMaxHeight] = useState(30);

    // Remove the blank option
    const options = React.useMemo(() => {
        const options = new Set();
        rawCategories.forEach((rawCategory) => {
        if (rawCategory.value !== "")
          {
            options.add(rawCategory);
          }
        });
        return [...options.values()];
    }, []);

    const customStyles = {
        valueContainer: (styles) => ({
            ...styles,
            maxHeight: maxHeight,
            overflowY: "auto"
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0 2px 0 0",
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: 0,
        }),
    }

    return (
        <div style={{width: width}}>
            <Select
                options={options}
                styles={customStyles}

                onChange={(selectedFilters) => {
                    if (selectedFilters.length === 0)
                    {
                        setFilter(undefined);
                    } else{
                        setFilter(selectedFilters);
                    }
                }}
                onFocus={() => {
                    setMaxHeight(160);
                }}
                onBlur={() => {
                    setMaxHeight(30);
                }}
                value={filterValue}
                placeholder="Filter..."
                isMulti
                theme={(theme) => ({
                    ...theme,
                    colors: {
                    ...theme.colors,
                        primary50: '#DEF2F1',
                        primary25: '#DEF2F1',
                        primary: '#3AAFA9',
                    },
                })}
            />
        </div>
    );
}

export default TableMultiSelectColumnFilter;
