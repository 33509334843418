import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Trash2} from "react-feather";
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;


const AddPeerReferenceButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("peerReferences", [
        ...values.peerReferences,
        {firstName: "", lastName: "", notes: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Another</Button>
  );
}

const RemovePeerReferenceButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("peerReferences", values.peerReferences.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}


const OnboardingPeerReferences = ({stage, token, data}) => {
  var initialValues = {peerReferences:[]}

  if (data && data.peerReferences)
  {
    for (var i = 0; i < data.peerReferences.length; i++)
    {
      if (data.peerReferences[i].firstName == null)
      {
        data.peerReferences[i].firstName = "";
      }
      if (data.peerReferences[i].lastName == null)
      {
        data.peerReferences[i].lastName = "";
      }
      if (data.peerReferences[i].notes == null)
      {
        data.peerReferences[i].notes = "";
      }
    
      initialValues.peerReferences.push(data.peerReferences[i]);
    }
  }

  for (i=initialValues.peerReferences.length; i < 3; i++)
  {
    initialValues.peerReferences.push({firstName: "", lastName: "", notes: ""});
  }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        peerReferences: Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().max(255).required("First name is required"),
            lastName: Yup.string().max(255).required("Last name is required"),
            notes: Yup.string().max(2047).required("Contact information is required"),
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              peerReferences: values.peerReferences
            })], {
              type: "application/json"
          }));

          // No files, but do it the same way so we can keep the backend consistent

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Peer References*
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Minimum of three required. References must be of the same provider type as yourself, <i>unless</i> you are a new grad, in which case one reference must be the program director from your most recent education.</span>
            </Form.Label>
            <br />
            {values.peerReferences.map((peerReference, index) => (
                <div key={index}>
                <i>#{index +1}</i>
                {index > 2 && <RemovePeerReferenceButton index={index} />}
                <br />
                <Form.Control
                  placeholder="First Name"
                  {...getFieldProps(`peerReferences[${index}].firstName`)}
                  isInvalid={Boolean(touched.peerReferences?.[index]?.firstName && errors.peerReferences?.[index]?.firstName)}
                />
                {!!touched.peerReferences?.[index]?.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.peerReferences?.[index]?.firstName}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Last Name"
                  {...getFieldProps(`peerReferences[${index}].lastName`)}
                  isInvalid={Boolean(touched.peerReferences?.[index]?.lastName && errors.peerReferences?.[index]?.lastName)}
                />
                {!!touched.peerReferences?.[index]?.lastName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.peerReferences?.[index]?.lastName}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  as="textarea"
                  style={{height: "100px"}}
                  placeholder="Complete contact information (including address, phone, and email address)"
                  {...getFieldProps(`peerReferences[${index}].notes`)}
                  isInvalid={Boolean(touched.peerReferences?.[index]?.notes && errors.peerReferences?.[index]?.notes)}
                />
                {!!touched.peerReferences?.[index]?.notes && (
                  <Form.Control.Feedback type="invalid">
                    {errors.peerReferences?.[index]?.notes}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            ))}
            <AddPeerReferenceButton />
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
            <div className="text-center mt-3 float-start">
              <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
            </div>
            <div id="submitButton" className="text-center mt-3 float-end">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
              >
                Save and Continue
              </Button>
            </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingPeerReferences;
