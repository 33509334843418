import React, { useState, useContext } from "react";
import { Card, Col, Row, Table, Form, Modal, Button, Spinner} from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import EditableExpires from "../../utils/EditableExpires";


const LoginAuditModal = ({ show, onClose, runSuccess}) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleStartDateChanged = (value) => {
    setStartDate(value);
  }

  const handleEndDateChanged = (value) => {
      setEndDate(value);
  }


  const handleExportClicked = (e) => {
      e.preventDefault();
      // setIsExporting(true);
  
      // authenticatedFetch({
      //   method: "POST",
      //   path:  "report/providerroster",
      //   requestData: {
      //     practiceIds: practiceIds,
      //     includeInactive: includeInactive,
      //   },
      //   successHandler: function(result) {
      //     window.open(result.url);
      //     setIsExporting(false);

      //     runSuccess("providerroster")
      //     onClose();
      //   },
      //   errorHandler: function(error) {
      //     setIsExporting(false);
      //     notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      //   }
      // });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Login Audit</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                <td>
                  Date Range:  
                </td>
                <td>
                <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={startDate} localActive={true} editingExpires={true} handleExpiresChanged={handleStartDateChanged} /></Form.Label>
                  &nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;
                <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={endDate} localActive={true} editingExpires={true} handleExpiresChanged={handleEndDateChanged} /></Form.Label>
                </td>  
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ChangeAuditModal = ({ show, onClose, runSuccess}) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleStartDateChanged = (value) => {
    setStartDate(value);
  }

  const handleEndDateChanged = (value) => {
      setEndDate(value);
  }


  const handleExportClicked = (e) => {
      e.preventDefault();
      // setIsExporting(true);
  
      // authenticatedFetch({
      //   method: "POST",
      //   path:  "report/providerroster",
      //   requestData: {
      //     practiceIds: practiceIds,
      //     includeInactive: includeInactive,
      //   },
      //   successHandler: function(result) {
      //     window.open(result.url);
      //     setIsExporting(false);

      //     runSuccess("providerroster")
      //     onClose();
      //   },
      //   errorHandler: function(error) {
      //     setIsExporting(false);
      //     notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      //   }
      // });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Change Audit</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                <td>
                  Date Range:  
                </td>
                <td>
                <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={startDate} localActive={true} editingExpires={true} handleExpiresChanged={handleStartDateChanged} /></Form.Label>
                  &nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;
                <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={endDate} localActive={true} editingExpires={true} handleExpiresChanged={handleEndDateChanged} /></Form.Label>
                </td>  
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}


const ReportList = () => {
    const [showLoginAuditModal, setShowLoginAuditModal] = useState(false);
    const [showChangeAuditModal, setShowChangeAuditModal] = useState(false);

    const [lastRunRefresh, setLastRunRefresh] = useState(0);

    const { isH3Admin, authorities } = useContext(UserSettingsContext);

    const runSuccess = (e) => {
      // setLastRunRefresh(lastRunRefresh + 1);
    }

    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
            <Card.Title>
            Audit Reports
            </Card.Title>
          </Card.Header>
        <Table striped hover>
          <thead>
            <tr>
              <td>Report Type</td>
              <td>Available Formats</td>
              <td>Last Run</td>
            </tr>
          </thead>
          <tbody>
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowLoginAuditModal(true)}}>
                <td><a href="#">Login Audit</a></td>
                <td>.csv, .pdf</td>
                {/* <td><LastRun reportType="providerroster" refresh={lastRunRefresh}/></td> */}
                <td>10/11/2023 @ 3:17 PM</td>
            </tr>
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowChangeAuditModal(true)}}>
                <td><a href="#">Change Audit</a></td>
                <td>.csv, .pdf</td>
                {/* <td><LastRun reportType="providerroster" refresh={lastRunRefresh}/></td> */}
                <td>10/14/2023 @ 10:20 AM</td>
            </tr>
          </tbody>
        </Table>
        <LoginAuditModal show={showLoginAuditModal} onClose={() => setShowLoginAuditModal(false)} runSuccess={runSuccess} />
        <ChangeAuditModal show={showChangeAuditModal} onClose={() => setShowChangeAuditModal(false)} runSuccess={runSuccess} />
      </Card>
    )
  };

const AuditReports = () => {

    return (
      <Row>
        <Helmet title="Audit Reports"/>
        <Col lg="12">
          <ReportList />
        </Col>
      </Row>
    )
  };
  
  export default AuditReports;
  