import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import OnboardingLayout from "./layouts/Onboarding";
import ConsentLayout from "./layouts/Consent";
import FppeProviderLayout from "./layouts/FppeProvider";
import FppeReviewerLayout from "./layouts/FppeReviewer";
import NetworkLayout from "./layouts/Network";
import DashboardLayout from "./layouts/Dashboard";
import H3AdminLayout from "./layouts/H3Admin";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import H3AdminGuard from "./components/guards/H3AdminGuard";

// Dashboards
import Default from "./pages/dashboards/Default";


// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import SetPassword from "./pages/auth/SetPassword";

// Onboarding
import Onboarding from "./pages/onboarding/Onboarding";

// Consent
import Consent from "./pages/consent/Consent";

// FPPE
import FppeProvider from "./pages/fppe/FppeProvider";
import FppeReviewer from "./pages/fppe/FppeReviewer";

// Network
import Network from "./pages/network/Network";

import Practice from "./pages/Practice"
import Provider from "./pages/Provider"
import Tasks from "./pages/Tasks";
import FppeTasks from "./pages/FppeTasks";
import ProviderManagement from "./pages/admin/ProviderManagement";
import UserManagement from "./pages/admin/UserManagement";
import CarrierManagement from "./pages/admin/CarrierManagement";
import FacilityManagement from "./pages/admin/FacilityManagement";
import RevenueTeams from "./pages/h3admin/RevenueTeams";
import Messaging from "./pages/Messaging";
import MessagingTemplates from "./pages/MessagingTemplates";
import Profile from "./pages/Profile";

import KPIs from "./pages/reports/KPIs";
import ProviderReports from "./pages/reports/ProviderReports";
import PracticeReports from "./pages/reports/PracticeReports";
import TaskReports from "./pages/reports/TaskReports";
import AuditReports from "./pages/reports/AuditReports";

import InboundFaxes from "./pages/referrals/InboundFaxes";
import Scheduling from "./pages/referrals/Scheduling";

// Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "practice/:id",
    element:  (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Practice />,
      },
    ],
  },
  {
    path: "provider/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Provider />,
      },
    ],
  },
  {
    path: "tasks",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "fppetasks",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <FppeTasks />,
      },
    ],
  },
  {
    path: "reports/kpis",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <KPIs />,
      },
    ],
  },
  {
    path: "reports/provider",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProviderReports />,
      },
    ],
  },
  {
    path: "reports/practice",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PracticeReports />,
      },
    ],
  },
  {
    path: "reports/task",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TaskReports />,
      },
    ],
  },
  {
    path: "reports/audit",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AuditReports />,
      },
    ],
  },
  {
    path: "messaging",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Messaging />,
      },
    ],
  },
  {
    path: "messagingtemplates",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MessagingTemplates />,
      },
    ],
  },
  {
    path: "profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
  {
    path: "admin/providers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProviderManagement />,
      },
    ],
  },
  {
    path: "admin/users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UserManagement />,
      },
    ],
  },
  {
    path: "admin/carriers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CarrierManagement />,
      },
    ],
  },
  {
    path: "admin/locations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <FacilityManagement />,
      },
    ],
  },
  {
    path: "referrals/inbound-faxes",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <InboundFaxes />,
      },
    ],
  },
  {
    path: "referrals/scheduling",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Scheduling />,
      },
    ],
  },
  {
    path: "h3admin/revenueteams",
    element: (
      <AuthGuard>
        <H3AdminGuard>
          <H3AdminLayout />
        </H3AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <RevenueTeams />,
      },
    ],
  },
  {
    path: "password",  // TODO move other auth stuff up here
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SetPassword />,
      },
      {
        path: ":token",
        element: <SetPassword />,
      }
    ]
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "onboarding",
    element: <OnboardingLayout />,
    children: [
      {
        path: ":stage/:token",
        element: <Onboarding />,
      },
      {
        path: ":stage",
        element: <Onboarding />,
      }
    ]
  },
  {
    path: "consent",
    element: <ConsentLayout />,
    children: [
      {
        path: ":token",
        element: <Consent />,
      }
    ]
  },
  {
    path: "fppe/provider",
    element: <FppeProviderLayout />,
    children: [
      {
        path: ":token",
        element: <FppeProvider />,
      }
    ]
  },
  {
    path: "fppe/reviewer",
    element: <FppeReviewerLayout />,
    children: [
      {
        path: ":token",
        element: <FppeReviewer />,
      }
    ]
  },
  {
    path: "network",
    element: <NetworkLayout />,
    children: [
      {
        path: ":token",
        element: <Network />,
      }
    ]
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
