import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Form, Modal, Button, Spinner} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import authenticatedFetch from "../../utils/fetch"
import rawCMECategories from "../../utils/cmeCategories";
import EditableExpires from "../../utils/EditableExpires";
import LastRun from "../../utils/LastRun";
import ReportFormatSelect from "../../utils/ReportFormatSelect";
import rawImmunizationTypes from "../../utils/immunizationTypes";


const ProviderSelect = ({ handleProviderChanged, selectedProviders}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
        authenticatedFetch({
            path: "provider/revenueteam",
                successHandler: function(result) {
                setIsLoaded(true);
                setData(result);
            },
            errorHandler: function(error) {
                setIsLoaded(true);
                setError(error);
            }
        });
    }, [])
  
    const options = data.map((provider) => {
        return {value: provider.id, label: provider.lastName + ", " + provider.firstName + (provider.middleName ? " " + provider.middleName : "")};
    });

    if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        return (
            <div style={{width: "350px"}}>
                <Select
                options={options}
                onChange={(selectedProviders) => {
                    handleProviderChanged(selectedProviders);
                }}
                value={selectedProviders}
                placeholder="All"
                isMulti
                theme={(theme) => ({
                  ...theme,
                  colors: {
                  ...theme.colors,
                    primary50: '#DEF2F1',
                    primary25: '#DEF2F1',
                    primary: '#3AAFA9',
                  },
                })}
                />
            </div>
        )
    }
}

const CMESelect = ({ handleCMEChanged, selectedCMEs}) => {
  const options = rawCMECategories.filter((cme) => {
    return cme.value !== "";
  }).map((cme) => {
    return {value: cme.value, label: cme.label};
  });

  return (
      <div style={{width: "350px"}}>
          <Select
          options={options}
          onChange={(selectedCMEs) => {
              handleCMEChanged(selectedCMEs);
          }}
          value={selectedCMEs}
          placeholder="All"
          isMulti
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              primary50: '#DEF2F1',
              primary25: '#DEF2F1',
              primary: '#3AAFA9',
            },
          })}
          />
      </div>
  )
}


const KeywordSelect = ({ handleKeywordsChanged, selectedKeywords}) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleKeywordsChanged((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  return (
      <div style={{width: "350px"}}>
          <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => handleKeywordsChanged(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Type word(s) or phrase(s) and press enter..."
            value={selectedKeywords}
          />
      </div>
  )
}

const CMEHours = ({ handleCMEHoursChanged, selectedCMEHours}) => {
  return (
      <div style={{width: "350px"}}>
          <Form.Control
          onChange={(e) => {
            handleCMEHoursChanged(e.target.value);
          }}
          value={selectedCMEHours}
          />
      </div>
  )
}

const CMEDateRangeSelect = ({ selectedCMEDateRangeOption, handleCMEDateRangeOptionChanged, selectedCMEDateRangeCustomStartDate, handleCMEDateRangeCustomStartDateChanged, selectedCMEDateRangeCustomEndDate, handleCMEDateRangeCustomEndDateChanged}) => {
  const [showDateRange, setShowDateRange] = useState(selectedCMEDateRangeOption === 'custom');

  return (
    <div style={{width: "350px"}}>
      <Form.Select
          style={{height: "40px"}}
          value={selectedCMEDateRangeOption}
          onChange={(e) => {
            if (e.target.value === "custom")
            {
              setShowDateRange(true);
            }
            else
            {
              setShowDateRange(false);
            }

            handleCMEDateRangeOptionChanged(e.target.value);
          }}
          >
            <option value="">(all-time)</option>
            <option value="calendarYear">Since start of calendar year</option>
            <option value="yearAgo">Past 12 months</option>
            <option value="twoYearsAgo">Past 24 months</option>
            <option value="custom">Custom...</option>
      </Form.Select>
      { showDateRange ?
      <>
        <br />
        <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={selectedCMEDateRangeCustomStartDate} localActive={true} editingExpires={true} handleExpiresChanged={handleCMEDateRangeCustomStartDateChanged} /></Form.Label>
        &nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;
        <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={selectedCMEDateRangeCustomEndDate} localActive={true} editingExpires={true} handleExpiresChanged={handleCMEDateRangeCustomEndDateChanged} /></Form.Label>
      </>
      :
      <></>
      }
    </div>
  )
}

const FacilitySelect = ({ handleFacilityChanged, selectedFacilities}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
      authenticatedFetch({
          path: "facility/revenueteam",
              successHandler: function(result) {
              setIsLoaded(true);
              setData(result);
          },
          errorHandler: function(error) {
              setIsLoaded(true);
              setError(error);
          }
      });
  }, [])

  const options = data.map((facility) => {
      return {value: facility.id, label: facility.name};
  });

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      return (
          <div style={{width: "350px"}}>
              <Select
              options={options}
              onChange={(selectedFacilities) => {
                handleFacilityChanged(selectedFacilities);
              }}
              value={selectedFacilities}
              placeholder="All"
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
              />
          </div>
      )
  }
}

const ImmunizationSelect = ({ handleImmunizationChanged, selectedImmunizations}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(rawImmunizationTypes);
  }, [])

  const options = data.filter((immunization) => {
    return immunization.value !== "";
  }).map((immunization) => {
      return {value: immunization.value, label: immunization.label};
  });

  return (
      <div style={{width: "350px"}}>
          <Select
          options={options}
          onChange={(selectedImmunizations) => {
            handleImmunizationChanged(selectedImmunizations);
          }}
          value={selectedImmunizations}
          placeholder="All"
          isMulti
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              primary50: '#DEF2F1',
              primary25: '#DEF2F1',
              primary: '#3AAFA9',
            },
          })}
          />
      </div>
  )
}


const ProviderDetailsModal = ({ show, onClose, runSuccess}) => {
    const [npi, setNpi] = useState(true);
    const [dob, setDob] = useState(false);
    const [license, setLicense] = useState(false);
    const [caqh, setCaqh] = useState(false);
    const [dea, setDea] = useState(false);
    const [medicaid, setMedicaid] = useState(false);
    const [medicare, setMedicare] = useState(false);
    const [otherIds, setOtherIds] = useState(false);
    const [providerIds, setProviderIds] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [reportFormat, setReportFormat] = useState("CSV");

    const [isExporting, setIsExporting] = useState(false);

    const notyf = useContext(NotyfContext);

    const handleProviderChanged = (providers, e) => {
        setSelectedProviders(providers);

        let newProviderIds = []
        for (var i=0; i<providers.length; i++)
        {
            newProviderIds.push(providers[i].value);
        }

        setProviderIds(newProviderIds);
    }

    const handleReportFormatChanged = (value) => {
      setReportFormat(value);
    }

    const handleExportClicked = (e) => {
        e.preventDefault();
        setIsExporting(true);
    
        authenticatedFetch({
          method: "POST",
          path:  "report/providerdetails",
          requestData: {
            providerIds: providerIds,
            npi: npi,
            dob: dob,
            license: license,
            caqh: caqh,
            dea: dea,
            medicaid: medicaid,
            medicare: medicare,
            otherIds: otherIds,
            reportFormat: reportFormat,
          },
          successHandler: function(result) {
            window.open(result.url);
            setIsExporting(false);

            runSuccess("providerdetails")
            onClose();
          },
          errorHandler: function(error) {
            setIsExporting(false);
            notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
          }
        });
    }
    
    
    return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Provider Details</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table> 
              <tbody>
                <tr>
                    <td>Provider(s):</td>
                    <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
                </tr>
                <tr>
                    <td>Included&nbsp;Fields:</td>
                    <td>
                        <Form.Check type="checkbox" inline name="group1" label="NPI" checked={npi} onChange={(e) => {setNpi(e.target.checked)}} />
                        <Form.Check type="checkbox" inline name="group1" label="DOB" checked={dob} onChange={(e) => {setDob(e.target.checked)}} />
                        <Form.Check type="checkbox" inline name="group1" label="License" checked={license} onChange={(e) => {setLicense(e.target.checked)}}  />
                        <Form.Check type="checkbox" inline name="group1" label="CAQH" checked={caqh} onChange={(e) => {setCaqh(e.target.checked)}} />
                        <Form.Check type="checkbox" inline name="group1" label="DEA" checked={dea} onChange={(e) => {setDea(e.target.checked)}}  />
                        <Form.Check type="checkbox" inline name="group1" label="Medicaid" checked={medicaid} onChange={(e) => {setMedicaid(e.target.checked)}}  />
                        <Form.Check type="checkbox" inline name="group1" label="PTAN" checked={medicare} onChange={(e) => {setMedicare(e.target.checked)}}  />
                        <Form.Check type="checkbox" inline name="group1" label="Other IDs" checked={otherIds} onChange={(e) => {setOtherIds(e.target.checked)}}  />
                    </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          {isExporting ? 
              <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
            :
            <>
              <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
            </>
            }
          </Modal.Footer>
        </Modal>
    );
}

const ProviderFacilitiesModal = ({ show, onClose, runSuccess}) => {
  const [providerIds, setProviderIds] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);

  const [facilityIds, setFacilityIds] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [reportFormat, setReportFormat] = useState("CSV");

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleProviderChanged = (providers, e) => {
      setSelectedProviders(providers);

      let newProviderIds = []
      for (var i=0; i<providers.length; i++)
      {
          newProviderIds.push(providers[i].value);
      }

      setProviderIds(newProviderIds);
  }

  const handleFacilityChanged = (facilities, e) => {
    setSelectedFacilities(facilities);

    let newFacilityIds = []
    for (var i=0; i<facilities.length; i++)
    {
      newFacilityIds.push(facilities[i].value);
    }

    setFacilityIds(newFacilityIds);
}

const handleReportFormatChanged = (value) => {
  setReportFormat(value);
}

const handleExportClicked = (e) => {
    e.preventDefault();
    setIsExporting(true);

    authenticatedFetch({
      method: "POST",
      path:  "report/providerfacilities",
      requestData: {
        providerIds: providerIds,
        facilityIds: facilityIds,
        reportFormat: reportFormat,
      },
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);

        runSuccess("providerfacilities")
        onClose();
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
}
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Facilities</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Provider(s):</td>
                  <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
              </tr>
              <tr>
                  <td>Facilities(s):</td>
                  <td><FacilitySelect handleFacilityChanged={handleFacilityChanged} selectedFacilities={selectedFacilities}/></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
            <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
            <Button onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ProviderActiveDocumentsModal = ({ show, onClose, runSuccess}) => {
  const [providerIds, setProviderIds] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [reportFormat, setReportFormat] = useState("CSV");

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleProviderChanged = (providers, e) => {
      setSelectedProviders(providers);

      let newProviderIds = []
      for (var i=0; i<providers.length; i++)
      {
          newProviderIds.push(providers[i].value);
      }

      setProviderIds(newProviderIds);
  }

  const handleReportFormatChanged = (value) => {
    setReportFormat(value);
  }

  const handleExportClicked = (e) => {
      e.preventDefault();
      setIsExporting(true);
  
      authenticatedFetch({
        method: "POST",
        path:  "report/provideractivedocuments",
        requestData: {
          providerIds: providerIds,
          reportFormat: reportFormat,
        },
        successHandler: function(result) {
          window.open(result.url);
          setIsExporting(false);

          runSuccess("provideractivedocuments")
          onClose();
        },
        errorHandler: function(error) {
          setIsExporting(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Active Documents</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Provider(s):</td>
                  <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
            <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
            <Button className="btn-secondary" onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ProviderCMEsModal = ({ show, onClose, runSuccess}) => {
  const [providerIds, setProviderIds] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [cmeIds, setCMEIds] = useState([]);
  const [selectedCMEs, setSelectedCMEs] = useState([]);
  const [selectedCMEHours, setSelectedCMEHours] = useState("");
  const [cmeDateRangeOption, setCMEDateRangeOption] = useState("twoYearsAgo");
  const [cmeDateRangeCustomStartDate, setCMEDateRangeCustomStartDate] = useState(null);
  const [cmeDateRangeCustomEndDate, setCMEDateRangeCustomEndDate] = useState(null);
  const [reportFormat, setReportFormat] = useState("CSV");

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleProviderChanged = (providers, e) => {
      setSelectedProviders(providers);

      let newProviderIds = []
      for (var i=0; i<providers.length; i++)
      {
          newProviderIds.push(providers[i].value);
      }

      setProviderIds(newProviderIds);
  }

  const handleCMEChanged = (cmes, e) => {
      setSelectedCMEs(cmes);

      let newCMEIds = []
      for (var i=0; i<cmes.length; i++)
      {
        newCMEIds.push(cmes[i].value);
      }

      setCMEIds(newCMEIds);
  }

  const handleCMEHoursChanged = (val) => {
      setSelectedCMEHours(val);
  }

  const handleCMEDateRangeOptionChanged = (value) => {
      setCMEDateRangeOption(value);
  }
  
  const handleCMEDateRangeCustomStartDateChanged = (value) => {
      setCMEDateRangeCustomStartDate(value);
  }

  const handleCMEDateRangeCustomEndDateChanged = (value) => {
      setCMEDateRangeCustomEndDate(value);
  }

  const handleReportFormatChanged = (value) => {
    setReportFormat(value);
  }

  const handleExportClicked = (e) => {
      e.preventDefault();
      setIsExporting(true);
  
      authenticatedFetch({
        method: "POST",
        path:  "report/providercmes",
        requestData: {
          providerIds: providerIds,
          categoryIds: cmeIds,
          hours: selectedCMEHours,
          dateRangeOption: cmeDateRangeOption,
          customStartDate: cmeDateRangeCustomStartDate,
          customEndDate: cmeDateRangeCustomEndDate,
          reportFormat: reportFormat,
        },
        successHandler: function(result) {
          window.open(result.url);
          setIsExporting(false);

          runSuccess("providercmes")
          onClose();
        },
        errorHandler: function(error) {
          setIsExporting(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | CMEs</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Provider(s):</td>
                  <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
              </tr>
              <tr>
                  <td>Categories(s):</td>
                  <td><CMESelect handleCMEChanged={handleCMEChanged} selectedCMEs={selectedCMEs}/></td>
              </tr>
              <tr>
                  <td>Hours &gt;=</td>
                  <td><CMEHours handleCMEHoursChanged={handleCMEHoursChanged} selectedCMEHours={selectedCMEHours} /></td>
              </tr>
              <tr>
                  <td>Date Range:</td>
                  <td><CMEDateRangeSelect selectedCMEDateRangeOption={cmeDateRangeOption} handleCMEDateRangeOptionChanged={handleCMEDateRangeOptionChanged} selectedCMEDateRangeCustomStartDate={cmeDateRangeCustomStartDate} handleCMEDateRangeCustomStartDateChanged={handleCMEDateRangeCustomStartDateChanged} selectedCMEDateRangeCustomEndDate={cmeDateRangeCustomEndDate} handleCMEDateRangeCustomEndDateChanged={handleCMEDateRangeCustomEndDateChanged}/></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
            <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
            <Button onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ProviderBoardCertificationsModal = ({ show, onClose, runSuccess}) => {
  const [providerIds, setProviderIds] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [reportFormat, setReportFormat] = useState("CSV");

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleProviderChanged = (providers) => {
      setSelectedProviders(providers);

      let newProviderIds = []
      for (var i=0; i<providers.length; i++)
      {
          newProviderIds.push(providers[i].value);
      }

      setProviderIds(newProviderIds);
  }

  const handleKeywordsChanged = (keywords) => {
    setSelectedKeywords(keywords);
}

const handleReportFormatChanged = (value) => {
  setReportFormat(value);
}

const handleExportClicked = (e) => {
    e.preventDefault();
    setIsExporting(true);

    const keywordList = []
    for (var i=0; i<selectedKeywords.length; i++)
    {
        keywordList.push(selectedKeywords[i].value);
    }

    authenticatedFetch({
      method: "POST",
      path:  "report/providerboardcertifications",
      requestData: {
        providerIds: providerIds,
        keywords: keywordList,
        reportFormat: reportFormat,
      },
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);

        runSuccess("providerboardcertifications")
        onClose();
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Board Certifications</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Provider(s):</td>
                  <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
              </tr>
              <tr>
                  <td>Keyword(s):</td>
                  <td><KeywordSelect handleKeywordsChanged={handleKeywordsChanged} selectedKeywords={selectedKeywords} /></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
            <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
            <Button onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ProviderImmunizationsModal = ({ show, onClose, runSuccess}) => {
  const [providerIds, setProviderIds] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);

  const [immunizationTypes, setImmunizationTypes] = useState([]);
  const [selectedImmunizations, setSelectedImmunizations] = useState([]);
  const [reportFormat, setReportFormat] = useState("CSV");

  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handleProviderChanged = (providers, e) => {
      setSelectedProviders(providers);

      let newProviderIds = []
      for (var i=0; i<providers.length; i++)
      {
          newProviderIds.push(providers[i].value);
      }

      setProviderIds(newProviderIds);
  }

  const handleImmunizationChanged = (immunizations, e) => {
    setSelectedImmunizations(immunizations);

    let newImmunizationTypes = []
    for (var i=0; i<immunizations.length; i++)
    {
      newImmunizationTypes.push(immunizations[i].value);
    }

    setImmunizationTypes(newImmunizationTypes);
}

const handleReportFormatChanged = (value) => {
  setReportFormat(value);
}

const handleExportClicked = (e) => {
    e.preventDefault();
    setIsExporting(true);

    authenticatedFetch({
      method: "POST",
      path:  "report/providerimmunizations",
      requestData: {
        providerIds: providerIds,
        immunizationTypes: immunizationTypes,
        reportFormat: reportFormat,
      },
      successHandler: function(result) {
        window.open(result.url);
        setIsExporting(false);

        runSuccess("providerimmunizations")
        onClose();
      },
      errorHandler: function(error) {
        setIsExporting(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
}
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Immunizations</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Provider(s):</td>
                  <td><ProviderSelect handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
              </tr>
              <tr>
                  <td>Immunization(s):</td>
                  <td><ImmunizationSelect handleImmunizationChanged={handleImmunizationChanged} selectedImmunizations={selectedImmunizations}/></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
            <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
            <Button onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ReportList = () => {
    const [showProviderDetailsModal, setShowProviderDetailsModal] = useState(false);
    const [showProviderFacilitiesModal, setShowProviderFacilitiesModal] = useState(false);
    const [showProviderActiveDocumentsModal, setShowProviderActiveDocumentsModal] = useState(false);
    const [showProviderCMEsModal, setShowProviderCMEsModal] = useState(false);
    const [showProviderBoardCertificationsModal, setShowProviderBoardCertificationsModal] = useState(false);
    const [showProviderImmunizationsModal, setShowProviderImmunizationsModal] = useState(false);

    const [lastRunRefresh, setLastRunRefresh] = useState(0);

    const { isH3Admin, authorities } = useContext(UserSettingsContext);

    const runSuccess = (e) => {
      setLastRunRefresh(lastRunRefresh + 1);
    }

    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
            <Card.Title>
            Provider Reports
            </Card.Title>
          </Card.Header>
        <Table striped hover>
          <thead>
            <tr>
              <td>Report Type</td>
              <td>Available Formats</td>
              <td>Last Run</td>
            </tr>
          </thead>
          <tbody>
            {(isH3Admin || authorities.includes("PROVIDER_DOCUMENT_VIEW") || authorities.includes("PROVIDER_DOCUMENT_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderActiveDocumentsModal(true)}}>
                <td><a href="#">Active Documents</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="provideractivedocuments" refresh={lastRunRefresh}/></td>
            </tr>
            : <></>
            }
            {(isH3Admin || authorities.includes("PROVIDER_FACILITY_VIEW") || authorities.includes("PROVIDER_FACILITY_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderFacilitiesModal(true)}}>
                <td><a href="#">Facilities</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providerfacilities" refresh={lastRunRefresh}/></td>
            </tr>
            : <></>
            }
            {(isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderDetailsModal(true)}}>
                <td><a href="#">Provider Details</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providerdetails" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
            {(isH3Admin || authorities.includes("PROVIDER_CME_VIEW") || authorities.includes("PROVIDER_CME_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderCMEsModal(true)}}>
                <td><a href="#">CMEs</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providercmes" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
            {(isH3Admin || authorities.includes("PROVIDER_BOARD_CERTIFICATION_VIEW") || authorities.includes("PROVIDER_BOARD_CERTIFICATION_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderBoardCertificationsModal(true)}}>
                <td><a href="#">Board Certifications</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providerboardcertifications" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
            {(isH3Admin || authorities.includes("PROVIDER_IMMUNIZATION_VIEW") || authorities.includes("PROVIDER_IMMUNIZATION_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderImmunizationsModal(true)}}>
                <td><a href="#">Immunizations</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providerimmunizations" refresh={lastRunRefresh}/></td>
            </tr>
            : <></>
            }
          </tbody>
        </Table>

        <ProviderDetailsModal show={showProviderDetailsModal} onClose={() => setShowProviderDetailsModal(false)} runSuccess={runSuccess} />
        <ProviderFacilitiesModal show={showProviderFacilitiesModal} onClose={() => setShowProviderFacilitiesModal(false)} runSuccess={runSuccess} />
        <ProviderActiveDocumentsModal show={showProviderActiveDocumentsModal} onClose={() => setShowProviderActiveDocumentsModal(false)} runSuccess={runSuccess} />
        <ProviderCMEsModal show={showProviderCMEsModal} onClose={() => setShowProviderCMEsModal(false)} runSuccess={runSuccess} />
        <ProviderBoardCertificationsModal show={showProviderBoardCertificationsModal} onClose={() => setShowProviderBoardCertificationsModal(false)} runSuccess={runSuccess} />
        <ProviderImmunizationsModal show={showProviderImmunizationsModal} onClose={() => setShowProviderImmunizationsModal(false)} runSuccess={runSuccess} />
      </Card>
    )
  };

const ProviderReports = () => {

    return (
      <Row>
        <Helmet title="Provider Reports"/>
        <Col lg="12">
          <ReportList />
        </Col>
      </Row>
    )
  };
  
  export default ProviderReports;
  