import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Table, Form, Spinner } from "react-bootstrap";

import NotyfContext from "../contexts/NotyfContext";

import { ExternalLink} from "react-feather";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import authenticatedFetch from "./fetch"

import rawTaskStatuses from "./taskStatuses";
import ActivityRow from "./ActivityRow";
import { number } from "yup";
  
const ProviderSelect = ({ disabled, selectedProviderId, handleProviderChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/revenueteam?includeEndDated=false",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let providerOptionList = [<option value={0} key={0}>Select a provider...</option>];
    providerOptionList.push(data.map((provider, i) => {
      return (
        <option value={provider.id} key={provider.id}>{provider.lastName}, {provider.firstName} {provider.middleName}</option>
      )
    }, this));

    return (
      <Form.Select disabled={disabled} onChange={(e) => {handleProviderChanged(e.target.value, e)}} value={selectedProviderId}>{providerOptionList}</Form.Select>
    )
  }
}

const FacilitySelect = ({ disabled, selectedFacilityId, handleFacilityChanged}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      authenticatedFetch({
        path: "facility/revenueteam",
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }, [])
  
    if (!isLoaded)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
      let facilityOptionList = [<option value={0} key={0}>Select a location...</option>];
      facilityOptionList.push(data.map((facility, i) => {
        return (
          <option value={facility.id} key={facility.id}>{facility.name}</option>
        )
      }, this));
  
      return (
        <Form.Select disabled={disabled} onChange={(e) => {handleFacilityChanged(e.target.value, e)}} value={selectedFacilityId}>{facilityOptionList}</Form.Select>
      )
    }
}

const ESignatureSelect = ({ disabled, selectedESignatureTemplateId, handleESignatureTemplateChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "fppetask/esignature/templates",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let optionList = [<option value={0} key={0}>Select a template...</option>];
    optionList.push(data.map((template, i) => {
      return (
        <option value={template.id} key={template.id}>{template.name}</option>
      )
    }, this));

    return (
      <Form.Select disabled={disabled} onChange={(e) => {handleESignatureTemplateChanged(e.target.value, e)}} value={selectedESignatureTemplateId}>{optionList}</Form.Select>
    )
  }
}



const FppeTaskModal = ({ id, category, providerId, facilityId, description, patientCount, providerFormsComplete, reviewerFormsComplete, activeProviderUrl, activeReviewerUrl, status, deadline, reviewerFirstName, reviewerLastName, reviewerCredentials, reviewerEmail, eSignatureTemplateId, archived, activityList, show, onCancel, onSave, onDelete }) => {
    const [localId] = useState(id);
    const [localStatus, setLocalStatus] = useState(status);
    const [localCategory, setLocalCategory] = useState(category);
    const [localProviderId, setLocalProviderId] = useState(providerId);
    const [localFacilityId, setLocalFacilityId] = useState(facilityId);
    const [localDescription, setLocalDescription] = useState(description);
    const [localPatientCount, setLocalPatientCount] = useState(patientCount);
    const [localReviewerFirstName, setLocalReviewerFirstName] = useState(reviewerFirstName);
    const [localReviewerLastName, setLocalReviewerLastName] = useState(reviewerLastName);
    const [localReviewerCredentials, setLocalReviewerCredentials] = useState(reviewerCredentials);
    const [localReviewerEmail, setLocalReviewerEmail] = useState(reviewerEmail);
    const [localESignatureTemplateId, setLocalESignatureTemplateId] = useState(eSignatureTemplateId);
    const [localSendProviderNotification, setLocalSendProviderNotification] = useState(false);

    const [localDeadline, setLocalDeadline] = useState(deadline);
    const [localArchived, setLocalArchived] = useState(archived);
    const [isSaving, setIsSaving] = useState(false);
  
    const [newActivity, setNewActivity] = useState('');

    const { isH3Admin, authorities } = useContext(UserSettingsContext);
    const canEdit = isH3Admin || authorities.includes("TASK_EDIT");
  
    const notyf = useContext(NotyfContext);
  
    // On create, may not touch these
    if (localStatus == null) {
      setLocalStatus("CREATED")
    }
  
    if (localCategory == null) {
      setLocalCategory("CHART_REVIEW");
    }
  
    if (localDescription == null) {
      setLocalDescription("");
    }

    if (localPatientCount == null) {
      setLocalPatientCount("");
    }

    if (localReviewerFirstName == null) {
      setLocalReviewerFirstName("");
    }

    if (localReviewerLastName == null) {
      setLocalReviewerLastName("");
    }

    if (localReviewerCredentials == null) {
      setLocalReviewerCredentials("");
    }

    if (localReviewerEmail == null) {
      setLocalReviewerEmail("");
    }

    if (localDeadline == null) {
      setLocalDeadline("");
    }
  
    const handleStatusChanged = (status, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalStatus(status);
    }
  
    const handleCategoryChanged = (category, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalCategory(category);
    }

    const handleProviderChanged = (providerId, e) => {
      setLocalProviderId(providerId);
    }

    const handleSendProviderNotificationChanged = (e) => {
      setLocalSendProviderNotification(e.target.checked);
    }

    const handleFacilityChanged = (facilityId, e) => {
        if (e != null) {
          e.preventDefault();
        }
    
        setLocalFacilityId(facilityId);
      }

    const handleDescriptionChanged = (description, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalDescription(description);
    }

    const handlePatientCountChanged = (patientCount, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalPatientCount(patientCount);
    }
  
    const handleReviewerFirstNameChanged = (reviewerFirstName, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalReviewerFirstName(reviewerFirstName);
    }

    const handleReviewerLastNameChanged = (reviewerLastName, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalReviewerLastName(reviewerLastName);
    }

    const handleReviewerCredentialsChanged = (reviewerCredentials, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalReviewerCredentials(reviewerCredentials);
    }

    const handleReviewerEmailChanged = (reviewerEmail, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalReviewerEmail(reviewerEmail);
    }

    const handleESignatureTemplateChanged = (eSignatureTemplateId, e) => {
      if (e != null) {
        e.preventDefault();
      }

      setLocalESignatureTemplateId(eSignatureTemplateId);
    }

    const handleDeadlineChanged = (deadline, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setLocalDeadline(deadline);
    }
    
    const handleArchivedChanged = (archived, e) => {
      setLocalArchived(archived);
    }
    
    const handleNewActivityChanged = (newActivity, e) => {
      if (e != null) {
        e.preventDefault();
      }
  
      setNewActivity(newActivity);
    }
  
    const reset = () => {
      setLocalStatus(status);
      setLocalCategory(category);
      setLocalProviderId(providerId);
      setLocalFacilityId(facilityId);
      setLocalDescription(description);
      setLocalPatientCount(patientCount);
      setLocalReviewerFirstName(reviewerFirstName);
      setLocalReviewerLastName(reviewerLastName);
      setLocalReviewerCredentials(reviewerCredentials);
      setLocalReviewerEmail(reviewerEmail);
      setLocalESignatureTemplateId(eSignatureTemplateId);
      setLocalSendProviderNotification(false);
      setLocalDeadline(deadline);
      setLocalArchived(archived);
      setNewActivity('');
      setIsSaving(false);
    }
  
    const handleCancel = () => {
      reset();
      onCancel();
    }

    const handleDelete = () => {
      if (window.confirm("Are you sure you want to delete this task?"))
      {
        authenticatedFetch({
          path: "fppetask/" + id,
          method: "DELETE",
          successHandler: function(result) {
            onDelete();

            notyf.open({type: "error", message: "Deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
          },
          errorHandler: function(error) {
            alert("Something went wrong; please try again");
          }
        });
      }
    }

    const doSubmit = () => {
      const requestData = {
        id: localId,
        status: localStatus,
        category: localCategory,
        providerId: localProviderId,
        facilityId: localFacilityId,
        description: localDescription,
        patientCount: localPatientCount,
        reviewerFirstName: localReviewerFirstName,
        reviewerLastName: localReviewerLastName,
        reviewerCredentials: localReviewerCredentials,
        reviewerEmail: localReviewerEmail,
        eSignatureTemplateId: localESignatureTemplateId,
        sendProviderNotification: localSendProviderNotification,
        deadline: localDeadline,
        archived: localArchived,
        newActivity: newActivity,
      };
  
      authenticatedFetch({
        path: "fppetask",
        method: "POST",
        requestData: requestData,
        successHandler: function() {
          setNewActivity('');
          setIsSaving(false);
  
          var message = "Updated!";
          if (localId == null || localId === 0)
          {
            message = "Created!";
            reset(); // If this is the shared "new" task modal, need to reset everything
          }
  
          onSave();
  
          notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          alert("Something went wrong; please try again");
          setNewActivity('');
          setIsSaving(false);
        }
      });
    }
  
    const handleSave = () => {
      if (!localProviderId || localProviderId == null || parseInt(localProviderId) === 0)
      {
        alert("Please select a provider");
        return;
      }
  
      if (!localFacilityId || localFacilityId == null || parseInt(localFacilityId) === 0)
      {
        alert("Please select a location");
        return;
      }

      if (localSendProviderNotification && (localDescription == null || localDescription === "" || localReviewerFirstName == null || localReviewerFirstName === "" || localReviewerLastName == null || localReviewerLastName === "" || localReviewerEmail == null || localReviewerEmail === "" || localESignatureTemplateId == null || localESignatureTemplateId === 0 || localESignatureTemplateId === "" || localPatientCount == null || localPatientCount === "" || parseInt(localPatientCount) < 1 || parseInt(localPatientCount) > 25))
      {
        alert("Please enter description, reviewer name, reviewer email, and number of patients, and select an e-signature template before sending provider link");
        return;
      }

      setIsSaving(true);

      doSubmit();
    }
  
    let activityRows = [];
    if (activityList) {
      activityRows.push(activityList.map((activity, i) => {
        return (
          <ActivityRow key={activity.id} user={activity.userFormattedName} notes={activity.notes} created={activity.created} />
        )
      }, this));
    }

    let statusOptionList = rawTaskStatuses.filter((status) => { return status.value !== "" }).map((status, i) => {
        return (
          <option value={status.value} key={status.value}>{status.label}</option>
        )
      }, this);

    let showSendProviderNotificationToggle = (activeProviderUrl == null || activeProviderUrl === "") && (activeReviewerUrl == null || activeReviewerUrl === "");
    let showProviderUrl = (activeProviderUrl != null && activeProviderUrl !== "") && (activeReviewerUrl == null || activeReviewerUrl === "");
    let showReviewerUrl = (activeReviewerUrl != null && activeReviewerUrl !== "");

    let notificationRelatedFieldRequired = !showSendProviderNotificationToggle || localSendProviderNotification || providerFormsComplete || reviewerFormsComplete;

    let fieldsDisabled = !canEdit || !showSendProviderNotificationToggle || providerFormsComplete || reviewerFormsComplete;

    return (
      <>
        <Modal show={show} onHide={handleCancel} centered>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              {id > 0 ? "FPPE Task Details for (" + id  + ")": "Create New FPPE Task"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table size="sm" striped> 
              <tbody>
                <tr>
                  <td style={{minWidth: "140px"}}>Status*</td>
                  <td>
                    <Form.Select disabled={!canEdit} onChange={(e) => {handleStatusChanged(e.target.value, e)}} value={localStatus}>
                      {statusOptionList}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td>Category*</td>
                  <td>
                    <Form.Select disabled={fieldsDisabled} onChange={(e) => {handleCategoryChanged(e.target.value, e)}} value={localCategory}>
                      <option value="CHART_REVIEW" key="CHART_REVIEW">Chart Review</option>
                      <option value="PROCTORING" key="PROCTORING">Proctoring</option>
                      <option value="OTHER" key="OTHER">Other</option>
                    </Form.Select>
                  </td>
                </tr>
                <tr><td>Provider*</td><td><ProviderSelect disabled={fieldsDisabled}  selectedProviderId={localProviderId} handleProviderChanged={handleProviderChanged} /></td></tr>
                <tr><td>Location*</td><td><FacilitySelect  disabled={fieldsDisabled} selectedFacilityId={localFacilityId} handleFacilityChanged={handleFacilityChanged} /></td></tr>
                <tr><td>Description{notificationRelatedFieldRequired && "*"}</td><td><Form.Control as="textarea" disabled={fieldsDisabled} onChange={(e) => {handleDescriptionChanged(e.target.value, e)}} value={localDescription} /></td></tr>
                <tr>
                  <td>Number of Patients{notificationRelatedFieldRequired && "*"}</td>
                  <td><Form.Control disabled={fieldsDisabled} type="number" min="0" max="25" onWheel={(e) => e.target.blur()} onChange={(e) => {handlePatientCountChanged(e.target.value, e)}} value={localPatientCount} /></td>
                </tr>
                <tr>
                  <td>Reviewer Name{notificationRelatedFieldRequired && "*"}</td>
                  <td>
                      <Form.Control disabled={fieldsDisabled} onChange={(e) => {handleReviewerFirstNameChanged(e.target.value, e)}}  value={localReviewerFirstName} style={{width: "35%", display: "inline-block"}} placeholder="First"/>&nbsp;&nbsp;
                      <Form.Control disabled={fieldsDisabled} onChange={(e) => {handleReviewerLastNameChanged(e.target.value, e)}}  value={localReviewerLastName} style={{width: "35%", display: "inline-block"}} placeholder="Last"/>&nbsp;&nbsp;
                      <Form.Control disabled={fieldsDisabled} onChange={(e) => {handleReviewerCredentialsChanged(e.target.value, e)}}  value={localReviewerCredentials} style={{width: "25%", display: "inline-block"}} placeholder="Credentials"/>
                  </td>
                </tr>
                <tr>
                  <td>Reviewer Email{notificationRelatedFieldRequired && "*"}</td>
                  <td><Form.Control disabled={fieldsDisabled} onChange={(e) => {handleReviewerEmailChanged(e.target.value, e)}}  value={localReviewerEmail}/></td>
                </tr>
                <tr>
                  <td>Reviewer E-Signature Template{notificationRelatedFieldRequired && "*"}</td>
                  <td>
                    <ESignatureSelect disabled={fieldsDisabled} selectedESignatureTemplateId={localESignatureTemplateId} handleESignatureTemplateChanged={handleESignatureTemplateChanged} />
                  </td>
                </tr>
                <tr>
                  <td>Provider Forms</td>
                  {providerFormsComplete ? <td>(completed)</td> :
                  <td>{showSendProviderNotificationToggle ? <><Form.Check disabled={!canEdit} type="switch" checked={localSendProviderNotification} style={{display: "inline"}}  onChange={(e) => {handleSendProviderNotificationChanged(e)}}/>&nbsp;&nbsp;Send link to provider</> : (showProviderUrl ? <a href={activeProviderUrl} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/>&nbsp;Active provider forms</a> : "(n/a)")}</td>
                  }
                </tr>
                <tr>
                  <td>Reviewer Forms</td>
                  {reviewerFormsComplete ? <td>(completed)</td> :
                  <td>{showReviewerUrl ?  <a href={activeReviewerUrl} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/>&nbsp;Active reviewer link</a> : "(pending provider form completion)"}</td>
                  }
                </tr>
                <tr>
                  <td>Deadline</td>
                  <td>
                    <Form.Control disabled={!canEdit} onChange={(e) => {handleDeadlineChanged(e.target.value, e)}} type="date" value={localDeadline} placeholder="Deadline" />
                  </td>
                </tr>
                {status === "COMPLETE" &&
                <>
                <tr>
                  <td>Archived</td>
                  <td><Form.Check disabled={!canEdit} onChange={(e) => {handleArchivedChanged(e.target.checked, e)}}  value={localArchived} /></td>
                </tr>
                </>
                }
                <tr>
                  <td>Notes</td>
                  <td>
                    <div style={{maxHeight: '160px', overflow: 'auto', display: 'block', width: '100%'}}>
                    <Table striped>
                      <tbody>
                        {canEdit ?
                        <tr><td><Form.Control key={0} onChange={(e) => {handleNewActivityChanged(e.target.value, e)}} value={newActivity} as="textarea" placeholder="Add new note"/></td></tr>
                        : '' }
                        {activityRows}
                      </tbody>
                    </Table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            { isSaving ? 
              <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '40px', marginTop: '11px', marginBottom: '11px'}}/>
              :
              <>
                {id != null && id > 0 ? <Button className="me-auto btn-secondary" onClick={handleDelete}>Delete</Button> : ''}
                <Button onClick={handleCancel}>{canEdit ? 'Cancel' : 'Close'}</Button>
                {canEdit ?
                <Button onClick={handleSave}>{id == null || id <= 0 ? "Create Task" : "Update Task"}</Button>
                : '' }
              </>
            }
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default FppeTaskModal;