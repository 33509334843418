import React, { useState, useEffect } from "react";
import { Card, Table} from "react-bootstrap";

import authenticatedFetch from "./fetch"

import HubParticipation from "./HubParticipation";

const HubParticipationStatusTable = ({entityType, entityId, entityName, canEdit}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      reload();
    }, [entityType, entityId, entityName])
  
    function reload() {
      authenticatedFetch({
        path: entityType + "/participationstatus/hub/" + entityId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
  
    var participationList;
  
    if (error)
    {
      participationList = <tr><td colSpan="5">Something went wrong... please reload the page...</td></tr>;
    }
    else if (!isLoaded)
    {
      participationList = <tr><td colSpan="5">Loading...</td></tr>;
    }
    else
    {
      participationList = data.hubs && data.hubs.length > 0
        && data.hubs.map((participation, i) => {
        return (
          <HubParticipation key={participation.id} onSave={reload} participationId={participation.id} entityType={entityType} entityId={entityId} entityName={entityName} participationName={participation.name} portal={participation.portalUrl} phone={participation.phone} email={participation.email} fax={participation.fax} effectiveDate={participation.effectiveDate} expires={participation.expires} canEdit={canEdit} />
        )
      }, this);
    }
    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
          <Card.Title>
            <div className="row">
              <div className="col-sm-12">
              Data Hub Access
              </div>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Name</td>
              <td>Portal</td>
              <td>Effective Date</td>
              <td>Next Attestation</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {participationList}   
          </tbody>
        </Table>
        </Card.Body>
      </Card>
    );
  };

export default HubParticipationStatusTable;