import { Form } from "react-bootstrap";

const EditableExpires = ({ localExpires, localActive, editingExpires, handleExpiresChanged, blankText="(n/a)", warnWithColors=true, redDays=30, yellowDays=90, display="block", size="sm", width="110px" }) => {
    var className = '';
    var expiresDate = null;
  
    if (localExpires != null)
    {
      var parts = localExpires.split("-");
  
      // If they just started keying in the date, it will come over as, e.g. 0002, and we don't want to interpret that as a real date and then set it as the value; just set this raw string value, e.g. 0002-12-21
      if (parseInt(parts[0]) > 1900)
      {
        expiresDate = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
  
        const diffInMs   = expiresDate - new Date()
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  
        if (warnWithColors)
        {
          if (diffInDays <= redDays)
          {
            className = 'text-danger';
          }
          else if (diffInDays <= yellowDays)
          {
            className= 'text-warning';
          }
        }
      }
    }
  
    var expiresDateDisplay = expiresDate != null ? new Date(expiresDate).toLocaleDateString("en-US") : blankText;
  
    var editableExpiresDateValue = "";
    if (expiresDate != null)
    {
      const date = new Date(expiresDate);
      editableExpiresDateValue = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
    }
    else if (localExpires != null)
    {
      // Not a valid date yet (e.g. we're getting 0002-12-23), but update the value with their current typing progress
      editableExpiresDateValue = localExpires;
    }
  
    return (
      <>
      {editingExpires && localActive ?
      <Form.Control min="2022" style={{width: width, display: display, fontSize: '0.85em'}} type="date" value={editableExpiresDateValue} onChange={(e) => {handleExpiresChanged(e.target.value, e)}} size={size}/>
      :
      <span className={className}>{expiresDateDisplay}</span>
      }
      </>
    );
  };

export default EditableExpires;