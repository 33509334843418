import React, {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
const { REACT_APP_BACKEND_URL } = process.env;


const OnboardingSigning = ({stage, token, data}) => {
  const[templateName, setTemplateName] = useState("Loading...");

  function embedUrl(integrationKey, url, urlIndex, moreToSign)
  {
    var finishText = (moreToSign ? "Document Complete!  Sign Next Document..." : "Click to Complete Signing!");

    window.DocuSign.loadDocuSign(integrationKey)
        .then((docusign) => {
            const signing = docusign.signing({
                url: url,
                displayFormat: 'focused',
                style: {
                    branding: {
                        primaryButton: {
                            backgroundColor: '#2B7A78',
                            color: '#fff',
                        }
                    },
                    signingNavigationButton: {
                        finishText: finishText,  // Clicking this is what triggers the sessionEnd event
                        position: 'bottom-center'
                    }
                }
            });

            var alreadyEnded = false;
            signing.on('sessionEnd', (event) => {
                if (!alreadyEnded)
                {
                  if (event["sessionEndType"] === 'signing_complete')
                  {
                    alreadyEnded = true;
                    if (moreToSign)
                    {
                      getNextDocument(urlIndex);
                    }
                    else
                    {
                      completeSigning();
                    }
                  }
                  else if (event["sessionEndType"] === 'viewing_complete')
                  { 
                    alreadyEnded = true;
                    if (moreToSign)
                    {
                      getNextDocument(urlIndex);
                    }
                    else
                    {
                      completeSigning();
                    }
                  }
                }
            });

            signing.mount('#agreement');
        })
        .catch((ex) => {
            // Any configuration or API limits will be caught here
        });
  }

  async function completeSigning() {
    const requestMetadata = {
      method: 'POST',
    };

    const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

    if (res.status >= 200 && res.status <= 299)
    {
      window.location = "/onboarding/success/" + token
    }
    else
    {
      throw Error(res.statusText);
    }
  }

  async function getNextDocument(currentUrlIndex) {
    const requestMetadata = {
      method: 'GET',
      headers: {'requestedUrlIndex': currentUrlIndex + 1}
    };

    const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

    if (res.status === 200)
    {
      const result = await res.json();
      if (result.integrationKey && result.url != null && result.urlIndex > 0)
      {
        setTemplateName(result.templateName);
        embedUrl(result.integrationKey, result.url, result.urlIndex, result.hasMore);
      }
      else
      {
        completeSigning();
      }
    }
    else
    {
      alert("Error getting next document");
    }
  }


  useEffect(() => {
    if (data && data["hasMore"] !== undefined)  // Make sure it's loaded
    {
      if (data.scriptSrc && data.integrationKey && data.url != null && data.urlIndex >= 0)
      {
        const script = document.createElement('script');
        script.src = data.scriptSrc;
        script.async = true;
        script.onload = () => embedUrl(data.integrationKey, data.url, data.urlIndex, data.hasMore);
      
        document.head.appendChild(script);

        setTemplateName(data.templateName);
      
        return () => {
          document.head.removeChild(script);
        }
      }
      else
      {
        // Go straight to success
        completeSigning();
      }
    }

  }, [token, data]);

  var divWidth = (!window.innerWidth || window.innerWidth >= 800) ? 700 : parseInt(window.innerWidth * .7);
  var divHeight = Math.min(parseInt(divWidth * 1.9), 1050);

  return (
    <>
      <Form.Label style={{fontWeight: "bold"}}>E-Signature: {templateName}</Form.Label>
      <br /><br />
      <div style={{height: divHeight + "px", width: divWidth + "px"}} id="agreement"></div>
    </>

  );
}

export default OnboardingSigning;
