import getActivityDateTimeDisplay from "../utils/datetime"

const ActivityRow = ({user, notes, created}) => {
    var notesWithNewlines = '';
    if (notes && notes.length > 0) {
        notesWithNewlines = notes.split("\n").map(function(item, idx) {
        return ( 
            <span key={idx}>
                {item}
                <br />
            </span>
            )
        });
    }

    // Can't get the enclosing table to be responsive, so just hardcode this here for now for mobile
    var width = (!window.innerWidth || window.innerWidth >= 800) ? '360px' : '220px'

    return (
        <>
        <tr>
            <td>
            <div style={{fontStyle: "italic", fontSize: "0.9em", wordWrap: "break-word", width: width}}>{notesWithNewlines}</div><small className="text-muted">{getActivityDateTimeDisplay(created)} by {user}</small>
            </td>
        </tr>
        </>    
    )
}

export default ActivityRow;