import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Trash2} from "react-feather";
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;


const AddWorkHistoryButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("workHistory", [
        ...values.workHistory,
        {position: "", organization: "", startDate: "", endDate: "", notes: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Work History</Button>
  );
}

const RemoveWorkHistoryButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("workHistory", values.workHistory.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}



const OnboardingWorkHistory = ({stage, token, data}) => {
  var initialValues = {workHistory:[]}

  if (data && data.workHistory)
  {
    for (var i = 0; i < data.workHistory.length; i++)
    {
      if (data.workHistory[i].position == null)
      {
        data.workHistory[i].position = "";
      }
      if (data.workHistory[i].organization == null)
      {
        data.workHistory[i].organization = "";
      }
      if (data.workHistory[i].startDate == null)
      {
        data.workHistory[i].startDate = "";
      }
      if (data.workHistory[i].endDate == null)
      {
        data.workHistory[i].endDate = "";
      }
      if (data.workHistory[i].notes == null)
      {
        data.workHistory[i].notes = "";
      }

      initialValues.workHistory.push(data.workHistory[i]);
    }
  }

  // for (i=initialValues.workHistory.length; i < 1; i++)
  // {
  //   initialValues.workHistory.push({position: "", organization: "", startDate: "", endDate: "", notes: ""});
  // }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        workHistory: Yup.array().of(
          Yup.object().shape({
            position: Yup.string().max(255).required("Position is required"),
            organization: Yup.string().max(255).required("Organization is required"),
            startDate: Yup.date().required("Start date is required"),
            endDate: Yup.date(),
            notes: Yup.string().max(2047).required("Contact information is required"),
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              workHistory: values.workHistory
            })], {
              type: "application/json"
          }));

          // No files, but do it the same way so we can keep the backend consistent

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Work History
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please include all work history, from final education until now.</span>
            </Form.Label><br />
            {values.workHistory.map((workHistory, index) => (
                <div key={index}>
                <i>#{index +1}</i>
                <RemoveWorkHistoryButton index={index} />
                
                <br />
                <Form.Control
                  placeholder="Position"
                  {...getFieldProps(`workHistory[${index}].position`)} 
                  isInvalid={Boolean(touched.workHistory?.[index]?.position && errors.workHistory?.[index]?.position)}
                />
                {!!touched.workHistory?.[index]?.position && (
                  <Form.Control.Feedback type="invalid">
                    {errors.workHistory?.[index]?.position}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Organization"
                  {...getFieldProps(`workHistory[${index}].organization`)}
                  isInvalid={Boolean(touched.workHistory?.[index]?.organization && errors.workHistory?.[index]?.organization)}
                />
                {!!touched.workHistory?.[index]?.organization && (
                  <Form.Control.Feedback type="invalid">
                    {errors.workHistory?.[index]?.organization}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`workHistory[${index}].startDate`)} 
                  isInvalid={Boolean(touched.workHistory?.[index]?.startDate && errors.workHistory?.[index]?.startDate)}
                />
                {!!touched.workHistory?.[index]?.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.workHistory?.[index]?.startDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>End Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`workHistory[${index}].endDate`)} 
                  isInvalid={Boolean(touched.workHistory?.[index]?.endDate && errors.workHistory?.[index]?.endDate)}
                />
                {!!touched.workHistory?.[index]?.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.workHistory?.[index]?.endDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  as="textarea"
                  style={{height: "100px"}}
                  placeholder="Complete contact information (including address, phone, and email address), *as well as* supervisor's name, and reason for leaving position"
                  {...getFieldProps(`workHistory[${index}].notes`)}
                  isInvalid={Boolean(touched.workHistory?.[index]?.notes && errors.workHistory?.[index]?.notes)}
                />
                {!!touched.workHistory?.[index]?.notes && (
                  <Form.Control.Feedback type="invalid">
                    {errors.workHistory?.[index]?.notes}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            ))}
            <AddWorkHistoryButton />
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
            <div className="text-center mt-3 float-start">
              <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
            </div>
            <div id="submitButton" className="text-center mt-3 float-end">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
              >
                Save and Continue
              </Button>
            </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingWorkHistory;
