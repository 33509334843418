import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import {  Trash2} from "react-feather";
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import isFileSizeUnderLimit from "../../utils/fileUploadSize";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;

const AddMalpracticeInsuranceButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("malpracticeInsurance", [
        ...values.malpracticeInsurance,
        {policy: "", notes: "", startDate: "", endDate: "", document: null, documentFilename: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Insurance</Button>
  );
}

const RemoveMalpracticeInsuranceButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("malpracticeInsurance", values.malpracticeInsurance.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}


const OnboardingMalpracticeInsurance = ({stage, token, data}) => {

  var initialValues = {malpracticeInsurance:[]}

  if (data && data.malpracticeInsurance)
  {
    for (var i = 0; i < data.malpracticeInsurance.length; i++)
    {
      if (data.malpracticeInsurance[i].policy == null)
      {
        data.malpracticeInsurance[i].policy = "";
      }
      if (data.malpracticeInsurance[i].notes == null)
      {
        data.malpracticeInsurance[i].notes = "";
      }
      if (data.malpracticeInsurance[i].startDate == null)
      {
        data.malpracticeInsurance[i].startDate = "";
      }
      if (data.malpracticeInsurance[i].endDate == null)
      {
        data.malpracticeInsurance[i].endDate = "";
      }
      if (data.malpracticeInsurance[i].documentFilename == null)
      {
        data.malpracticeInsurance[i].documentFilename = "";
      }

      data.malpracticeInsurance[i].document = null;

      initialValues.malpracticeInsurance.push(data.malpracticeInsurance[i]);
    }
  }

  let required = data.preferences && data.preferences.malpracticeRequired;

  if (required)
  {
    for (i=initialValues.malpracticeInsurance.length; i < 1; i++)
    {
      initialValues.malpracticeInsurance.push({policy: "", notes: "", startDate: "", endDate: "", document: null, documentFilename: ""});
    }
  }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        malpracticeInsurance: Yup.array().of(
          Yup.object().shape({
            policy: Yup.string().max(255).required("Policy number is required"),
            notes: Yup.string().max(255).required("Insurance company is required"),
            startDate: Yup.date().required("Start date is required"),
            endDate: Yup.date().required("End date is required"),
            documentFilename: Yup.string(),
            document: Yup.mixed().when("documentFilename", {
              is: value => !value || value === "",
              then: Yup.mixed().required("Document is required"),
              otherwise: Yup.mixed()
            })
            .test(
              "fileSize",
              "File size too large; max file size is 10 Mb",
              isFileSizeUnderLimit
            ),
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try 
        {
          for (var i = 0; i < values.malpracticeInsurance.length; i++)
          {
            // Set the document filename to the file we're sending so we can link the two up on the backend; preserve the old, if any, so we can delete
            values.malpracticeInsurance[i].oldDocumentFilename = values.malpracticeInsurance[i].documentFilename;
            if (values.malpracticeInsurance[i].document !== null)
            {
              values.malpracticeInsurance[i].documentFilename = values.malpracticeInsurance[i].document.name;
            }
          }

          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              malpracticeInsurance: values.malpracticeInsurance
            })], {
              type: "application/json"
          }));

          for (i = 0; i < values.malpracticeInsurance.length; i++)
          {
            if (values.malpracticeInsurance[i].document !== null)
            {
              formData.append("files", values.malpracticeInsurance[i].document);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Malpractice Insurance{required && "*"}
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please include every current <i>and former</i> insurance policy. For MDs, please go back through fellowship. APPs, please go back through graduate program.</span>
            </Form.Label><br />
            {values.malpracticeInsurance.map((malpracticeInsurance, index) => (
                <div key={index}>
                <i>#{index +1}</i>
                {(!required || index > 0) && <RemoveMalpracticeInsuranceButton index={index} />}
                
                <br />
                <Form.Control
                  placeholder="Policy Number"
                  {...getFieldProps(`malpracticeInsurance[${index}].policy`)}
                  isInvalid={Boolean(touched.malpracticeInsurance?.[index]?.policy && errors.malpracticeInsurance?.[index]?.policy)}
                />
                {!!touched.malpracticeInsurance?.[index]?.policy && (
                  <Form.Control.Feedback type="invalid">
                    {errors.malpracticeInsurance?.[index]?.policy}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Insurance Company"
                  {...getFieldProps(`malpracticeInsurance[${index}].notes`)}
                  isInvalid={Boolean(touched.malpracticeInsurance?.[index]?.notes && errors.malpracticeInsurance?.[index]?.notes)}
                />
                {!!touched.malpracticeInsurance?.[index]?.notes && (
                  <Form.Control.Feedback type="invalid">
                    {errors.malpracticeInsurance?.[index]?.notes}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Issue Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`malpracticeInsurance[${index}].startDate`)} 
                  isInvalid={Boolean(touched.malpracticeInsurance?.[index]?.startDate && errors.malpracticeInsurance?.[index]?.startDate)}
                />
                {!!touched.malpracticeInsurance?.[index]?.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.malpracticeInsurance?.[index]?.startDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`malpracticeInsurance[${index}].endDate`)} 
                  isInvalid={Boolean(touched.malpracticeInsurance?.[index]?.endDate && errors.malpracticeInsurance?.[index]?.endDate)}
                />
                {!!touched.malpracticeInsurance?.[index]?.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.malpracticeInsurance?.[index]?.endDate}
                  </Form.Control.Feedback>
                )}
                <br />
                {values.malpracticeInsurance?.[index]?.documentFilename && 
                <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.malpracticeInsurance?.[index]?.documentFilename}</span>)</Form.Label>
                }
                <Form.Control
                  type="hidden"
                  {...getFieldProps(`malpracticeInsurance[${index}].documentFilename`)}
                />
                <Form.Control
                  type="file"
                  name={`malpracticeInsurance[${index}].document`}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                    setFieldValue(`malpracticeInsurance[${index}].document`, file);
                  }}
                  isInvalid={Boolean(touched.malpracticeInsurance?.[index]?.document && errors.malpracticeInsurance?.[index]?.document)}
                />
                {!!touched.malpracticeInsurance?.[index]?.document && (
                  <Form.Control.Feedback type="invalid">
                    {errors.malpracticeInsurance?.[index]?.document}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            ))}
            <AddMalpracticeInsuranceButton />
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
          <div className="text-center mt-3 float-start">
            <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
          </div>
          <div id="submitButton" className="text-center mt-3 float-end">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
            >
              Save and Continue
            </Button>
          </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingMalpracticeInsurance;
