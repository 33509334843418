import React from "react";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Trash2} from "react-feather";
import usStateOptions from "../../utils/usStateOptions";
import stateLicenseTypes from "../../utils/stateLicenseTypes"
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import isFileSizeUnderLimit from "../../utils/fileUploadSize";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;


const AddStateLicenseButton = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("stateLicenses", [
        ...values.stateLicenses,
        {state: "", type: "", license: "", effectiveDate: "", expirationDate: "", document: null, documentFilename: ""},
      ]);
  }

  return (
    <Button onClick={(e) => handleAddClicked(e)} size="sm">+ Add Another</Button>
  );
}

const RemoveStateLicenseButton = ({index}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleRemoveClicked = (e) =>
  {
      e.preventDefault();
      setFieldValue("stateLicenses", values.stateLicenses.filter((_, i) => i !== index));
  }

  return (
    <Trash2 style={{'height': '12px', 'width': '12px'}} className="float-end" cursor="pointer" onClick={(e) => {handleRemoveClicked(e)}}/>
  );
}


const OnboardingStateLicenses = ({stage, token, data}) => {
  let usStateOptionList = usStateOptions.map((state) => {
    var label = state.label === "" ? "State..." : state.label;
    return (
      <option value={state.value} key={state.value}>{label}</option>
    )
  }, this);

  let stateLicenseTypeList = stateLicenseTypes.map((stateLicenseType) => {
    var label = stateLicenseType.label === "" ? "Type..." : stateLicenseType.label;
    return (
      <option value={stateLicenseType.value} key={stateLicenseType.value}>{label}</option>
    )
  }, this);
  
  var initialValues = {stateLicenses:[]}

  if (data && data.stateLicenses)
  {
    for (var i = 0; i < data.stateLicenses.length; i++)
    {
      if (data.stateLicenses[i].state == null)
      {
        data.stateLicenses[i].state = "";
      }
      if (data.stateLicenses[i].type == null)
      {
        data.stateLicenses[i].type = "";
      }
      if (data.stateLicenses[i].license == null)
      {
        data.stateLicenses[i].license = "";
      }
      if (data.stateLicenses[i].effectiveDate == null)
      {
        data.stateLicenses[i].effectiveDate = "";
      }
      if (data.stateLicenses[i].expirationDate == null)
      {
        data.stateLicenses[i].expirationDate = "";
      }
      if (data.stateLicenses[i].documentFilename == null)
      {
        data.stateLicenses[i].documentFilename = "";
      }

      data.stateLicenses[i].document = null;

      initialValues.stateLicenses.push(data.stateLicenses[i]);
    }
  }

  for (i=initialValues.stateLicenses.length; i < 1; i++)
  {
    initialValues.stateLicenses.push({state: "", type: "", license: "", effectiveDate: "", expirationDate: "", document: null, documentFilename: ""});
  }

  let onboardingStages = getOnboardingStages(data.preferences);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        stateLicenses: Yup.array().of(
          Yup.object().shape({
            state: Yup.string().max(255).required("State is required"),
            type: Yup.string().max(255).required("Type is required"),
            license: Yup.string().max(255).required("License number is required"),
            effectiveDate: Yup.date().required("Issue date is required"),
            expirationDate: Yup.date().required("Expiration date is required"),
            documentFilename: Yup.string(),
            document: Yup.mixed().when("documentFilename", {
              is: value => !value || value === "",
              then: Yup.mixed().required("Document is required"),
              otherwise: Yup.mixed()
            })
            .test(
              "fileSize",
              "File size too large; max file size is 10 Mb",
              isFileSizeUnderLimit
            ),
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try 
        {
          for (var i = 0; i < values.stateLicenses.length; i++)
          {
            // Set the document filename to the file we're sending so we can link the two up on the backend; preserve the old, if any, so we can delete
            values.stateLicenses[i].oldDocumentFilename = values.stateLicenses[i].documentFilename;
            if (values.stateLicenses[i].document !== null)
            {
              values.stateLicenses[i].documentFilename = values.stateLicenses[i].document.name;
            }
          }

          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              stateLicenses: values.stateLicenses
            })], {
              type: "application/json"
          }));

          for (i = 0; i < values.stateLicenses.length; i++)
          {
            if (values.stateLicenses[i].document !== null)
            {
              formData.append("files", values.stateLicenses[i].document);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>State Licenses*
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please include every current <i>and former</i> license.</span>
            </Form.Label><br />
            {values.stateLicenses.map((stateLicense, index) => (
                <div key={index}>
                <i>#{index +1}</i>
                {index > 0 && <RemoveStateLicenseButton index={index} />}
                <br />
                <Form.Select
                  {...getFieldProps(`stateLicenses[${index}].state`)} 
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.state && errors.stateLicenses?.[index]?.state)}
                  >
                    {usStateOptionList}
                </Form.Select>
                {!!touched.stateLicenses?.[index]?.state && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.state}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Select
                  {...getFieldProps(`stateLicenses[${index}].type`)} 
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.type && errors.stateLicenses?.[index]?.type)}
                  >
                    {stateLicenseTypeList}
                </Form.Select>
                {!!touched.stateLicenses?.[index]?.type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.type}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="License Number"
                  {...getFieldProps(`stateLicenses[${index}].license`)}
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.license && errors.stateLicenses?.[index]?.license)}
                />
                {!!touched.stateLicenses?.[index]?.license && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.license}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Issue Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`stateLicenses[${index}].effectiveDate`)} 
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.effectiveDate && errors.stateLicenses?.[index]?.effectiveDate)}
                />
                {!!touched.stateLicenses?.[index]?.effectiveDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.effectiveDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`stateLicenses[${index}].expirationDate`)} 
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.expirationDate && errors.stateLicenses?.[index]?.expirationDate)}
                />
                {!!touched.stateLicenses?.[index]?.expirationDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.expirationDate}
                  </Form.Control.Feedback>
                )}
                <br />
                {values.stateLicenses?.[index]?.documentFilename && 
                <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.stateLicenses?.[index]?.documentFilename}</span>)</Form.Label>
                }
                <Form.Control
                  type="hidden"
                  {...getFieldProps(`stateLicenses[${index}].documentFilename`)}
                />
                <Form.Control
                  type="file"
                  name={`stateLicenses[${index}].document`}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const file = event.currentTarget.files.length > 0 ? event.currentTarget.files[0] : null;
                    setFieldValue(`stateLicenses[${index}].document`, file);
                  }}
                  isInvalid={Boolean(touched.stateLicenses?.[index]?.document && errors.stateLicenses?.[index]?.document)}
                />
                {!!touched.stateLicenses?.[index]?.document && (
                  <Form.Control.Feedback type="invalid">
                    {errors.stateLicenses?.[index]?.document}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            ))}
            <AddStateLicenseButton />
          </Form.Group> 
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
          <div className="text-center mt-3 float-start">
            <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
          </div>
          <div id="submitButton" className="text-center mt-3 float-end">          
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
            >
              Save and Continue
            </Button>
          </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingStateLicenses;
