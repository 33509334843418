import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Badge, Container, Form, Button, Pagination, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import Spinner from 'react-bootstrap/Spinner';

import InputMask from "react-input-mask";

import authenticatedFetch from "../utils/fetch"

import NotyfContext from "../contexts/NotyfContext";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import getActivityDateTimeDisplay from "../utils/datetime"

import SlimPracticePicker from "../utils/SlimPracticePicker"

import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusCircle, MinusCircle, Clipboard, Flag, Clock, Send, Calendar, XSquare } from "react-feather";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilePdf,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import AvailableESignatureTemplates from "../utils/AvailableESignatureTemplates";

const formatMessageContentForDisplay = (content) => {
  var contentDisplay = content;
  if (contentDisplay && contentDisplay.length > 555)
  {
    contentDisplay = contentDisplay.substring(0, 553) + "...";
  }

  if (contentDisplay && contentDisplay.length > 0) {
    contentDisplay = contentDisplay.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
        )
    });
  }

  return contentDisplay;
}

const ConsentFormDisplay = ({filename, smsMessageId}) => {
  const notyf = useContext(NotyfContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClicked = (e) => {
    e.preventDefault();
    setIsDownloading(true);

    authenticatedFetch({
      method: "POST",
      requestData: {filename: filename},
      path:  "sms/" + smsMessageId + "/file/signeddownloadurl",
      successHandler: function(result) {
        setIsDownloading(false);
        window.open(result.url);
      },
      errorHandler: function(error) {
        setIsDownloading(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    isDownloading ?
    <Spinner animation="border" size="sm" />
    :
    <FontAwesomeIcon title={filename} icon={faFilePdf} onClick={(e) => {handleDownloadClicked(e)}} size="lg" style={{color: '#2B7A78', cursor: 'pointer'}}/>
  )
}

const ConsentFormsDownloadAll = ({filenames, smsMessageId}) => {
  const notyf = useContext(NotyfContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClicked = (e) => {
    e.preventDefault();
    setIsDownloading(true);
    
    authenticatedFetch({
      method: "POST",
      requestData: {filenames: filenames},
      path:  "sms/" + smsMessageId + "/files/signeddownloadurl",
      successHandler: function(result) {
        setIsDownloading(false);

        for (var i=0; i<result.urls.length; i++)
        {
          window.open(result.urls[i]);
        }
      },
      errorHandler: function(error) {
        setIsDownloading(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    isDownloading ?
    <Spinner animation="border" size="sm" />
    :
    <FontAwesomeIcon title="Download All" icon={faDownload} onClick={(e) => {handleDownloadClicked(e)}} size="sm" style={{color: '#888888', cursor: 'pointer'}}/>
  )
}

const ConsentFormsDisplay = ({consentForms, row}) => {
  if (row.original.consentFormsPending)
  {
    return <Clock style={{width: "18px"}}/>;
  }
  else if (consentForms && consentForms.length > 0) {
    var icons = consentForms.map((file, idx) => {
      return (
        <span key={idx}><ConsentFormDisplay key={idx} filename={file.filename} smsMessageId={row.original.key}/>&nbsp;</span>
      )
    });

    return (
      <>
      {icons}
      <ConsentFormsDownloadAll filenames={consentForms.map((file) => file.filename)} smsMessageId={row.original.key}/>
      </>
    )
  }

  return <></>
}

const FormsProcessedDisplay = ({consentFormsProcessed, row}) => {
  const[localConsentFormsProcessed, setLocalConsentFormsProcessed] = useState(consentFormsProcessed);

  const notyf = useContext(NotyfContext);

  const onConsentFormsProcessedChanged = (e) => {
    setLocalConsentFormsProcessed(e.target.checked);
    
    authenticatedFetch({
      method: "PUT",
      requestData: {consentFormsProcessed: e.target.checked},
      path:  "sms/" + row.original.key,
      successHandler: function(result) {
        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setLocalConsentFormsProcessed(!e.target.checked)
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  if (!row.original.consentFormsPending && row.original.consentForms && row.original.consentForms.length > 0)
  {
    return <Form.Check checked={localConsentFormsProcessed} onChange={(e) => {onConsentFormsProcessedChanged(e)}} />;
  }
  return <></>
}

const CancelScheduledMessageDisplay = ({row, reload}) => {
  const[canceling, setCanceling] = useState(false);
  const notyf = useContext(NotyfContext);

  const onCancelClicked = (e) => {
    e.preventDefault();

    if (!window.confirm("Are you sure you want to cancel this scheduled message?"))
    {
      return;
    }

    setCanceling(true);
    
    authenticatedFetch({
      method: "DELETE",
      path:  "sms/" + row.original.key,
      successHandler: function(result) {
        reload();
        setCanceling(false);
        notyf.open({type: "success", message: "Cancelled!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setCanceling(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  if (row.original.externalStatus === "scheduled" )
  {
    if (canceling)
    {
      return <Spinner animation="border" size="sm" />;
    }

    return <XSquare style={{'height': '16px', 'width': '16px'}} cursor="pointer" title="Cancel" onClick={(e) => {onCancelClicked(e)}} />;
  }
  return <></>
}

const ScheduleSendModal = ({ show, onClose, onSchedule, scheduleDate, setScheduleDate, scheduleTime, setScheduleTime, isScheduling, setIsScheduling}) => {
  const handleCloseClicked = (e) => {
    reset();
    onClose();
  }

  const handleScheduleClicked = (e) => {
    e.preventDefault();

    if (scheduleDate === "")
    {
      alert("Please select a date");
      return;
    }
    if (scheduleTime === "")
    {
      alert("Please select a time");
      return;
    }

    var parts = scheduleDate.split("-");
    var scheduleDateObj = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js

    var timeParts = scheduleTime.split(":");

    scheduleDateObj.setHours(timeParts[0]);
    scheduleDateObj.setMinutes(timeParts[1]);
    scheduleDateObj.setSeconds(0);

    if (scheduleDateObj < new Date(new Date().getTime() + 15*60000))
    {
      alert("Please schedule the message at least 15 minutes in the future");
      return;
    }

    setIsScheduling(true);

    onSchedule(e);
  }

  const reset = () => {
    setScheduleDate("");
    setScheduleTime("");
  }

  const handleDateChanged = (e) => {
    e.preventDefault();
    setScheduleDate(e.target.value);
  }

  const handleTimeChanged = (e) => {
    e.preventDefault();
    setScheduleTime(e.target.value);
  }

  
  return (
      <Modal show={show} onHide={handleCloseClicked}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Schedule SMS Message</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                <td>
                  Date:  
                </td>
                <td>
                  <Form.Control min="2024" style={{width: "40%"}} type="date" value={scheduleDate} onChange={(e) => {handleDateChanged(e)}}/>
                </td>  
              </tr>
              <tr>
                <td>
                  Time:  
                </td>
                <td>
                <Form.Control style={{width: "40%"}} type="time" step="300" value={scheduleTime} onChange={(e) => {handleTimeChanged(e)}}/>
                </td>  
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isScheduling ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <Button onClick={(e) => {handleCloseClicked(e)}}>Cancel</Button>
              <Button onClick={(e) => {handleScheduleClicked(e)}}>Schedule</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const MessageCreation = ({practiceId, onMessageSent}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [showScheduleSendModal, setShowScheduleSendModal] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);

  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");

  const [toNumber, setToNumber] = useState("");
  const [content, setContent] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [template, setTemplate] = useState("");
  const [sending, setSending] = useState(false);

  const [selectedWildcardPickerProviderId, setSelectedWildcardPickerProviderId] = useState("");
  const [selectedWildcardPickerProviderValue, setSelectedWildcardPickerProviderValue] = useState("");

  const [selectedWildcardPickerDate, setSelectedWildcardPickerDate] = useState("");
  const [selectedWildcardPickerDateValue, setSelectedWildcardPickerDateValue] = useState("");

  const [selectedWildcardPickerTime, setSelectedWildcardPickerTime] = useState("");
  const [selectedWildcardPickerTimeValue, setSelectedWildcardPickerTimeValue] = useState("");

  const [sendConsentForms, setSendConsentForms] = useState(false);
  const [selectedESignatureTemplates, setSelectedESignatureTemplates] = useState({});
  const [signerFirstName, setSignerFirstName] = useState("");
  const [signerLastName, setSignerLastName] = useState("");

  const notyf = useContext(NotyfContext);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  let sendEnabled = isH3Admin || authorities.includes("MESSAGING_EDIT");

  useEffect(() => {
    authenticatedFetch({
      path: "sms/" + practiceId + "/templates",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [practiceId])

  const onToNumberChanged = (e) => {
    e.preventDefault();

    setToNumber(e.target.value);
  }

  function parseNumber(raw)
  {
    var cleaned = (raw).replace(/\D/g, '');
    return cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }

  function parseToNumbers(raw)
  {
    var parts = raw.split(",");
    const parsedNumberList = new Set();
    for (var i=0; i<parts.length; i++)
    {
      var match = parseNumber(parts[i]);
      if (match) {
        parsedNumberList.add('(' + match[1] + ') ' + match[2] + '-' + match[3]);
      }
      else if (parts[i].trim().length > 0) {
        parsedNumberList.add(parts[i]);
      }
    }

    if (parsedNumberList.size > 0)
    {
      return Array.from(parsedNumberList).join(", ");
    }

    return null;
  }

  const onToNumberBlur = (e) => {
    var parsed = parseToNumbers(toNumber);
    if (parsed)
    {
      setToNumber(parsed);
    }

    return null;
  }

  async function onPasteClicked(e) 
  {
    e.preventDefault();

    const text = await navigator.clipboard.readText();
    const parsed = parseToNumbers(text);
    
    if (parsed)
    {
      setToNumber(parsed);
    }
    else
    {
      setToNumber(text);
    }
  }

  const onContentChanged = (e) => {
    e.preventDefault();

    setContent(e.target.value);
  }

  const onTemplateChanged = (e) => {
    e.preventDefault();

    setSelectedWildcardPickerProviderId("");
    setSelectedWildcardPickerProviderValue("");
    setSelectedWildcardPickerDate("");
    setSelectedWildcardPickerDateValue("");
    setSelectedWildcardPickerTime("");
    setSelectedWildcardPickerTimeValue("");

    for (var i = 0; i < data.length; i++)
    {
      if (data[i].id === parseInt(e.target.value))
      {
        setTemplate(e.target.value);
        setContent(data[i].content);
        setOriginalContent(data[i].content);
        return;
      }
    }

    setTemplate("");
    setContent("");
    setOriginalContent("");
  }

  function replaceProviderWildcard(str, value)
  {
    return str.replace("{provider}", value).replace("{PROVIDER", value).replace("{Provider}", value);
  }

  function replaceDateWildcard(str, value)
  {
    return str.replace("{date}", value).replace("{DATE", value).replace("{Date}", value)
  }

  function replaceTimeWildcard(str, value)
  {
    return str.replace("{time}", value).replace("{TIME", value).replace("{Time}", value)
  }

  // Don't hide and show these while someone is typing if they remove a wildcard character... just hide/show once based on the original template content
  var isProviderWildcardPickerVisible = originalContent.toLowerCase().indexOf("{provider}") >= 0;
  var isDateWildcardPickerVisible = originalContent.toLowerCase().indexOf("{date}") >= 0;
  var isTimeWildcardPickerVisible = originalContent.toLowerCase().indexOf("{time}") >= 0;

  const handleProviderWildcardPickerChanged = (value) => {
    // Save a copy of this
    setSelectedWildcardPickerProviderValue(value);

    // Preserve the other wildcards
    var newContent = originalContent;
    if (isDateWildcardPickerVisible && selectedWildcardPickerDateValue)
    {
      newContent = replaceDateWildcard(newContent, selectedWildcardPickerDateValue);
    }
    if (isTimeWildcardPickerVisible && selectedWildcardPickerTimeValue)
    {
      newContent = replaceTimeWildcard(newContent, selectedWildcardPickerTimeValue);
    }

    if (value)
    {
      newContent = replaceProviderWildcard(newContent, value);
    }

    setContent(newContent);
  }

  function getOrdinalNum(n) {
    return (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }

  const handleDateWildcardPickerChanged = (e) => {
    e.preventDefault();
    setSelectedWildcardPickerDate(e.target.value);
    var parts = e.target.value.split("-");

    // Preserve the other wildcards
    var newContent = originalContent;
    if (isProviderWildcardPickerVisible && selectedWildcardPickerProviderValue)
    {
      newContent = replaceProviderWildcard(newContent, selectedWildcardPickerProviderValue);
    }
    if (isTimeWildcardPickerVisible && selectedWildcardPickerTimeValue)
    {
      newContent = replaceTimeWildcard(newContent, selectedWildcardPickerTimeValue);
    }

    // If they just started keying in the date, it will come over as, e.g. 0002,
    if (parts.length > 0 && parseInt(parts[0]) > 1900)
    {
      var scheduledDate = new Date(parts[0], parts[1] - 1, parts[2]);

      var options = { weekday: 'long', month: 'long', day: 'numeric' };
      var formatted = scheduledDate.toLocaleDateString("en-US", options);

      // Get the number by hand
      var formattedParts = formatted.split(' ');
      if (formattedParts.length === 3)
      {
        var suffix = getOrdinalNum(parseInt(formattedParts[2]));
      }
      formatted = formatted + suffix;
      newContent = replaceDateWildcard(newContent, formatted);
    
      // Save a copy of this
      setSelectedWildcardPickerDateValue(formatted);
    }
    else
    {
      // Save a copy of this
      setSelectedWildcardPickerDateValue("");
    }

    setContent(newContent);
  }

  const handleTimeWildcardPickerChanged = (e) => {
    e.preventDefault();
    setSelectedWildcardPickerTime(e.target.value);

    var parts = e.target.value.split(":");

    // Preserve the other wildcards
    var newContent = originalContent;
    if (isProviderWildcardPickerVisible && selectedWildcardPickerProviderValue)
    {
      newContent = replaceProviderWildcard(newContent, selectedWildcardPickerProviderValue);
    }
    if (isDateWildcardPickerVisible && selectedWildcardPickerDateValue)
    {
      newContent = replaceDateWildcard(newContent, selectedWildcardPickerDateValue);
    }

    if (parts.length === 2)
    {
      var hours = parseInt(parts[0]);
      var minutes = parseInt(parts[1]);
      var suffix = hours > 11 ? "pm" : "am";
      
      hours = ((hours + 11) % 12 + 1);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var formatted = hours + ":" + minutes + suffix;

      newContent = replaceTimeWildcard(newContent, formatted);

      // Save a copy of this
      setSelectedWildcardPickerTimeValue(formatted);
    }
    else
    {
      // Save a copy of this
      setSelectedWildcardPickerTimeValue("");
    }
    
    setContent(newContent);
  }

  const handleSendConsentFormsChanged = (e) => {
    setSendConsentForms(e.target.checked);
  }

  const onESignatureTemplatesChanged = (templateId, isIncluded) => {
    selectedESignatureTemplates[templateId] = isIncluded;
    setSelectedESignatureTemplates(selectedESignatureTemplates);
  }

  const validate = (isSchedule) => {
    if (!toNumber || toNumber.length < 14)
    { 
      alert("Please enter valid phone number(s) with area code. Separate multiple numbers with a comma");
      return false;
    }

    var parts = toNumber.split(",");
    for (var i=0; i<parts.length; i++)
    {
      var match = parseNumber(parts[i]);
      if (!match)
      {
        alert("Please enter valid phone number(s) with area code. Separate multiple numbers with a comma");
        return false;
      }
    }

    if (!content || content.length < 4)
    {
      alert("Please enter a message");
      return false;
    }

    if (content.indexOf("{") >= 0 || content.indexOf("}") >= 0)
    {
      if (!window.confirm("This message may still contain template variables. Are you sure you want to " + (isSchedule ? "schedule" : "send") + "  it?"))
      {
        return false;
      }
    }

    if (sendConsentForms && Object.keys(selectedESignatureTemplates).length === 0)
    {
      alert("Please select at least one consent form");
      return false;
    }

    if (sendConsentForms)
    {
      var found=false;
      for (var key in selectedESignatureTemplates)
      {
        if (selectedESignatureTemplates[key])
        {
          found = true;
          break;
        }
      }

      if (!found)
      {
        alert("Please select at least one consent form");
        return false;
      }
    }

    if (sendConsentForms && (!signerFirstName || signerFirstName.length < 2 || !signerLastName || signerLastName.length < 2))
    {
      alert("Please enter the expected signer's first and last name");
      return false;
    }

    return true;
  }


  const handleScheduleSendClicked = (e) => {
    if (!validate(true))
    {
      return;
    }

    setShowScheduleSendModal(true);
  }

  const handleSendClicked = (e) => {
    e.preventDefault();

    if (!validate(scheduleDate !== "" && scheduleTime !== ""))
    {
      setIsScheduling(false);
      return;
    }

    var scheduleDateObj = null;

    if (scheduleDate !== "" && scheduleTime !== "")
    {
      var parts = scheduleDate.split("-");
      scheduleDateObj = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
  
      var timeParts = scheduleTime.split(":");
  
      scheduleDateObj.setHours(timeParts[0]);
      scheduleDateObj.setMinutes(timeParts[1]);
      scheduleDateObj.setSeconds(0);

      if (!window.confirm("Please confirm you want to schedule the message:\n\n\"" + content + "\"\n\nto: " + toNumber + "\n\nfor: " + scheduleDateObj.toLocaleDateString("en-US", {hour: 'numeric', minute:'2-digit', timeZoneName: 'short'})))
      {
        setIsScheduling(false);
        return;
      }
    }
    else
    {
      if (!window.confirm("Please confirm you want to send the message:\n\n\"" + content + "\"\n\nto: " + toNumber))
      {
        return;
      }
    }

    setSending(true);

    authenticatedFetch({
      path: "sms/" + practiceId,
      method: "POST",
      requestData: {toNumber: toNumber, content: content, scheduleDateTime: scheduleDateObj, sendConsentForms: sendConsentForms, includedESignatureTemplates: selectedESignatureTemplates, signerFirstName: signerFirstName, signerLastName: signerLastName},
      successHandler: function() {
        setSending(false);
        setIsScheduling(false);
        setShowScheduleSendModal(false);
        setToNumber("");
        setContent("");
        setOriginalContent("");
        setTemplate("");

        setSelectedWildcardPickerProviderId("");
        setSelectedWildcardPickerProviderValue("");
        setSelectedWildcardPickerDate("");
        setSelectedWildcardPickerDateValue("");
        setSelectedWildcardPickerTime("");
        setSelectedWildcardPickerTimeValue("");

        setSendConsentForms(false);
        setSelectedESignatureTemplates({});
        setSignerFirstName("");
        setSignerLastName("");
        setScheduleDate("");
        setScheduleTime("");   

        onMessageSent();

        notyf.open({type: "success", message: "Message " + (scheduleDateObj != null ? "scheduled" : "sent") + "!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        setSending(false);
        setIsScheduling(false);

        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  var templateList;
  if (!isLoaded)
  {
    templateList = [<option value={0} key={0}>Loading...</option>];
  }
  else if (error || !data)
  {
    templateList = [<option value={0} key={0}>Error... Please reload the page...</option>];
  }
  else
  {
    templateList = [<option value={0} key={0}>Start from a template (optional)...</option>];
    templateList.push(data.map((template, i) => {
      return (
        <option value={template.id} key={template.id}>{template.name}</option>
      )
    }, this));
  }

  return (
    <>
    <Card>
    <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Compose New Message
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="table-borderless table-sm">
        <tbody>
          <tr>
            <td>To:</td>
            { window.isSecureContext && navigator.clipboard.readText ?
            <td>
            <Form.Control maxLength={75} placeholder="(555) 444-1234, (555) 777-4321, ..." as="input" value={toNumber} onBlur={(e) => {onToNumberBlur(e)}} onChange={(e) => {onToNumberChanged(e)}} style={{width: "90%", display: "inline"}}/>
            &nbsp;&nbsp;
            <OverlayTrigger
              triggers="[hover]"
              placement="bottom"
              overlay={<Tooltip >Paste from clipboard</Tooltip>}
              >
                <Clipboard onClick={(e) => {onPasteClicked(e)}} style={{'height': '16px', 'width': '16px', 'cursor': 'pointer', 'marginLeft': '-36px', color: "#2B7A78"}} title="Paste from clipboard"/>
            </OverlayTrigger>
            </td>
            : 
            <td>
              <Form.Control maxLength={75} placeholder="(555) 444-1234, (555) 777-4321, ..." as="input" value={toNumber} onBlur={(e) => {onToNumberBlur(e)}} onChange={(e) => {onToNumberChanged(e)}} style={{width: "90%", display: "inline"}}/>
            </td>
            }
          </tr>
          <tr>
            <td>Template:</td>
            <td>
              <Form.Select value={template} onChange={(e) => {onTemplateChanged(e)}} style={{width: "90%"}} >
                {templateList}
              </Form.Select>
            </td>
          </tr>
          {isProviderWildcardPickerVisible ?
            <tr>
              <td>Provider:</td>
              <td><ProviderTemplateWildcardPicker selectedProviderId={selectedWildcardPickerProviderId} setSelectedProviderId={setSelectedWildcardPickerProviderId} handleProviderWildcardPickerChanged={handleProviderWildcardPickerChanged} practiceId={practiceId}/></td>
            </tr>
          : <></>
          }
          {isDateWildcardPickerVisible ?
          <tr>
            <td>Date:</td>
            <td><Form.Control min="2022" style={{width: "40%"}} type="date" value={selectedWildcardPickerDate} onChange={(e) => {handleDateWildcardPickerChanged(e)}}/></td>
          </tr>
          : <></>
          }
          {isTimeWildcardPickerVisible ?
          <tr>
            <td>Time:</td>
            <td><Form.Control style={{width: "40%"}} type="time" step="300" value={selectedWildcardPickerTime} onChange={(e) => {handleTimeWildcardPickerChanged(e)}}/></td>
          </tr>
          : <></>
          }
          <tr>
            <td>Message:</td>
            <td>
              <Form.Control disabled={!sendEnabled} value={content} maxLength={999} placeholder="Type your message or start from a template..." as="textarea" onChange={(e) => {onContentChanged(e)}} style={{ display: "block", minHeight: '250px', width: '90%'}}/>
            </td>
          </tr>
          <tr>
            <td>Consent<br />Forms:</td>
            <td>
              <Form.Check type="switch" checked={sendConsentForms} style={{display: "inline"}}  onChange={(e) => {handleSendConsentFormsChanged(e)}}/>&nbsp;&nbsp;Include link to selected consent e-signature form(s):
            </td>
          </tr>
          {
            (sendEnabled && sendConsentForms)
            &&
            <>
            <tr>
              <td>&nbsp;</td>
              <td>
                <AvailableESignatureTemplates pathPrefix="sms" compact={true} canEdit={sendEnabled} onESignatureTemplatesChanged={onESignatureTemplatesChanged} />
              </td>
            </tr>
            <tr >
              <td>&nbsp;</td>
              <td><Form.Control value={signerFirstName} onChange={(e) => {setSignerFirstName(e.target.value)}} style={{width: "44%", display: "inline-block"}} placeholder="Signer's first name..."/>
                  <Form.Control value={signerLastName} onChange={(e) => {setSignerLastName(e.target.value)}} style={{width: "44%", marginLeft: "2%", display: "inline-block"}} placeholder="Signer's last name..."/>
                  <br /><br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> (Link will be appended to the bottom of the SMS message)</span>
              </td>
            </tr>
            </>
          }
          <tr><td colSpan="2"></td></tr>
          <tr><td colSpan="2"></td></tr>
          <tr>
            <td colSpan="2">
              {sending ?
                <Spinner animation="border" size="sm" /> :
                <>
                  <Button variant="primary" disabled={!sendEnabled} type="submit" onClick={(e) => {handleSendClicked(e)}}>
                    <Send className="feather" />&nbsp;&nbsp;Send SMS Message
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button variant="primary" disabled={!sendEnabled} type="submit" onClick={(e) => {handleScheduleSendClicked(e)}}>
                    <Calendar className="feather" />&nbsp;&nbsp;Schedule SMS Message
                  </Button>
                </>
              }
            </td>
          </tr>
        </tbody>
        </Table>
      </Card.Body>
    </Card>
    <ScheduleSendModal isScheduling={isScheduling} setIsScheduling={setIsScheduling} scheduleDate={scheduleDate} setScheduleDate={setScheduleDate} scheduleTime={scheduleTime} setScheduleTime={setScheduleTime} onSchedule={handleSendClicked} show={showScheduleSendModal} onClose={() => setShowScheduleSendModal(false)} />
    </>
  )
};

const MessagingPracticeSettings = ({practiceId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "sms/" + practiceId + "/settings",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [practiceId])

  return (
    <Card>
    <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
          Settings
        </Card.Title>
        <Card.Body>
        { (!isLoaded || error || !data) ?
          <>
          {!isLoaded ?
          "Loading..."
          :
          "Error... please reload the page..."
          }
          </>
        :
        <Table className="table-borderless table-sm">
          <tbody>
            <tr>
              <td>Message Sending Number:</td>
            </tr>
            <tr>
              <td>
                <Form.Control size="sm" disabled={true} value={data.fromNumber || ''}/>
              </td>
            </tr>
            <tr>
              <td>SMS Reply Forwarding Email:</td>
            </tr>
            <tr>
              <td>
                <Form.Control size="sm" disabled={true} value={data.smsReplyForwardingEmail  || ''}/>
              </td>
            </tr>
            <tr>
              <td>SMS Reply Auto-Response:</td>
            </tr>
            <tr>
              <td>
                <Form.Control size="sm" style={{minHeight: "125px"}} as="textarea" disabled={true} value={data.smsReplyAutoResponse || ''}/>
              </td>
            </tr>
            <tr>
              <td>Phone Call Forwarding Number:</td>
            </tr>
            <tr>
              <td>
                <Form.Control size="sm" disabled={true} value={data.callForwardNumber || ''}/>
              </td>
            </tr>
          </tbody>
        </Table>
        }
        </Card.Body>
      </Card.Header>
    </Card>
  )
}

function ToNumberColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <InputMask
        mask="(999) 999-9999"
        size="sm"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        >
        {(inputProps) => <Form.Control placeholder="Search..." {...inputProps} style={{width: "130px", display: "inline", marginLeft:"8px"}} type="text"/>}

      </InputMask>
  );
}

function customToNumberFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
      if (!filterValue)
      {
        return true;
      }

      let phoneNumberFromRow = row.id.indexOf(".") > 0 ? row.original.createdBy : row.original.toNumber;

      for (var i=0; i<filterValue.length; i++)
      {
        if (i >= phoneNumberFromRow.length)
        {
          return false;
        }

        if (filterValue[i] !== "_" && filterValue[i] !== phoneNumberFromRow[i])
        {
          return false;
        }
      }

      return true;
  });
}

function ContentColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  return (
    <Form.Control 
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
      className="mt-2"
      style={{width: "250px", display: "inline", marginLeft:"8px"}}
    />
  );
}

function contentContainsAllWords(words, content)
{
  for (var i=0; i < words.length; i++)
  {
    if(!content || content.toLowerCase().indexOf(words[i].trim().toLowerCase()) < 0)
    {
      return false;
    }
  }

  return true;
}

function customContentFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
      if (!filterValue)
      {
        return true;
      }

      // Always show all replies (if the parent is shown)
      if (row.id.indexOf(".") > 0)
      {
        return true;
      }

      let words = filterValue.trim().split(" ");
      if (contentContainsAllWords(words, row.original.content))
      {
        return true;
      }

      // We don't match, but if we have sub-rows that do match, we need to show ourselves as the parent for them to be able to display
      var matchingSubRow = false;
      for (var i=0; i<row.originalSubRows.length; i++)
      {
        if (contentContainsAllWords(words, row.originalSubRows[i].content))
        {
          matchingSubRow = true;
          break;
        }
      }

      return matchingSubRow;
  });
}

function getConsentFormsLength(consentForms)
{
  if (consentForms)
  {
    return consentForms.length;
  }

  return 0;

}

const MessageHistoryPaginated = ({messageList, isLoaded, error, reload}) => {
  const data = messageList;

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({

              })}
            >
              {row.isExpanded ? (
                <MinusCircle className="feather" />
              ) : (
                <PlusCircle className="feather" />
              )}
            </span>
          ) : null,
      },
      {
        Header: "Created At",
        accessor: "created",
        key: "created",
        Cell: ({ value, row }) => (
          getActivityDateTimeDisplay(value, true)
        )
      },
      {
        Header: "Scheduled For",
        accessor: "scheduleDateTime",
        key: "scheduleDateTime",
        Cell: ({ value, row }) => (
          <span style={{textDecoration: row.original.externalStatus === "canceled" ? "line-through" : ""}}>{getActivityDateTimeDisplay(value, true)}</span>
        )
      },
      {
        Header: "By",
        accessor: "createdBy",
        key: "createdBy",
        Cell: ({ value, row }) => (
          row.id.indexOf(".") > 0 ? <i>Reply from: {value}</i> : <>{value}</>
        )
      },
      {
        Header: "To",
        accessor: "toNumber",
        key: "toNumber",
        filter: customToNumberFilter,
        Filter: ToNumberColumnFilter,
      },
      {
        Header: "Content",
        accessor: "content",
        key: "content",
        filter: customContentFilter,
        Filter: ContentColumnFilter,
        Cell: ({ value, row }) => (
          formatMessageContentForDisplay(value)
        )
      },
      {
        Header: "Consent Forms",
        accessor: "consentForms",
        key: "consentForms",
        Cell: ({ value, row }) => (
          <ConsentFormsDisplay consentForms={value} row={row}/>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          var lenA = getConsentFormsLength(rowA.values[columnId]);
          var lenB = getConsentFormsLength(rowB.values[columnId]);

          if (lenA === lenB)
          {
            return 0;
          }

          if (lenA > 0 && lenB === 0)
          {
            return -1;
          }

          return 1;
        },
      },
      {
        Header: "Forms Processed",
        accessor: "consentFormsProcessed",
        key: "consentFormsProcessed",
        Cell: ({ value, row }) => (
          <FormsProcessedDisplay consentFormsProcessed={value} row={row}/>
        )
      },
      {
        Header: "Status",
        accessor: "externalStatus",
        key: "externalStatus",
        Cell: ({ value, row }) => {
          var badgeBg = "secondary";
          if (value)
          {
            if (value === "delivered")
            {
              badgeBg = "primary"
            }
            else if (value === "failed" || value === "undelivered")
            {
              badgeBg = "danger"
            }
            else if (value === "scheduled")
            {
              badgeBg = "warning"
            }
          }
          
          var badge;
          if (value === null)
          {
            badge = <Spinner animation="border" size="sm" />
          }
          else
          {
            badge = <span key={value}>
              <Badge bg={badgeBg} pill>{value}</Badge>
            </span>
          }
          return badge;
        }
      },
      {
        Header: "",
        accessor: "cancel",
        key: "cancel",
        Cell: ({ value, row }) => (
          <CancelScheduledMessageDisplay row={row} reload={reload}/>
        )
      },
    ],
  []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
              id: 'created',
              desc: true
          }
      ], },
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
          <Card.Title>
          Message History
          </Card.Title>
        </Card.Header>
        <Card.Body>
        <Table className="table-sm" striped bordered {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = (column.id === "expander" || column.key === "toNumber" || column.key === "content" || column.key === "consentFormsProcessed" || column.key === "cancel") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                      {(column.id !== "expander" && column.key !== "toNumber" && column.key !== "content" && column.key !== "consentFormsProcessed" && column.key !== "cancel") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : 
                        (
                          (column.key === "toNumber" || column.key === "content") ? <>{column.render("Filter")}</>
                          :
                          <></>
                        )
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={8}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={8}>Loading...</td></tr>) : (
              page.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <tr style={{fontFamily: "monospace"}} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td style={{whiteSpace: (cell.column.key !== "content") ? "nowrap" : ""}} className={(cell.column.key === "content" || cell.column.key === "created" || cell.column.key === "createdBy" || cell.column.key === "toNumber" || cell.column.key === "consentForms" || cell.column.key === "consentFormsProcessed") && row.id.indexOf(".") > 0 ? "table-secondary" : ""} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })))
            }
          </tbody>
        </Table>
        <br />
        <Row>
          <Col md="6">
            <span className="mx-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              min={1}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
        </Card.Body>
    </Card>
  )
};

const PracticePatientMessaging = ({practiceId}) => {
  // Handle state here so we can reload history after message is sent
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    reload();

    const refreshHistoryInterval = setInterval(() => reload(), 5000);
    return () => {
      // Equivalent to componentWillUnmount
      clearInterval(refreshHistoryInterval);
    };
  }, [practiceId])

  const reload = () => {
    authenticatedFetch({
      path: "sms/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  var messageList = [];
  if (isLoaded)
  {
    messageList = data.map((message, i) => {
      var subRows = message.replies.map((reply, j) => {
        return {key: reply.id, content: reply.content, createdBy: reply.fromNumber, created: reply.created}
      }, this);

      return {key: message.id, toNumber: message.toNumber, content: message.content, consentForms: message.consentForms, consentFormsPending: message.consentFormsPending, consentFormsProcessed: message.consentFormsProcessed, externalStatus: message.externalStatus, created: message.created, createdBy: message.createdBy, scheduleDateTime: message.scheduleDateTime, subRows: subRows}
    }, this);
  }

  return (
    <>
      <Row>
        <Col lg="8" md="12">
          <MessageCreation practiceId={practiceId} onMessageSent={reload} />
        </Col>
        <Col lg="4">
          <MessagingPracticeSettings practiceId={practiceId} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <MessageHistoryPaginated messageList={messageList} isLoaded={isLoaded} error={error} reload={reload} />
        </Col>
      </Row>
    </>
  )
};


const ProviderTemplateWildcardPicker = ({practiceId, selectedProviderId, setSelectedProviderId, handleProviderWildcardPickerChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "sms/providers/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, [practiceId]);

  const onProviderWildcardPickerChanged = (e) => {
    e.preventDefault();
    setSelectedProviderId(e.target.value);

    if (e.target.value > 0)
    {
      handleProviderWildcardPickerChanged(e.target.options[e.target.selectedIndex].text);
    }
    else
    {
      // First option
      handleProviderWildcardPickerChanged("");
    }
  }

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let providerOptionList = [<option value={0} key={0}>Select a provider (optional)...</option>];
    providerOptionList.push(data.map((provider, i) => {
      return (
        <option value={provider.id} key={provider.id}>{provider.displayName}</option>
      )
    }, this));

    return (
      <Form.Select style={{width: "90%"}} onChange={(e) => {onProviderWildcardPickerChanged(e)}} value={selectedProviderId}>{providerOptionList}</Form.Select>
    )
  }
};

const Messaging = () => {
  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "sms/practices",
      successHandler: function(result) {
        if (result && result.length === 1)
        {
          // Skip the practice picker if there is only one practice
          setPracticeId(result[0].id);
          setPracticeName(result[0].name);
        }

        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, []);

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(e.target.value);
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleSelectDifferentPractice = (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null)
  }

  const title = "Messaging" + (practiceName != null ? " | " + practiceName : "")

  return (
    <React.Fragment>
        <Helmet title={title} />
        <h1 className="h3 mb-3">{title}</h1>
        {practiceId > 0 && data.length > 1 ? <><Button variant="secondary" size="sm" onClick={(e) => {handleSelectDifferentPractice(e)}}>&lt; Select a different practice</Button><br /><br /></> : <></>}
        <Container fluid className="p-0">
          { practiceId > 0 ? <PracticePatientMessaging practiceId={practiceId} /> : <SlimPracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
      </Container>
    </React.Fragment>
  )
};

export default Messaging;
