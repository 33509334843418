import React from "react";
import { Col, Row, Form } from "react-bootstrap";


const SlimPracticePicker = ({onPracticeChanged, isLoaded, error, data}) => {
  var practiceList;
  if (!isLoaded)
  {
    practiceList = [<option value={0} key={0}>Loading...</option>];
  }
  else if (error || !data)
  {
    practiceList = [<option value={0} key={0}>Error... Please reload the page...</option>];
  }
  else
  {
    practiceList = [<option value={0} key={0}>Select a practice...</option>];
    practiceList.push(data.map((practice, i) => {
      return (
        <option value={practice.id} key={practice.id}>{practice.name}</option>
      )
    }, this));
  }

  return (
    <Row>
      <Col lg="12">
        <Form.Select onChange={(e) => {onPracticeChanged(e)}} style={{width: "500px"}} >
          {practiceList}
        </Form.Select>
      </Col>
    </Row>
  )
};


export default SlimPracticePicker;
