import React, { useContext  } from "react";
import { Navigate } from "react-router-dom";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

// For routes that can only be accessed by authenticated users
function H3AdminGuard({ children }) {
  const { isH3Admin } = useContext(UserSettingsContext);


  if (!isH3Admin)
  {
    return <Navigate to={"/"}/>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default H3AdminGuard;
