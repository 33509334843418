const rawMaritalStatuses =[
  {"value":null, "label":""},
  {"value":"SINGLE", "label":"Single"},
  {"value":"MARRIED", "label":"Married"},
  {"value": "WIDOWED", "label":"Widowed"},
  {"value": "SEPARATED", "label":"Separated"},
  {"value": "DIVORCED", "label":"Divorced"}
];
  

export default rawMaritalStatuses;