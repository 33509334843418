import React, { useState, useEffect } from "react";
import { Card, Container, Col, Row, Form} from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import authenticatedFetch from "../../utils/fetch"
import RevenueTeamLabel from "../../components/RevenueTeamLabel";

import { Doughnut, Bar, Line } from "react-chartjs-2";

// import usePalette from "../../hooks/usePalette";

const CarrierSelect = ({ selectedCarrierId, handleCarrierChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "carrier/revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  if (!isLoaded)
  {
    return (<Form.Select size="sm" disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select size="sm" disabled={true}><option value={0} key={0}>Please reload...</option></Form.Select>);
  }
  else
  {
    let carrierOptionList = [<option value={0} key={0}>Filter by carrier...</option>];
    carrierOptionList.push(data.map((carrier, i) => {
      return (
        <option value={carrier.id} key={carrier.id}>{carrier.name}</option>
      )
    }, this));

    carrierOptionList.push(<option value={-1} key={-1}>(No Carrier)</option>);
    return (
      <Form.Select size="sm" onChange={(e) => {handleCarrierChanged(e.target.value, e)}} value={selectedCarrierId}>{carrierOptionList}</Form.Select>
    )
  }
}

const SpeedBenchmark = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    authenticatedFetch({
      path: "task/stats/averagecompletiontime",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  var content;
  if (error)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "200px", paddingTop: "70px" }} className='text-danger'>Please reload...</div></>
  }
  else if (!isLoaded)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "200px", paddingTop: "70px"}}>Loading...</div></>
  }
  else
  {
    // const palette = usePalette();

    let h3Average = 36;  // TOOD server should likely supply this, by specialty, etc
    let you = data["value"];
    let revenueTeamName = data["revenueTeamName"];

    const chartData = {
      labels: ['H3 Average', revenueTeamName],
      datasets: [
        {
          label: 'Days',
          backgroundColor: [
            "#E8EAED",
            "#3AAFA9",
          ],
          data: [h3Average, you],
          barPercentage: 0.75,
          categoryPercentage: 0.75,
        }
      ]
    }
  
    const options = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }],
        xAxes: [
          {
            stacked: false,
            gridLines: {
              color: "transparent",
            },
          },
        ],
      }
    };
  
    content = <Bar height={200} data={chartData} options={options}  />;
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: "-15px"}}>
        <Card.Title>Task Completion</Card.Title>
        <h6 className="card-subtitle text-muted">
          Average lag, in <b>days</b>, rolling 90 day window
        </h6>
      </Card.Header>
      <Card.Body className="d-flex">
          {content}
      </Card.Body>
    </Card>
  );
};

const AccuracyBenchmark = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    authenticatedFetch({
      path: "task/stats/futureactiondate",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result["value"]);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  var content;
  if (error)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "223px", paddingTop: "90px" }} className='text-danger'>Please reload...</div></>
  }
  else if (!isLoaded)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "223px", paddingTop: "90"}}>Loading...</div></>
  }
  else
  {
      if (data === -1)
      {
        // N/A
        content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "223px", paddingTop: "90"}}>(n/a)</div></>
      }
      else if (data === 100)
      {
        content = <><div style={{fontSize: "7em", textAlign: "center", width: "100%", height: "223px", paddingTop: "10px", color: "#3AAFA9"}}>{data}%</div></>
      }
      else
      {
        var className = 'text-danger';
        if (data >= 90)
        {
          className = 'text-warning';
        }
        
        content = <><div style={{fontSize: "7em", textAlign: "center", width: "100%", height: "223px", paddingTop: "10px"}} className={className}>{data}%</div></>
      }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title style={{marginBottom: "-20px"}}>Next Action Dates in Future</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
          {content} 
      </Card.Body>
    </Card>
  );
};

// const HoursSaved = () => {
//   return (
//     <Card>
//       <Card.Header>
//         <Card.Title style={{marginBottom: "-20px"}}>Hours Saved Past 30 Days</Card.Title>
//       </Card.Header>
//       <Card.Body className="d-flex">
//           <div style={{fontSize: "7em", textAlign: "center", width: "100%", height: "200px", paddingTop: "10px", color: "#3AAFA9"}}>14</div>
          
//       </Card.Body>
//     </Card>
//   );
// };

// const ProjectedWorkload = () => {
//   return (
//     <Card>
//       <Card.Header>
//         <Card.Title style={{marginBottom: "-20px"}}>Projected 30 Day Workload</Card.Title>
//       </Card.Header>
//       <Card.Body className="d-flex">
//           <div style={{ height: "200px", paddingTop: "40px", width: "100%"}}>
//             <div style={{fontSize: "3.5em", textAlign: "center", width: "100%", marginBottom: "50px"}}>1.4 FTEs</div>
//               <Badge bg="" style={{fontSize: "1.3em"}} className="badge-soft-danger me-2">
//               <ArrowUp size={22} /> 56%
//               </Badge>
//           </div>
//       </Card.Body>
      
//     </Card>
//   );
// };

const OpenTasks = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  
  useEffect(() => {
    authenticatedFetch({
      path: "task/stats/opentasksbytype",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  var content;
  var title = "Open Tasks";
  if (error)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "200px", paddingTop: "70px" }} className='text-danger'>Please reload...</div></>
  }
  else if (!isLoaded || !data)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "200px", paddingTop: "70px"}}>Loading...</div></>
  }
  else
  {
    title = <>Open Tasks: {data.total}</>

    if (data.total === 0)
    {
       // N/A
      content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "200px", paddingTop: "70px"}}>(n/a)</div></>
    }
    else
    {
      // const palette = usePalette();

      const chartData = {
        labels: Object.keys(data["openTasks"]),
        datasets: [
          {
            data: Object.values(data["openTasks"]),
            backgroundColor: [
              "#E8EAED",
              "#3AAFA9",
              "#2B7A78",
              "#DEF2F1",
              "#17252A",
            ],
            borderColor: "transparent",
          },
        ]
      };

      const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 45,
        legend: {
          display: true,
          position: "bottom",
        },
        
      };

      content = <><div className="chart chart-sm"><Doughnut data={chartData} options={options} /></div></>
    }
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: "-15px"}}>
        <Card.Title>{title}</Card.Title>
        <h6 className="card-subtitle text-muted">
          Number of open tasks, by category
        </h6>
      </Card.Header>
      <Card.Body>
          {content}
      </Card.Body>
    </Card>
  );
};

const TaskLag = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState(0);

  useEffect(() => {
    authenticatedFetch({
      path: "task/stats/averagecompletiontimebymonthandtype?carrierId=" + selectedCarrierId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [selectedCarrierId])

  const handleCarrierChanged = (carrierId, e) => {
    e.preventDefault();
    setSelectedCarrierId(carrierId);
  }

  var content;
  if (error)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "300px", paddingTop: "120px" }} className='text-danger'>Please reload...</div></>
  }
  else if (!isLoaded || !data)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "300px", paddingTop: "120px"}}>Loading...</div></>
  }
  else
  {
    // const palette = usePalette();
    var colors = ["#E8EAED", "#3AAFA9", "#2B7A78", "#DEF2F1", "#17252A"];

    var datasets = [];
    for (var i=0; i<data["timeSeriesList"].length; i++)
    {
      datasets.push({
        label: data["timeSeriesList"][i]["label"],
          data: data["timeSeriesList"][i]["data"],
          backgroundColor: colors[i % colors.length],
          borderColor: colors[i % colors.length],
          fill: false,
      })
    }

    const chartData = {
      labels: data["xAxisLabels"],
      datasets: datasets
    };

    const options = {
      maintainAspectRatio: false,
      cutoutPercentage: 45,
      legend: {
        display: true,
        position: "bottom",
      },
    };

    content = <div className="chart"><Line data={chartData} options={options} /></div>
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: "-15px"}}>
        <Card.Title>Task Lag <div className="float-end"><CarrierSelect selectedCarrierId={selectedCarrierId} handleCarrierChanged={handleCarrierChanged} /></div></Card.Title>
        <h6 className="card-subtitle text-muted">
          Average lag, in <b>days</b>, by category, by month
        </h6>
      </Card.Header>
      <Card.Body>
        {content}
      </Card.Body>
    </Card>
  );
};

const InitialEnrollmentLagByCarrier = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    authenticatedFetch({
      path: "task/stats/initialenrollmenttimebycarrier",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])

  var content;
  if (error)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "300px", paddingTop: "120px" }} className='text-danger'>Please reload...</div></>
  }
  else if (!isLoaded || !data)
  {
    content = <><div style={{fontSize: "1.5em", textAlign: "center", width: "100%", height: "300px", paddingTop: "120px"}}>Loading...</div></>
  }
  else
  {
    const chartData = {
      labels: data["xAxisLabels"],
      datasets: [
        {
          label: data["timeSeriesList"][0]["label"],
          backgroundColor: "#3AAFA9",
          borderColor: "#3AAFA9",
          data: data["timeSeriesList"][0]["data"],
          barPercentage: 0.75,
          categoryPercentage: 0.5,
        },
        {
          label: data["timeSeriesList"][1]["label"],
          backgroundColor: "#E8EAED",
          borderColor: "#E8EAED",
          data: data["timeSeriesList"][1]["data"],
          barPercentage: 0.75,
          categoryPercentage: 0.5,
        },
      ]
    }

    const options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
      },
      scales: {
        yAxes: [{
          stacked: false,
          ticks: {
            beginAtZero: true
          }
        }],
        xAxes: [
          {
            stacked: false,
            gridLines: {
              color: "transparent",
            },
          },
        ],
      },
    };

    content = <div className="chart"><Bar data={chartData} options={options} /></div>
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: "-15px"}}>
        <Card.Title>Initial Enrollment Lag</Card.Title>
        <h6 className="card-subtitle text-muted">
          Average lag, in <b>days</b>, by carrier, rolling 90 day window
        </h6>
      </Card.Header>
      <Card.Body>
        {content}
      </Card.Body>
    </Card>
  );
};


// const H3Score = () => {
//     return (
//         <Table striped bordered size="sm">
//           <tbody>
//           <tr>
//             <td style={{textAlign: "center", width: "100px"}}><FontAwesomeIcon icon={faStar} size="2xl" style={{color: "#3AAFA9", width: "25px", height: "25px"}} /></td>
//             <td style={{textAlign: "center", width: "100px"}}><FontAwesomeIcon icon={faStar} size="2xl" style={{color: "#3AAFA9", width: "25px", height: "25px"}} /></td>
//             <td style={{textAlign: "center", width: "100px"}}><FontAwesomeIcon icon={faStar} size="2xl" style={{color: "#3AAFA9", width: "25px", height: "25px"}} /></td>
//             <td style={{textAlign: "center", width: "100px"}}><FontAwesomeIcon icon={faStar} size="2xl" style={{ width: "25px", height: "25px"}} /></td>
//             <td style={{textAlign: "center", width: "100px"}}><FontAwesomeIcon icon={faStar} size="2xl" style={{ width: "25px", height: "25px"}} /></td>
//           </tr>
//           <tr>
//             <td style={{textAlign: "center"}}>System</td>
//             <td style={{textAlign: "center"}}>Speed</td>
//             <td style={{textAlign: "center"}}>Automation</td>
//             <td style={{textAlign: "center"}}>Accuracy</td>
//             <td style={{textAlign: "center"}}>Usage</td>
//           </tr>
//           </tbody>
//         </Table>
//     )
//   };

const KPIs = () => {

    return (
      <React.Fragment>
        <Helmet title="KPIs" />
        <Container fluid className="p-0">
          <Row style={{marginBottom: "5px"}}>
            <Col lg="12">
              <h1 className="h3 mb-3">KPIs | <RevenueTeamLabel /></h1>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <SpeedBenchmark />
            </Col>
            <Col lg="4">
              <AccuracyBenchmark />
            </Col>
            <Col lg="4">
              <OpenTasks />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <TaskLag />
            </Col>
            <Col lg="6">
              <InitialEnrollmentLagByCarrier />
            </Col>
          </Row>
          
        </Container>
        </React.Fragment>
    )
  };
  
  export default KPIs;
  