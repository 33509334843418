import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import OnboardingDemographics from "../../components/onboarding/OnboardingDemographics";
import OnboardingPeerReferences from "../../components/onboarding/OnboardingPeerReferences";
import OnboardingTraining from "../../components/onboarding/OnboardingTraining";
import OnboardingWorkHistory from "../../components/onboarding/OnboardingWorkHistory";
import OnboardingStateLicenses from "../../components/onboarding/OnboardingStateLicenses";
import OnboardingMalpracticeInsurance from "../../components/onboarding/OnboardingMalpracticeInsurance";
import OnboardingBoardCertifications from "../../components/onboarding/OnboardingBoardCertifications";
import OnboardingImmunizations from "../../components/onboarding/OnboardingImmunizations";
import OnboardingDocuments from "../../components/onboarding/OnboardingDocuments";
import OnboardingSigning from "../../components/onboarding/OnboardingSigning";

import logo from "../../assets/img/logo_dark.png";

import getOnboardingStages from "../../utils/onboardingStages";

const { REACT_APP_BACKEND_URL } = process.env;

const OnboardingPage = () => {
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let { stage, token } = useParams();

  if (stage === "start")
  {
    stage = "demographics";
  }

  useEffect(() => {
    if (token && stage && stage !== "success")
    {
      fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, {credentials: "include"})
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          if (parseInt(error.message) === 400 || parseInt(error.message) === 401 || parseInt(error.message) === 404)
          {
            setNotFound(true);
          }
          else if (parseInt(error.message) === 422)
          {
            setExpired(true);
          }
          
          setError(error);
        }
      )
    }
    else if (stage === "success")
    {
      setIsLoaded(true);
    }
    else
    {
      setIsLoaded(true);
      setNotFound(true);
    }
  }, [stage, token])

  if (!isLoaded)
  {
    return (
      <React.Fragment>
        <Helmet title="Onboarding | Loading..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
              <p className="lead">Loading...</p>
              <br />
              <Spinner animation="border" size="sm" />
              <br /><br />
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (notFound)
  {
    return (
      <React.Fragment>
        <Helmet title="Onboarding | Not Found..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">That link doesn't appear to be valid. Please make sure to copy and paste the full URL from your email inbox.</p>
            <p className="lead">If you're still having trouble, please reach out to your practice administrator, or <a href="mailto:support@h3althcare.com">support@h3althcare.com</a> for assistance.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (expired)
  {
    return (
      <React.Fragment>
        <Helmet title="Onboarding | Expired..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">That link has <b>expired</b></p>
            <p className="lead">Please reach out to your practice administrator for assistance.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (error || !data)
  {
    return (
      <React.Fragment>
        <Helmet title="Onboarding | Something Went Wrong..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">Something went wrong; please reload the page...</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (stage === "success")
  {
    return (
      <React.Fragment>
        <Helmet title="Onboarding | Success!" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead"><b>Thank you!</b><br /><br /> We've received your submission. The practice will be in touch soon!</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }

  let title = "Onboarding | " + data.name;
  let onboardingStages = getOnboardingStages(data.preferences);
  return (
    <React.Fragment>
      <Helmet title={title} />
      <div className="text-center mt-4">
        <img
                src={logo}
                alt="H3"
                className="img-fluid"
                width="80"
                height="80"
                style={{marginBottom: "10px"}}
              />
        <br />
        <p className="lead">Welcome, <b>{data.name}</b>!</p>
      </div>

      <Card>
        <Card.Body>
          <>
          <div className="mb-3 float-end">{onboardingStages.indexOf(stage) + 1} of {onboardingStages.length}</div>
          <div className="m-sm-4">
            {stage === "demographics" && <OnboardingDemographics stage={stage} token={token} data={data} />}
            {stage === "peer-references" && <OnboardingPeerReferences stage={stage} token={token} data={data} />}
            {stage === "training" && <OnboardingTraining stage={stage} token={token} data={data} />}
            {stage === "work-history" && <OnboardingWorkHistory stage={stage} token={token} data={data} />}
            {stage === "state-licenses" && <OnboardingStateLicenses stage={stage} token={token} data={data} />}
            {stage === "malpractice-insurance" && <OnboardingMalpracticeInsurance stage={stage} token={token} data={data} />}
            {stage === "board-certifications" && <OnboardingBoardCertifications stage={stage} token={token} data={data} />}
            {stage === "immunizations" && <OnboardingImmunizations stage={stage} token={token} data={data} />}
            {stage === "documents" && <OnboardingDocuments stage={stage} token={token} data={data}  />}
            {stage === "signing" && <OnboardingSigning stage={stage} token={token} data={data}  />}
          </div>
          </>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
  
};

export default OnboardingPage;
