const rawTaskStatuses =[
  {"value":"", "label":""},
  {"value":"CREATED", "label":"Inbox", "shortLabel":"Inbox"},
  {"value":"IN_PROGRESS", "label":"In Progress", "shortLabel":"In Progress"},
  {"value": "BLOCKED", "label":"Waiting (External)", "shortLabel":"Waiting (Ext.)"},
  {"value": "BLOCKED_INTERNALLY", "label":"Waiting (Internal)", "shortLabel":"Waiting (Int.)"},
  {"value": "BLOCKED_SUBMITTED", "label":"Waiting (Submitted)", "shortLabel":"Waiting (Subm.)"},
  {"value": "COMPLETE", "label":"Complete", "shortLabel":"Complete"},
];

export default rawTaskStatuses;