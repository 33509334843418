import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Form} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import { X, Check, Edit2, Trash2} from "react-feather";

import { useTable, useSortBy, useFilters} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import authenticatedFetch from "../utils/fetch"

import EditableExpires from "./EditableExpires";

const WorkHistory = ({ id, providerId, position, organization, startDate, endDate, notes, refreshList, canEdit, onCancelEdit, startInEditMode=false }) => {
  const [editing, setEditing] = useState(startInEditMode);
  const [localPosition, setLocalPosition] = useState(position);
  const [localOrganization, setLocalOrganization] = useState(organization);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localNotes, setLocalNotes] = useState(notes);
  
  const notyf = useContext(NotyfContext);


  function handleDeleteClicked(e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?"))
    {
      authenticatedFetch({
        method: "DELETE",
        path: "workhistory/" + id,
        successHandler: function(result) {
          refreshList();
        },
        errorHandler: function(error) {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  function handleEditClicked(e) {
    e.preventDefault();
    setEditing(true);
  }

  function cancelEdit(e) {
    e.preventDefault();
    setEditing(false);
    setLocalPosition(position);
    setLocalOrganization(organization);
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
    setLocalNotes(notes);

    if (onCancelEdit)
    {
      onCancelEdit();
    }
  }

  function saveEdit(e) {
    e.preventDefault();
    if (localPosition === '' && localStartDate === null && localOrganization === '')
    {
      alert("Please enter position, organization, or start date");
      return;
    }

    setEditing(false);

    if (position !== localPosition || organization !== localOrganization || startDate !== localStartDate || endDate !== localEndDate || notes !== localNotes)
    {
      authenticatedFetch({
        method: id ? "PUT" : "POST",
        requestData: {providerId: parseInt(providerId), position: localPosition,  organization: localOrganization, startDate: localStartDate, endDate: localEndDate, notes: localNotes},
        path: id ? "workhistory/" + id  : "workhistory",
        successHandler: function(result) {
          refreshList();
          notyf.open({type: "success", message: id ? "Updated!" : "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setEditing(true);
          setLocalPosition(position);
          setLocalOrganization(organization);
          setLocalStartDate(startDate);
          setLocalEndDate(endDate);
          setLocalNotes(notes);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  function handlePositionChanged(e) {
    e.preventDefault();
    setLocalPosition(e.target.value);
  }
  
  function handleOrganizationChanged(e) {
    e.preventDefault();
    setLocalOrganization(e.target.value);
  }

  function handleStartDateChanged(val, e) {
    e.preventDefault();
    setLocalStartDate(val);
  }

  function handleEndDateChanged(val, e) {
    e.preventDefault();
    setLocalEndDate(val);
  }

  function handleNotesChanged(e) {
    e.preventDefault();
    setLocalNotes(e.target.value);
  }

  var notesReadOnly = '';
  if (localNotes && localNotes.length > 0) {
    notesReadOnly = localNotes.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
       )
    });
  }

  return (
    <>
    {editing ?
    <td>
      <Form.Control size="sm" autoFocus={true} maxLength={255} value={localPosition} onChange={(e) => {handlePositionChanged(e)}} />
    </td>
    :
    <td>
      {localPosition}
    </td>
    }
    {editing ?
    <td>
      <Form.Control size="sm" maxLength={255} value={localOrganization} onChange={(e) => {handleOrganizationChanged(e)}} />
    </td>
    :
    <td>
      {localOrganization}
    </td>
    }
    <td>
    <EditableExpires warnWithColors={false} localExpires={localStartDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleStartDateChanged} />
    </td>
    <td>
    <EditableExpires warnWithColors={false} localExpires={localEndDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleEndDateChanged} />
    </td>
    {editing ?
    <td>
      <Form.Control style={{height: "120px"}} size="sm" as="textarea" maxLength={511} value={localNotes} onChange={(e) => {handleNotesChanged(e)}} />
    </td>
    :
    <td>
      {notesReadOnly}
    </td>
    }
    <td style={{width: '70px'}}>
      { canEdit?
        <>
        {editing ? 
        <>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </>
        :
        <>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </>
        }
        </>
        : <>&nbsp;</>
      }
    </td>
  </>
  )
}


const WorkHistoriesTable = ({providerId, addingNew, onCancelAddNew, list, isLoaded, error, canEdit, refreshList}) => {
  const data = list;

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        key: "id",
      },
      {
        Header: "Position",
        accessor: "position",
        key: "position",
      },
      {
        Header: "Organization",
        accessor: "organization",
        key: "organization",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        key: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
        key: "endDate",
      },
      {
        Header: "Details",
        accessor: "notes",
        key: "notes",
      },
      {
        Header: "",
        accessor: "icons",
        key: "icons",
      },
    ],
  []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: ['id'],
        sortBy: [
            {
                id: 'startDate',
                desc: true
            },
            {
              id: 'endDate',
              desc: true
            }
        ],
      }
    },
    useFilters,
    useSortBy,
  );

  return (
    <Table className="table-sm" striped bordered hover {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const { key, ...restHeaderProps } = (column.id === "icons" || column.id === "notes") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
            return (
              <th {...restHeaderProps} key={key}>
                {column.render("Header")}
                {(column.id !== "icons" && column.id !== "notes") ? (
                  <>
                  {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                  )}
                  </>
                ) : <></>
                }
              </th>
            )
          }
          )}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {
      error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
        !isLoaded ? (<tr><td colSpan={5}>Loading...</td></tr>) : (
          rows.map((row) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr {...restRowProps} key={key}>
              <WorkHistory providerId={providerId} id={row.values.id} key={row.values.id} position={row.values.position ? row.values.position : ""} organization={row.values.organization ? row.values.organization : ""} startDate={row.values.startDate} endDate={row.values.endDate} notes={row.values.notes ? row.values.notes : ""} refreshList={refreshList} canEdit={canEdit}/>
            </tr>
          );
        })))
      }
      {
      addingNew ?
        <tr><WorkHistory providerId={providerId} key={0} startInEditMode={true} position={""} organization={""} startDate={null} endDate={null} notes={""} onCancelEdit={onCancelAddNew} canEdit={canEdit} refreshList={refreshList}/></tr>
      : <></>
      }
    </tbody>
  </Table>
  )
}

const WorkHistories = ({providerId, canEdit}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [addingNew, setAddingNew] = useState(false);

  useEffect(() => {
    refreshList();
  }, [providerId])

  function refreshList()
  {
    setAddingNew(false);
    authenticatedFetch({
      path: "workhistory/provider/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function() {
        setIsLoaded(true);
        setError(true);
      }
    });
  }

  const handleAddNewClicked = (e) => {
    e.preventDefault();
  
    setAddingNew(true);
  }

  const onCancelAddNew = () => {
    setAddingNew(false);
  }

  var list = [];
  if (isLoaded)
  {
    list = data.map((item, i) => {
      return {key: item.id, id: item.id, position: item.position, organization: item.organization, startDate: item.startDate, endDate: item.endDate, notes: item.notes, updated: item.updated, updatedBy: item.updatedBy}
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-20px'}}>
        <Card.Title>Work History
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <WorkHistoriesTable list={list} addingNew={addingNew} onCancelAddNew={onCancelAddNew} providerId={providerId} error={error} isLoaded={isLoaded} canEdit={canEdit} refreshList={refreshList}/>
        {canEdit?
          <span className="float-end" style={{marginTop: '6px'}}>
            <Button size="sm" variant="primary" className="float-end" onClick={(e) => {handleAddNewClicked(e)}}>+ Add New Work History</Button>
          </span>
          : <></>
          }
      </Card.Body>
    </Card>
  )
}

export default WorkHistories;
