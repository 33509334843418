const { REACT_APP_BACKEND_URL } = process.env;

const authenticatedFetch = ({
    path, 
    requestData,
    additionalHeaders = {},
    method='GET', 
    successHandler = function(){}, 
    errorHandler = function(){}, 
    formData = false,
}) => {  
    var requestMetadata;
    if (method === 'POST' || method === 'PUT')
    {
        if (formData)
        {
            // This is for mixed/file uploads
            requestMetadata = {
                headers: {},
                method: method,
                credentials: 'include',
                body: requestData
            };
        }
        else
        {
            requestMetadata = {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(requestData)
            };
        }
    }
    else
    {
        requestMetadata = {
            method: method,
            credentials: 'include',
            headers: {}
        };
    }

    // Add the additional headers
    for (var key in additionalHeaders)
    {
        requestMetadata.headers[key] = additionalHeaders[key];
    }

    if (sessionStorage.getItem("revenueTeamId") != null && sessionStorage.getItem("revenueTeamId") !== "")
    {
        requestMetadata.headers["revenueTeamId"] = sessionStorage.getItem("revenueTeamId");
    }

    fetch(REACT_APP_BACKEND_URL + path, requestMetadata)
    .then(res => {
        if (res.ok)
        {
            return res.json();
        }

        throw Error(res.status);
    })  
    .then(
      (result) => {
        successHandler(result);
      },
      (error) => {
        if (parseInt(error.message) === 401 || parseInt(error.message) === 412)
        {
            if (parseInt(error.message) === 412 && localStorage.getItem("isH3Admin"))
            {
                if (window.location.pathname != null && window.location.pathname.length > 1)
                {
                    window.location = "/h3admin/revenueteams?destination=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
                }
                else
                {
                    window.location = "/h3admin/revenueteams"
                }
            }
            else
            {
                localStorage.setItem("isAuthenticated", false);
                localStorage.setItem("isH3Admin", false);
                if (window.location.pathname != null && window.location.pathname.length > 1)
                {
                    window.location = "/auth/sign-in?destination=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
                }
                else
                {
                    window.location = "/auth/sign-in";
                }
            }
        }
        else if (parseInt(error.message) === 403 || parseInt(error.message) === 404)
        {
            window.location = "/";
        } 
        else
        {
            errorHandler(error);
        }
      }
    )
  };
  
  export default authenticatedFetch;