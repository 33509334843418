import { useState, useEffect } from "react";

import authenticatedFetch from "./fetch";
import getActivityDateTimeDisplay from "./datetime";


const LastRun = ({ reportType, refresh}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        authenticatedFetch({
            path: "report/lastrun/" + reportType,
                successHandler: function(result) {
                setIsLoaded(true);
                setData(result);
            },
            errorHandler: function(error) {
                setIsLoaded(true);
                setError(error);
            }
        });
    }, [reportType, refresh])

    if (!isLoaded)
    {
        return "Loading..."
    }
    else if (error)
    {
        return "Error... please reload the page..."
    }
    else if (!data || !data.date)
    {
        return "(n/a)"
    }
    else
    {
        return getActivityDateTimeDisplay(data.date);
    }
}

export default LastRun;