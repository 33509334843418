import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Form, Modal, Button, Spinner} from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import Select from "react-select";
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import authenticatedFetch from "../../utils/fetch"
import LastRun from "../../utils/LastRun";
import ReportFormatSelect from "../../utils/ReportFormatSelect";


const PracticeSelect = ({ handlePracticeChanged, selectedPractices}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
      authenticatedFetch({
          path: "practice/revenueteam",
              successHandler: function(result) {
              setIsLoaded(true);
              setData(result);
          },
          errorHandler: function(error) {
              setIsLoaded(true);
              setError(error);
          }
      });
  }, [])

  const options = data.map((practice) => {
      return {value: practice.id, label: practice.name};
  });

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      return (
          <div style={{width: "350px"}}>
              <Select
              options={options}
              onChange={(selectedPractices) => {
                handlePracticeChanged(selectedPractices);
              }}
              value={selectedPractices}
              placeholder="All"
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
              />
          </div>
      )
  }
}


const ProviderRosterModal = ({ show, onClose, runSuccess}) => {

  const [practiceIds, setPracticeIds] = useState([]);
  const [selectedPractices, setSelectedPractices] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [reportFormat, setReportFormat] = useState("CSV");
  const [isExporting, setIsExporting] = useState(false);

  const notyf = useContext(NotyfContext);

  const handlePracticeChanged = (practices, e) => {
      setSelectedPractices(practices);

      let newPracticeIds = []
      for (var i=0; i<practices.length; i++)
      {
        newPracticeIds.push(practices[i].value);
      }

      setPracticeIds(newPracticeIds);
  }

  const handleReportFormatChanged = (value) => {
    setReportFormat(value);
  }

  const handleExportClicked = (e) => {
      e.preventDefault();
      setIsExporting(true);
  
      authenticatedFetch({
        method: "POST",
        path:  "report/providerroster",
        requestData: {
          practiceIds: practiceIds,
          includeInactive: includeInactive,
          reportFormat: reportFormat,
        },
        successHandler: function(result) {
          window.open(result.url);
          setIsExporting(false);

          runSuccess("providerroster")
          onClose();
        },
        errorHandler: function(error) {
          setIsExporting(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Provider Roster</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Practice(s):</td>
                  <td><PracticeSelect handlePracticeChanged={handlePracticeChanged} selectedPractices={selectedPractices}/></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td><Form.Check type="checkbox" inline name="group1" label="Include Inactive Providers" checked={includeInactive} onChange={(e) => {setIncludeInactive(e.target.checked)}} /></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <ReportFormatSelect selectedReportFormat={reportFormat} handleReportFormatChanged={handleReportFormatChanged} />
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ReportList = () => {
    const [showProviderRosterModal, setShowProviderRosterModal] = useState(false);

    const [lastRunRefresh, setLastRunRefresh] = useState(0);

    const { isH3Admin, authorities } = useContext(UserSettingsContext);

    const runSuccess = (e) => {
      setLastRunRefresh(lastRunRefresh + 1);
    }

    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
            <Card.Title>
            Practice Reports
            </Card.Title>
          </Card.Header>
        <Table striped hover>
          <thead>
            <tr>
              <td>Report Type</td>
              <td>Available Formats</td>
              <td>Last Run</td>
            </tr>
          </thead>
          <tbody>
            {(isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowProviderRosterModal(true)}}>
                <td><a href="#">Provider Roster</a></td>
                <td>.csv | .pdf</td>
                <td><LastRun reportType="providerroster" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
          </tbody>
        </Table>
        <ProviderRosterModal show={showProviderRosterModal} onClose={() => setShowProviderRosterModal(false)} runSuccess={runSuccess} />
      </Card>
    )
  };

const PracticeReports = () => {

    return (
      <Row>
        <Helmet title="Practice Reports"/>
        <Col lg="12">
          <ReportList />
        </Col>
      </Row>
    )
  };
  
  export default PracticeReports;
  