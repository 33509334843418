import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Badge, Container, Form, Button, Modal, ProgressBar } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import useSidebar from "../../hooks/useSidebar";
import Spinner from 'react-bootstrap/Spinner';

import InputMask from "react-input-mask";

import authenticatedFetch from "../../utils/fetch"

import NotyfContext from "../../contexts/NotyfContext";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import LastActivityDisplay from "../../utils/LastActivityDisplay";

import SlimPracticePicker from "../../utils/SlimPracticePicker"
import ActivityRow from "../../utils/ActivityRow";
import rawReferralStatuses from "../../utils/referralStatuses";
import rawReferralTypes from "../../utils/referralTypes";
import rawReferralUrgencies from "../../utils/referralUrgencies";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFax
} from "@fortawesome/free-solid-svg-icons";
import { Printer, ExternalLink} from "react-feather";


const Lane = ({ practiceId, name, status, className='', showAddNewReferral=false, children, onContainerLoaded, reloadReferralLists }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  const [show, setShow] = useState(false);

  const handleAddNewReferral = () => setShow(true);
  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadReferralLists();
  }

  return (

      <Card style={{minHeight: '200px'}}>
        <Card.Header>
          <Card.Title className={className}>{name}</Card.Title>
        </Card.Header>
        <Card.Body style={{marginTop: '-25px'}}>
          {showAddNewReferral ? 
          <>
            <div className="d-grid">
              <Button variant="primary" onClick={(e) => handleAddNewReferral()}>+ Referral Quick Add</Button>
            </div> 
            <br />
            <ReferralModal practiceId={practiceId} referral={{}} show={show} onCancel={handleCancel} onSave={handleSave} canEdit={true} />
          </>
          : ''
          }
          <div data-status={status} ref={handleContainerLoaded}>{children}</div>
          
        </Card.Body>
      </Card>
  );
};

const FaxViewModal = ({ show, onCancel, selectedFaxId }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const notyf = useContext(NotyfContext);

  // TODO issue with stale link if no re-render for 15 minutes

  useEffect(() => {
    setIsLoaded(false);
    setData([]);

    if (selectedFaxId)
    {
      authenticatedFetch({
        path: "inbound_fax/details/" + selectedFaxId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
  }, [selectedFaxId]);

  const handleFullPreview = (e) => {
    e.preventDefault();
    if (isLoaded && data)
    {
      window.open(data.signedDownloadUrl, '_blank');
    }
  }

  return (
      <Modal show={show} onHide={onCancel} dialogClassName={"modal-850"}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Inbound Fax | Quick Look {isLoaded && data != null && !error ? "| " + data.filename : ""}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoaded ? <><Spinner animation="border" size="sm"/>&nbsp;&nbsp;Loading... </> : 
          (
            (error || !data) ? <>Something went wrong; please reload the page</> :
            <>
            <iframe src={data.signedDownloadUrl} style={{width: "800px", height: "500px" }} frameBorder="0"></iframe>
            <br /><br />
            </>
            )}
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1', marginTop: "-10px"}}>
            <span className="float-start"><a href="#" onClick={(e) => {handleFullPreview(e)}}><ExternalLink style={{'height': '12px', 'width': '12px'}}/>&nbsp; Open Fax in New Tab</a></span>&nbsp;&nbsp;
            <Button onClick={onCancel}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}

const QuickReplyModal = ({ patientLastName, patientFirstName, referringPractice, show, onCancel}) => {

  const[localReason, setLocalReason] = useState('');

  const notyf = useContext(NotyfContext);

  const handleCancel = () => {
    reset();
    onCancel();
  }
  
  const reset = () => {
    setLocalReason('');
  }

  const handleReasonChanged = (val, e) => {
    setLocalReason(val);
  }

  const handleSendClicked = (e) => {
    e.preventDefault();
    notyf.open({type: "success", message: "Fax sent to " + referringPractice + "!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
    onCancel();
  }

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
        <Modal.Title>
          <FontAwesomeIcon icon={faFax} />&nbsp;Quick Reply | {patientFirstName} {patientLastName} | {referringPractice}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table> 
          <tbody>
          <tr>
              <td>Reason</td>
              <td>
                <Form.Select value={localReason} onChange={(e) => {handleReasonChanged(e.target.value, e)}} value={localReason}>
                  <option>Please select fax template...</option>
                  <option>Patient declined scheduling</option>
                  <option>Third outreach - no response</option>
                  <option>Contact information missing</option>
                  <option>Incorrect contact information</option>
                  <option>Other</option>
                </Form.Select>
              </td>
          </tr>
          <tr>
              <td>Additional notes</td>
              <td><Form.Control as="textarea" /></td>
          </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          <Button onClick={handleCancel}>Close</Button>
          <Button onClick={(e) => {handleSendClicked(e)}}>Send</Button>
      </Modal.Footer>
    </Modal>
);
};

const ReferralModal = ({ practiceId, referral, show, onCancel, onSave, onDelete, canEdit }) => {
  const [localId] = useState(referral.id);
  const [localStatus, setLocalStatus] = useState(referral.status == null ? 'PENDING_OUTREACH' : referral.status);
  const [localUrgency, setLocalUrgency] = useState(referral.urgency == null ? "" : referral.urgency);
  const [localProgress, setLocalProgress] = useState(referral.progress == 0 ? "" : referral.progress);
  const [localLocation, setLocalLocation] = useState(referral.location == null ? "" : referral.location);
  const [localReason, setLocalReason] = useState(referral.reason == null ? "" : referral.reason);
  const [localDx, setLocalDx] = useState(referral.dx == null ? "" : referral.dx);
  const [localPreOp, setLocalPreOp] = useState(referral.preOp == null ? "" : referral.preOp);
  const [localTesting, setLocalTesting] = useState(referral.testing == null ? "" : referral.testing);
  const [localNotes, setLocalNotes] = useState(referral.notes == null ? "" : referral.notes);
  const [localPatientFirstName, setLocalPatientFirstName] = useState(referral.patientFirstName == null ? "" : referral.patientFirstName);
  const [localPatientLastName, setLocalPatientLastName] = useState(referral.patientLastName == null ? "" : referral.patientLastName);
  const [localPatientPhone1, setLocalPatientPhone1] = useState(referral.patientPhone1 == null ? "" : referral.patientPhone1);
  const [localPatientEmail1, setLocalPatientEmail1] = useState(referral.patientEmail1 == null ? "" : referral.patientEmail1);
  const [localPatientAddress1, setLocalPatientAddress1] = useState(referral.patientAddress1 == null ? "" : referral.patientAddress1);
  const [localPatientCity, setLocalPatientCity] = useState(referral.patientCity == null ? "" : referral.patientCity);
  const [localPatientState, setLocalPatientState] = useState(referral.patientState == null ? "" : referral.patientState);
  const [localPatientZip, setLocalPatientZip] = useState(referral.patientZip == null ? "" : referral.patientZip);
  const [localReferringPractice, setLocalReferringPractice] = useState(referral.referringPractice == null ? "" : referral.referringPractice);
  const [localReferringPhysician, setLocalReferringPhysician] = useState(referral.referringPhysician == null ? "" : referral.referringPhysician);

  const [localArchived, setLocalArchived] = useState(referral.archived);

  const [newActivity, setNewActivity] = useState('');

  const notyf = useContext(NotyfContext);

  // On create, may not touch these
  if (localStatus == null) {
    setLocalStatus("PENDING_OUTREACH")
  }
  if (localUrgency == null) {
    setLocalUrgency("")
  }

  const handlePatientPhone1Changed = (newValue, e) => {
    e.preventDefault();

    // Remove any remaining mask characters from the truly numeric one; can't do anything about the phone parens and dash on partially completed phones
    newValue = newValue.replace(/_/g, '');

    setLocalPatientPhone1(newValue);
  }

  // // Have to format the local assignees here to structure the plugin can understand
  // const formattedAssignees = [];
  // if (assignees != null)
  // {
  //   for (var i=0; i<assignees.length; i++)
  //   {
  //     formattedAssignees.push({value: assignees[i].userId, label:assignees[i].firstName + ' ' + assignees[i].lastName});
  //   }
  // }
  // const [localAssignees, setLocalAssignees] = useState(formattedAssignees);

  // const handleAssigneesChanged = (assignees) => {
  //   setLocalAssignees(assignees);
  // }

  const reset = () => {
    // Reset all local fields
    setLocalStatus(referral.status == null ? 'PENDING_OUTREACH' : referral.status);
    setLocalUrgency(referral.urgency == null ? "" : referral.urgency);
    setLocalProgress(referral.progress == null ? 0 : referral.progress);
    setLocalLocation(referral.location == null ? "" : referral.location);
    setLocalReason(referral.reason == null ? "" : referral.reason);
    setLocalDx(referral.dx == null ? "" : referral.dx);
    setLocalPreOp(referral.preOp == null ? "" : referral.preOp);
    setLocalTesting(referral.testing == null ? "" : referral.testing);
    setLocalNotes(referral.notes == null ? "" : referral.notes);
    setLocalPatientFirstName(referral.patientFirstName == null ? "" : referral.patientFirstName);
    setLocalPatientLastName(referral.patientLastName == null ? "" : referral.patientLastName);
    setLocalPatientPhone1(referral.patientPhone1 == null ? "" : referral.patientPhone1);
    setLocalPatientEmail1(referral.patientEmail1 == null ? "" : referral.patientEmail1);
    setLocalPatientAddress1(referral.patientAddress1 == null ? "" : referral.patientAddress1);
    setLocalPatientCity(referral.patientCity == null ? "" : referral.patientCity);
    setLocalPatientState(referral.patientState == null ? "" : referral.patientState);
    setLocalPatientZip(referral.patientZip == null ? "" : referral.patientZip);
    setLocalReferringPractice(referral.referringPractice == null ? "" : referral.referringPractice);
    setLocalReferringPhysician(referral.referringPhysician == null ? "" : referral.referringPhysician);
    setNewActivity('');
  }

  const handleCancel = () => {
    reset();
    onCancel();
  }

  // TEMP
  const options = [{value:"1", label:"Fairbanks Cardiology"}, {value:"2", label:"Hillside Family Medicine"}, {value:"3", label:"Mat-Su Family Medicine"}, {value:"1", label:"The Alaska Hospitalist Group"} ]

  const customStyles = {
    valueContainer: (styles) => ({
        ...styles,
        fontSize: "1.1em",
    })
  }

  const handleDelete = () => {
      if (window.confirm("Are you sure you want to delete this referral and any associated attachments?"))
      {
        authenticatedFetch({
          path: "referral/" + referral.id,
          method: "DELETE",
          successHandler: function(result) {
            onDelete();

            notyf.open({type: "error", message: "Deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
          },
          errorHandler: function(error) {
            alert("Something went wrong; please try again");
          }
        });
      }
  }

  const handleSave = () => {

    if (localStatus === null || localStatus.length === 0)
    {
      alert("Please select a status");
      return;
    }

    if (localUrgency === null || localUrgency.length === 0)
    {
      alert("Please select an urgency");
      return;
    }

    if (localLocation === null || localLocation.length === 0)
    {
      alert("Please select a location");
      return;
    }

    if (localPatientFirstName === null || localPatientFirstName.length === 0 || localPatientLastName === null || localPatientLastName.length === 0)
    {
      alert("Please enter a patient first and last name");
      return;
    }

    if (localProgress != null && (localProgress < 0 || localProgress > 100))
    {
      alert("Please indicate a valid progress value (0-100, or blank)");
      return;
    }

    const requestData = {
      id: localId,
      status: localStatus,
      urgency: localUrgency,
      progress: localProgress,
      location: localLocation,
      reason: localReason,
      dx: localDx,
      preOp: localPreOp,
      testing: localTesting,
      notes: localNotes,
      patientFirstName: localPatientFirstName,
      patientLastName: localPatientLastName,
      patientPhone1: localPatientPhone1,
      patientEmail1: localPatientEmail1,
      patientAddress1: localPatientAddress1,
      patientCity: localPatientCity,
      patientState: localPatientState,
      patientZip: localPatientZip,
      referringPractice: localReferringPractice,
      referringPhysician: localReferringPhysician,
      archived: localArchived,
      newActivity: newActivity,
      // assignees: localAssignees
    };

    authenticatedFetch({
      path: "referral/" + practiceId,
      method: "POST",
      requestData: requestData,
      successHandler: function(result) {
        setNewActivity('');

        var message = "Updated!";
        if (localId == null || localId === 0)
        {
          message = "Created!";
          reset(); // If this is the shared "new" task modal, need to reset everything
        }

        onSave();

        notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function() {
        alert("Something went wrong; please try again");
        setNewActivity('');
      }
    });
  }

  let activityRows = [];
  if (referral.activity) {
    activityRows.push(referral.activity.map((activity, i) => {
      return (
        <ActivityRow key={activity.id} user={activity.userFormattedName} notes={activity.notes} created={activity.created} />
      )
    }, this));
  }

  let statusOptionList = rawReferralStatuses.map((status, i) => {
      return (
        <option value={status.value} key={status.value}>{status.label}</option>
      )
  }, this);

  let urgencyOptionList = rawReferralUrgencies.map((urgency, i) => {
    return (
      <option value={urgency.value} key={urgency.value}>{urgency.label}</option>
    )
  }, this);

  // TODO need a spinner while the save is running

  return (
      <Modal show={show} onHide={handleCancel} dialogClassName={"modal-850"}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            {referral.id > 0 ? "Referral Details | " + referral.patientFirstName + " " + referral.patientLastName + " | " +  referral.referringPractice : "Referral Quick Add"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                <td colSpan={4} style={{border: 'none', padding: '0', margin: '0'}}><Form.Label><b>Referral Status</b></Form.Label></td>
              </tr>
              <tr>
                <td>
                  <Form.Select disabled={!canEdit} onChange={(e) => {setLocalStatus(e.target.value)}} value={localStatus}>
                    {statusOptionList}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select disabled={!canEdit} onChange={(e) => {setLocalUrgency(e.target.value)}} value={localUrgency}>
                    {urgencyOptionList}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select disabled={!canEdit} onChange={(e) => {setLocalLocation(e.target.value)}} value={localLocation}>
                    <option value="">Select a location...</option>
                    <option value="T-Tower">T-Tower</option>
                    <option value="ARH">ARH</option>
                    <option value="Mat-Su">Mat-Su</option>
                    <option value="Soldotna">Soldotna</option>
                    <option value="Homer">Homer</option>
                  </Form.Select>
                </td>
                <td>
                  <Form.Control placeholder="Progress %..." disabled={!canEdit} onChange={(e) => {setLocalProgress(e.target.value)}} type="numeric" value={localProgress} />
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{border: 'none', paddingLeft: '0', paddingBottom: '0', margin: '0'}}><Form.Label><b>Patient Demographics</b></Form.Label></td>
              </tr>
              <tr>
                <td style={{border: 'none'}}><Form.Control placeholder="First Name..."  disabled={!canEdit} onChange={(e) => {setLocalPatientFirstName(e.target.value)}} value={localPatientFirstName} /></td>
                <td style={{border: 'none'}}><Form.Control placeholder="Last Name..."  disabled={!canEdit} onChange={(e) => {setLocalPatientLastName(e.target.value)}} value={localPatientLastName} /></td>
                <td style={{border: 'none'}}>
                  <InputMask
                    mask='(999) 999-9999'
                    value={localPatientPhone1} 
                    onChange={(e) => {handlePatientPhone1Changed(e.target.value, e)}} >

                    {(inputProps) => <Form.Control placeholder="Phone..." {...inputProps} type="text" />}

                  </InputMask>
                </td>
                <td style={{border: 'none'}}><Form.Control placeholder="Email..." disabled={!canEdit} onChange={(e) => {setLocalPatientEmail1(e.target.value)}} value={localPatientEmail1}/></td>
              </tr>
              <tr>
                <td><Form.Control placeholder="Address..." disabled={!canEdit} onChange={(e) => {setLocalPatientAddress1(e.target.value)}} value={localPatientAddress1}/></td>
                <td><Form.Control placeholder="City..."  disabled={!canEdit} onChange={(e) => {setLocalPatientCity(e.target.value)}} value={localPatientCity} /></td>
                <td><Form.Control placeholder="State..." disabled={!canEdit} onChange={(e) => {setLocalPatientState(e.target.value)}} value={localPatientState}/></td>
                <td><Form.Control placeholder="Zip..." disabled={!canEdit} onChange={(e) => {setLocalPatientZip(e.target.value)}} value={localPatientZip}/></td>
              </tr>
              <tr>
                <td colSpan={4} style={{border: 'none', paddingLeft: '0', paddingBottom: '0', margin: '0'}}><Form.Label><b>Referral Source</b></Form.Label></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Form.Select disabled={!canEdit} onChange={(e) => {setLocalReferringPractice(e.target.value)}} value={localReferringPractice}>
                    <option value="">Select referring practice...</option>
                    <option value="Fairbanks Cardiology" >Fairbanks Cardiology</option>
                    <option value="Hillside Family Medicine">Hillside Family Medicine</option>
                    <option value="Mat-Su Family Medicine">Mat-Su Family Medicine</option>
                    <option value="The Alaska Hospitalist Group">The Alaska Hospitalist Group</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </td>
                <td colSpan={2}><Form.Control disabled={!canEdit} value={localReferringPhysician} onChange={(e) => {setLocalReferringPhysician(e.target.value)}}  placeholder="Referring Physician..." /></td>
              </tr>
              <tr>
                <td colSpan={4} style={{border: 'none', paddingLeft: '0', paddingBottom: '0', margin: '0'}}><Form.Label><b>Referral Details</b></Form.Label></td>
              </tr>
              <tr>
                <td style={{border: 'none'}}><Form.Control placeholder="Dx..."  disabled={!canEdit} onChange={(e) => {setLocalDx(e.target.value)}} value={localDx} /></td>
                <td style={{border: 'none'}}><Form.Control placeholder="Pre-op..." disabled={!canEdit} onChange={(e) => {setLocalPreOp(e.target.value)}} value={localPreOp} /></td>
                <td style={{border: 'none'}} colSpan={2}>
                  <Form.Select disabled={!canEdit} onChange={(e) => {setLocalTesting(e.target.value)}} value={localTesting}>
                      <option value="">Testing requested...</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td colSpan={2}><Form.Control placeholder="Reason..." as="textarea"  disabled={!canEdit} onChange={(e) => {setLocalReason(e.target.value)}} value={localReason} /></td>
                <td colSpan={2}><Form.Control as="textarea" placeholder="Notes..." disabled={!canEdit} onChange={(e) => {setLocalNotes(e.target.value)}} value={localNotes}/></td>
              </tr>

              {/* {referral.status === "SCHEDULED" &&
              <tr>
                <td>Archived</td>
                <td><Form.Check disabled={!canEdit} onChange={(e) => {handleArchivedChanged(e.target.checked, e)}}  value={localArchived} /></td>
              </tr>} */}
              <tr>
                <td colSpan={4} style={{border: 'none', paddingLeft: '0', paddingBottom: '0', margin: '0'}}><Form.Label><b>Activity</b></Form.Label></td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div style={{maxHeight: '200px', overflow: 'auto', display: 'block', width: '100%'}}>
                  <Table striped>
                    <tbody>
                      {canEdit ?
                      <tr><td><Form.Control key={0} onChange={(e) => {setNewActivity(e.target.value)}} value={newActivity} as="textarea" placeholder="Add new activity..."/></td></tr>
                      : '' }
                      {activityRows}
                    </tbody>
                  </Table>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          {referral.id != null && referral.id > 0 ? <Button className="me-auto btn-secondary" onClick={handleDelete}>Delete</Button> : ''}
          <Button onClick={handleCancel}>{canEdit ? 'Cancel' : 'Close'}</Button>
          {canEdit ?
          <Button onClick={handleSave}>{referral.id == null || referral.id <= 0 ? "Create Referral" : "Update Referral"}</Button>
          : '' }
        </Modal.Footer>
      </Modal>
  );
}
  
const containers = [];

const Referral = ({practiceId, referral, reloadReferralLists, canEdit}) => {
  const [show, setShow] = useState(false);
  const [showQuickReply, setShowQuickReply] = useState(false);
  const [showFaxView, setShowFaxView] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canSeeFax = isH3Admin || authorities.includes("INBOUND_FAX_VIEW") || authorities.includes("INBOUND_FAX_EDIT");

  const notyf = useContext(NotyfContext);

  const handleShow = () => setShow(true);

  const handleCancel = () => {
    setShow(false);
  }

  const handleCancelQuickReply = () => {
    setShowQuickReply(false);
  }

  const handleCancelFaxView = () => {
    setShowFaxView(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadReferralLists();
  }

  const handleDelete = () => {
    setShow(false);
    reloadReferralLists();
  }

  const handleQuickReplyClick = (e) => {
    e.preventDefault();
    
    if (canEdit && referral.referringPractice != null && referral.referringPractice.length > 0)
    {
      setShowQuickReply(true);
    }
  }

  const handleFaxClick = (e) => {
    e.preventDefault();
    if (!canSeeFax)
    {
      return;
    }

    setShowFaxView(true);

  };


  // TODO
  const assigneeDisplay = "";
  const avatarDisplay = "";

  var reasonForReferralText = referral.reason;
  if (reasonForReferralText && reasonForReferralText.length > 255)
  {
    reasonForReferralText = reasonForReferralText.substring(0, reasonForReferralText-2) + "...";
  }

  if (reasonForReferralText && reasonForReferralText.length > 0) {
    reasonForReferralText = reasonForReferralText.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
        )
    });
  }

  var referralTypeDisplay;
  for (var i = 0; i < rawReferralTypes.length; i++)
  {
    if (rawReferralTypes[i].value === referral.type)
    {
      referralTypeDisplay = rawReferralTypes[i].label;
      break;
    }
  }

  return (
    <Card className="mb-3 bg-light border" id={referral.id}>
      <Card.Header className="p-3 bg-light ">
        <Card.Title className="mb-0">
          {referral.urgency === "URGENT" ?
          <Badge pill bg="warning" className="float-end">Urgent</Badge>
          : <></>
          }
          <span>{referral.patientLastName}, {referral.patientFirstName}<br /></span>
          <div onClick={(e) => handleQuickReplyClick(e)}  style={{color: '#2B7A78', fontSize: '0.9em', marginTop: '3px', marginBottom:'3px', cursor: (canEdit && referral.referringPractice && referral.referringPractice !== "" ? 'pointer' : 'default')}}>{referral.referringPractice && referral.referringPractice !== "" ?<FontAwesomeIcon icon={faFax}/> : <></>} &nbsp;{referral.referringPractice && referral.referringPractice !== "" ? <>{referral.referringPractice}<br /></>: ""}</div>
          <Badge bg="secondary" pill>{referral.location}</Badge>&nbsp;
          <Badge bg="primary" pill>{referralTypeDisplay}</Badge>
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-3" style={{marginTop: '-15px'}}>
        <hr style={{marginTop: '-10px'}} />
        {
          referral.patientPhone1 && referral.patientPhone1.length > 6 ? <div style={{marginBottom: '5px'}}><b>Patient phone:&nbsp;</b>{referral.patientPhone1}</div> : <></>
        }
        {
          reasonForReferralText && reasonForReferralText.length > 0 ? <div><b>Reason for referral:&nbsp;</b>{reasonForReferralText}</div> : <></>
        }
        <br />
        <LastActivityDisplay task={referral} />
        
        { assigneeDisplay.length > 0 ?
        <p><b>Assigned to:</b>&nbsp;{assigneeDisplay.join(", ")}</p>
          : ''
        }
        {/* <div className="float-end mt-n1">
          {avatarDisplay}
        </div> */}
        { referral.inboundFaxId > 0 ?
        <>
        <Printer style={{height: '24px', width: '24px', color: '#3AAFA9', cursor: (canSeeFax ? "pointer": "default")}} onClick={(e) => handleFaxClick(e)}  className="float-end" />
        <FaxViewModal show={showFaxView} onCancel={handleCancelFaxView} selectedFaxId={referral.inboundFaxId} /> 
        </>
        : ''
        }
         <Button onClick={(e) => handleShow()} variant="primary" size="sm">
          {canEdit ? "Open" : "View Details"}
        </Button>
        <br />
        <br />
        <p className="mb-2 fw-bold">
          Progress<span className="float-end">{referral.progress}%</span>
        </p>
        <ProgressBar className="progress-sm" now={referral.progress} />
      </Card.Body>
      <QuickReplyModal patientLastName={referral.patientLastName} patientFirstName={referral.patientFirstName} referringPractice={referral.referringPractice} show={showQuickReply} onCancel={handleCancelQuickReply} /> 
      <ReferralModal practiceId={practiceId} referral={referral} show={show} onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} canEdit={canEdit} />
    </Card>
  )
};

function filterReferralsByStatus(data, status, isFiltered, practiceId, reloadReferralLists, canEdit) {
  return data.filter(function(referral) {
    if (referral.status === status) 
    {
      if (isFiltered(referral))
      {
        return false;
      }

      return true;
    }
    return false;
  }).map((referral) => {
    return (
      <Referral
        practiceId={practiceId}
        key={referral.id}
        referral={referral}
        reloadReferralLists={reloadReferralLists}
        canEdit={canEdit}
      />
    )
  }, this);
}

const Referrals = ({practiceId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { setPendingOutreachBadge } = useSidebar();

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("REFERRAL_SCHEDULING_EDIT");

  const onContainerReady = (container) => {
    containers.push(container);
  };

  useEffect(() => {
    reloadReferralLists();

  }, [practiceId]);

  const reloadReferralLists = () =>
  {
    authenticatedFetch({
      path: "referral/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "referral/pendingoutreach",
      successHandler: function(result) {
        setPendingOutreachBadge(result)
      }
    });
  }

  function isFiltered(referral) 
  {
    // TODO 
    return false;
    // if (!filteredAssignees || filteredAssignees.length === 0)
    // {
    //   return false;
    // }

    // if (!task.assignees || task.assignees.length === 0)
    // {
    //   return true;
    // }

    // for (var i = 0; i < task.assignees.length; i++)
    // {
    //   for (var j=0; j < filteredAssignees.length; j++)
    //   {
    //     if (task.assignees[i].userId === filteredAssignees[j].value)
    //     {
    //       return false;
    //     }
    //   }
    // }

    // return true;
  }

  // TODO

  const referralsPendingOutreach = filterReferralsByStatus(data, "PENDING_OUTREACH", isFiltered, practiceId, reloadReferralLists, canEdit);
  const referralsFirstAttempt = filterReferralsByStatus(data, "FIRST_ATTEMPT", isFiltered, practiceId, reloadReferralLists, canEdit);
  const referralsSecondAttempt= filterReferralsByStatus(data, "SECOND_ATTEMPT", isFiltered, practiceId, reloadReferralLists, canEdit);
  const referralsScheduled = filterReferralsByStatus(data, "SCHEDULED", isFiltered, practiceId, reloadReferralLists, canEdit);

  return (
    <>
      <Row>
        <Col sm="6" md="3">
          <Lane practiceId={practiceId} name="Pending Outreach" status="PENDING_OUTREACH" onContainerLoaded={onContainerReady} reloadReferralLists={reloadReferralLists} showAddNewReferral={canEdit}>
            {referralsPendingOutreach}
          </Lane>
        </Col>
        <Col sm="6" md="3">
          <Lane practiceId={practiceId} name="First Attempt"  status="FIRST_ATTEMPT" onContainerLoaded={onContainerReady}>
            {referralsFirstAttempt}
          </Lane>
        </Col>
        <Col sm="6" md="3">
          <Lane practiceId={practiceId} name="Second Attempt" status="SECOND_ATTEMPT" onContainerLoaded={onContainerReady} className="alert alert-danger">
            {referralsSecondAttempt}
          </Lane>
        </Col>
        <Col sm="6" md="3">
          <Lane practiceId={practiceId} name="Scheduled" status="SCHEDULED" onContainerLoaded={onContainerReady}>
            {referralsScheduled}
          </Lane>
        </Col>
        </Row>
    </>
  )
};

const Scheduling = () => {
  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    authenticatedFetch({
      path: "referral/practices",
      successHandler: function(result) {
        if (result && result.length === 1)
        {
          // Skip the practice picker if there is only one practice
          setPracticeId(result[0].id);
          setPracticeName(result[0].name);
        }
        else if (result)
        {
          for (var i = 0; i < result.length; i++)
          {
            if (result[i].id === 25)
            {
              // Demo company 25, short circuit to this practice for fewer clicks
              setPracticeId(result[i].id);
              setPracticeName(result[i].name);
              break;
            }
          }
        }

        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, []);

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(e.target.value);
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleSelectDifferentPractice = (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null)
  }


  const title = "Scheduling" + (practiceName != null ? " | " + practiceName : "")

  return (
    <React.Fragment>
        <Helmet title={title} />
        <h1 className="h3 mb-3">{title}</h1>
        {practiceId > 0 && data.length > 1 ? 
          <>
            <Button variant="secondary" size="sm" onClick={(e) => {handleSelectDifferentPractice(e)}}>&lt; Select a different practice</Button>&nbsp;&nbsp;&nbsp;
            <br /><br />
          </>
          : <></>}
        <Container fluid className="p-0">
          { practiceId > 0 ? <Referrals practiceId={practiceId} /> : <SlimPracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
        </Container>
    </React.Fragment>
  )
};

export default Scheduling;
