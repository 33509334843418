import React from "react";

import useSettingsState from "../hooks/useSettingsState";

const initialState = {
  isAuthenticated: false,
  isH3Admin: false
};

const UserSettingsContext = React.createContext(initialState);

function UserSettingsProvider({ children }) {
  const [isH3Admin, setIsH3Admin] = useSettingsState("isH3Admin", false);  // Use local storage; state doesn't survive a page refresh
  const [authorities, setAuthorities] = useSettingsState("authorities", []); 
  const [environment, setEnvironment] = useSettingsState("environment", "dev");
  const [isAuthenticated, setIsAuthenticated] = useSettingsState("isAuthenticated", false);
  const [navbarRefresh, setNavbarRefresh] = useSettingsState("navbarRefresh", 0);

  return (
    <UserSettingsContext.Provider
      value={{
        isH3Admin,
        setIsH3Admin,
        authorities,
        setAuthorities,
        environment,
        setEnvironment,
        isAuthenticated,
        setIsAuthenticated,
        navbarRefresh,
        setNavbarRefresh
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
}

export { UserSettingsProvider, UserSettingsContext };
