import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="mailto:support@h3althcare.com">support@h3althcare.com</a>
              </span>
            </li>
            |&nbsp;&nbsp;
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="tel:866-387-4344">(866) 387-4344</a>
              </span>
            </li>
            |&nbsp;&nbsp;
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="https://artifacts.h3althcare.com/privacy.html">Privacy</a>
              </span>
            </li>
            |&nbsp;&nbsp;
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="https://artifacts.h3althcare.com/terms.html">Terms</a>
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            Copyright &copy; {new Date().getFullYear()} -{" "}
            <span href="/" className="text-muted">
              H3 Healthcare Holdings LLC
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
