
import getActivityDateTimeDisplay from './datetime';


const LastActivityDisplay = ({task, shortLastActivity=false}) => {
  const lastActivityTextLength = shortLastActivity ? 80 : 165;
  const lastActivityDateTime = task.activity.length > 0 ? task.activity[0].created : null
  const lastActivityUser = task.activity.length > 0 ? task.activity[0].userFormattedName : null

  var lastActivityText = task.activity.length > 0 ? task.activity[0].notes : null;
  if (lastActivityText && lastActivityText.length > lastActivityTextLength)
  {
    lastActivityText = lastActivityText.substring(0, lastActivityTextLength-2) + "...";
  }

  if (lastActivityText && lastActivityText.length > 0) {
    lastActivityText = lastActivityText.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
        )
    });
  }

  if (lastActivityText == null)
  {
    return <></>
  }

  let lastActivityDateTimeDisplay = getActivityDateTimeDisplay(lastActivityDateTime);

  return (
    <>
      <div style={{fontWeight: "bold", fontSize: "0.9em", marginTop: "-5px"}}>Last update</div>
      <span style={{fontStyle: "italic", fontSize: "0.9em"}}>{lastActivityText}</span><small className="text-muted">{lastActivityDateTimeDisplay} by {lastActivityUser}</small><br /><br />
      
    </>
  )
}

export default LastActivityDisplay;