import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

const { REACT_APP_BACKEND_URL } = process.env;


const SetPassword = ({token, isReset}) => {
  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password must contain at least 8 characters, and include: one uppercase, one lowercase, one number and one special character (e.g. '!' or '*')"
        ),
        passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          const requestMetadata = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(
              {
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                token: token
              })
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "auth/setpassword", requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          {!status || !status.success ? 
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              label="New password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <br />
            <Form.Label>Confirm password</Form.Label>
            <Form.Control
              type="password"
              name="passwordConfirmation"
              label="Confirm new password"
              value={values.passwordConfirmation}
              isInvalid={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {(!!touched.password || !!touched.passwordConfirmation) && (
              <Form.Control.Feedback type="invalid">
                {errors.password || errors.passwordConfirmation}
                
              </Form.Control.Feedback>
            )}
          </Form.Group>
          : ''
          }
          {status && status.success ? 
            <div id="success" className="text-center mt-3">
              <h4>Success! Your new password has been set.</h4>
              <br /><br />
              <h5><a href="/auth/sign-in">Sign in now</a></h5>
            </div>
          :
          <div id="submitButton" className="text-center mt-3">
            <br />
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {isReset ? "Reset" : "Set"} password
            </Button>
          </div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default SetPassword;
