import React, { useState, useEffect } from "react";
import { Table, Button, Modal} from "react-bootstrap";

import authenticatedFetch from "./fetch"

import getActivityDateTimeDisplay from "./datetime"

import CredentialRow from "./CredentialRow";

const LoginCredentialsModal = ({ show, onClose, entityId, entityType, entityName, carrierName, carrierId, canEdit, isHub=false, hubType}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [mostRecentlyCopied, setMostRecentlyCopied] = useState(null);
  
    useEffect(() => {
        authenticatedFetch({
          path: isHub ? entityType + "/portalcredentials/" + hubType + "/" + entityId + "/" + carrierId : entityType + "/portalcredentials/" + entityId + "/" + carrierId,
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
    }, [entityType, entityId, carrierId, isHub])
  
    const onCopyValue = (name) => {
      setMostRecentlyCopied(name);
    }
    
    const onNewValue = (name, value) => {
      data[name] = value;
      setData(data);
  
      authenticatedFetch({
        path: isHub ? entityType + "/portalcredentials/" + hubType + "/" + entityId + "/" + carrierId : entityType + "/portalcredentials/" + entityId + "/" + carrierId,
        method: "POST",
        requestData: data,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result); // Update our state with the ID, if it's brand new
        },
        errorHandler: function() {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
  
    if (error) {
      return (
      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          Something went wrong; please reload the page...
        </Modal.Body></Modal>
      );
    }
    if (!isLoaded) {
      return (
        <Modal show={show} onHide={onClose}>
          <Modal.Body>
            Loading...
          </Modal.Body></Modal>
        );
    }
    return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Portal Login Credentials for {entityName}</span><br />{carrierName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table> 
              <tbody>
                <CredentialRow canEdit={canEdit} label="Username" data={data} name="username" onNewValue={onNewValue} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} />
                <CredentialRow canEdit={canEdit} label="Password" data={data} name="password" onNewValue={onNewValue} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} obfuscate={true} />
                <CredentialRow canEdit={canEdit} label="Two-factor auth device" data={data} name="mfa1" onNewValue={onNewValue} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} copyable={false} />
                <CredentialRow canEdit={canEdit} label="Security questions" data={data} name="securityQuestions" onNewValue={onNewValue} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} copyable={false} inputType="textarea" />
                <CredentialRow canEdit={canEdit} label="Notes" data={data} name="notes" onNewValue={onNewValue} copyable={false} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} inputType="textarea" />
              </tbody>
            </Table>
            {data.updated && data.updatedBy ?
              <span className="float-end" style={{fontSize: "0.9em", fontStyle: "italic", marginRight: "5px"}}>Updated: {getActivityDateTimeDisplay(data.updated)} by {data.updatedBy}</span>
            : <></>
            }
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button onClick={onClose}>Close</Button>
          </Modal.Footer>
        </Modal>
    );
  }


export default LoginCredentialsModal;
