import React from "react";
import { Form } from "react-bootstrap";

const TableSelectColumnFilter = ({filterValue, setFilter, rawCategories, showNoCategory=true}) => {
    const options = React.useMemo(() => {
        const options = new Set();
        rawCategories.forEach((rawCategory) => {
          if (rawCategory.value !== "")
          {
            options.add(rawCategory);
          }
        });
        return [...options.values()];
    }, []);

    return (
        <Form.Select size="sm" style={{width: 'auto'}}
            value={filterValue === "" ? "" : filterValue || "all"}
            onChange={(e) => {
            e.stopPropagation();
            if (e.target.value === "all")
            {
                // This will just short circuit and show all - our custom filter function will NOT be called
                setFilter(undefined);
            }
            else
            {
                setFilter(e.target.value);
            }
            }}
            onClick={(e) => {
                // Allow column to also be sorted... don't trigger sort click when changing the filter
                e.stopPropagation();
            }}
            className="mt-2"
        >
            <option value="all">All</option>
            {options.map((option, i) => (
            <option key={i} value={option.value}>
                {option.label}
            </option>
            ))}
            {showNoCategory && <option value="">(no category)</option>}
        </Form.Select>
    );
}

export default TableSelectColumnFilter;
