import React, { useState, useEffect, useContext } from "react";
import { Edit2, Trash2, Download, X, Check } from "react-feather";
import Spinner from 'react-bootstrap/Spinner';
import { Table, Form, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

import NotyfContext from "../contexts/NotyfContext";
import { useTable, useFilters, useSortBy } from "react-table";
import { UserSettingsContext } from "../contexts/UserSettingsContext";

import getRawDocumentCategories from "./documentCategories";
import TableSelectColumnFilter from "./TableSelectColumnFilter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";


import authenticatedFetch from "./fetch"
import EditableExpires from "./EditableExpires";


// "entityType" == provider / practice / revenueteam
const Document = ({ allFilenames, filename, modified, effectiveDate, expires, size, category, reminderSent, notes, entityId, entityType, refreshFileList, canEdit, isH3AdminFile }) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [localFilename, setLocalFilename] = useState(filename);
  const [localCategory, setLocalCategory] = useState(category);
  const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);
  const [localExpires, setLocalExpires] = useState(expires);
  const [localNotes, setLocalNotes] = useState(notes);

  const notyf = useContext(NotyfContext);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  let rawDocumentCategories = getRawDocumentCategories(isH3Admin, authorities, isH3AdminFile);

  if (localCategory == null)
  {
    setLocalCategory('');
  }

  if (localNotes == null)
  {
    setLocalNotes('');
  }

  function handleClick(e) {
    e.preventDefault();

    if (saving)
    {
      return;
    }

    authenticatedFetch({
      method: "POST",
      requestData: {filename: filename},
      path: entityType + "/" + (isH3AdminFile ? "h3admin/" : "") + entityId + "/file/signeddownloadurl",
      successHandler: function(result) {
        window.open(result.url);
      },
      errorHandler: function(error) {
        alert("Something went wrong; please try again.")
      }
    });
  }

  function handleDeleteClicked(e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete: " + filename + "?"))
    {
      authenticatedFetch({
        method: "POST",
        requestData: {filename: filename},
        path: entityType + "/" + (isH3AdminFile ? "h3admin/" : "") + entityId + "/file/delete",
        successHandler: function(result) {
          refreshFileList();
        },
        errorHandler: function(error) {
          alert("Something went wrong; please try again.")
        }
      });
    }
  }

  function handleEditClicked(e) {
    e.preventDefault();
    setEditing(true);
  }

  function cancelEdit(e) {
    e.preventDefault();
    setEditing(false);
    setLocalFilename(filename);
    setLocalCategory(category);
    setLocalEffectiveDate(effectiveDate);
    setLocalExpires(expires);
    setLocalNotes(notes);
  }

  function saveEdit(e) {
    e.preventDefault();

    if (localFilename === '')
    {
      alert("Filename can't be blank!");
      return;
    }

    // Check for existing filename
    if (allFilenames.includes(localFilename) && localFilename !== filename)
    {
      alert("Filename already exists; please choose a different name!");
      return;
    }

    setEditing(false);

    if (filename !== localFilename || category !== localCategory || effectiveDate != localEffectiveDate || expires !== localExpires || localNotes !== notes)
    {
      setSaving(true);
      authenticatedFetch({
        method: "PUT",
        requestData: {fromFilename: filename, toFilename: localFilename, category: localCategory, effectiveDate:localEffectiveDate, expiration: localExpires, notes: localNotes},
        path:  entityType + "/" + (isH3AdminFile ? "h3admin/" : "") + entityId + "/file",
        successHandler: function() {
          setSaving(false);
          refreshFileList();
          notyf.open({type: "success", message: "Updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setSaving(false);
          setLocalFilename(filename);
          setLocalCategory(category);
          setLocalEffectiveDate(effectiveDate);
          setLocalExpires(expires);
          setLocalNotes(notes);
          alert("Something went wrong; please try again.");
        }
      });
    }
  }

  const handleEffectiveDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalEffectiveDate(date);
  }

  const handleExpiresChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalExpires(date);
  }

  function handleFilenameChanged(e) {
    e.preventDefault();
    setLocalFilename(e.target.value);
  }

  function handleCategoryChanged(e) {
    e.preventDefault();
    setLocalCategory(e.target.value);
  }

  function handleNotesChanged(e) {
    e.preventDefault();
    setLocalNotes(e.target.value);
  }

  function formatSize(sizeInBytes)
  {
    if (sizeInBytes < 1000)
    {
      return sizeInBytes + "B";
    }
    else if (sizeInBytes > 1000 && sizeInBytes < 1000000)
    {
      return parseInt(sizeInBytes / 1000) + "KB";
    }
    return parseInt(sizeInBytes / 1000 / 1000) + "MB";
  }
  
  var selectedCategoryLabel = '';
  if (localCategory !== '')
  {
    for (let i = 0; i < rawDocumentCategories.length; i++)
    {
      if (localCategory === rawDocumentCategories[i].value)
      {
        selectedCategoryLabel = rawDocumentCategories[i].label;
        break;
      }
    }
  }

  let categoryList = rawDocumentCategories.map((category, i) => {
    return (
      <option key={i} value={category.value}>{category.label}</option>
    )
  }, this);

  var filenameDisplay = localFilename
  if (filenameDisplay && filenameDisplay.length > 100)
  {
    filenameDisplay = filenameDisplay.substring(0, 98) + "...";
  }
  
  var reminderSentDisplay = '';
  if (reminderSent != null)
  {
    var parts = reminderSent.split("-");
    const reminderSentDateObj = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
    reminderSentDisplay = new Date(reminderSentDateObj).toLocaleDateString("en-US");
  }

  var notesReadOnly = '';
  if (localNotes && localNotes.length > 0) {
    notesReadOnly = localNotes.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
       )
    });
  }

  return (
    <>
    {editing ?
    <td>
      <Form.Control size="sm" autoFocus={true} value={localFilename} onChange={(e) => {handleFilenameChanged(e)}} />
    </td>
    :
    <td onClick={(e) => handleClick(e)} style={{cursor: saving ? "" : "pointer", maxWidth: "325px"}}>
      <Download style={{'color':'#3AAFA9', 'height': '12px', 'width': '12px'}}/>&nbsp;
      <OverlayTrigger
      triggers="[hover,focus]"
      placement="bottom"
      overlay={<Tooltip >{localFilename}</Tooltip>}
      >
        <span>{filenameDisplay}</span>
      </OverlayTrigger>
    </td>
    }
    <td style={{fontFamily: "monospace"}}>{formatSize(size)}</td>
    <td>
      {editing?
        <Form.Select size="sm" value={localCategory} onChange={(e) => {handleCategoryChanged(e)}} style={{width: "auto"}} >
          {categoryList}
        </Form.Select>
      :
      <Badge pill bg="secondary">{selectedCategoryLabel}</Badge>
      }
    </td>
    {/* <td>{new Date(Date.parse(modified)).toLocaleString('en-US', {timeStyle: "short", dateStyle: "short", hour12: false})}</td> */}
    <td><EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleEffectiveDateChanged} blankText="" /></td>
    <td><EditableExpires localExpires={localExpires} localActive={true} editingExpires={editing} handleExpiresChanged={handleExpiresChanged} blankText="" /></td>
    {/* {!isH3AdminFile && <td>{reminderSentDisplay}</td> } */}
    <td style={{minWidth: editing ? "190px" : ""}}>
      {editing?
      <Form.Control as="textarea" rows={3} value={localNotes} onChange={(e) => {handleNotesChanged(e)}} />
      :
      notesReadOnly
      }
    </td>
    <td style={{minWidth: '50px'}}>
      { canEdit?
        <>
        {editing ? 
        <>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </>
        :
        <>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </>
        }
        </>
        : <>&nbsp;</>
      }
    </td>
  </>
  )
}

function customCategoryFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
      if (filterValue === "" && 
      (row.original.category == null || row.original.category === ""))
      {
      // Comes from server as null right now if not set, but handle blank string to be safe
      return true;
      }
  
      return filterValue === row.original.category;
  });
  }
  

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Form.Control 
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} document(s)...`}
      className="mt-2"
    />
  );
}

function ExpiresColumnCheckboxFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <>
    <Form.Check 
      size="sm"
      className="mt-2"
      style={{display: "inline"}}
      value={ filterValue || "excludeGreaterThan30Days"}
      onChange={(e) => {
        setFilter(e.target.checked ? "includeAll" : "excludeGreaterThan30Days");
      }}
      />&nbsp;
      <Form.Label style={{display: "inline", fontSize: "0.9em"}}>Show outdated documents (expired &gt; 30 days ago)</Form.Label> 
    </>
  );
}

function customExpiresFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
    if (filterValue === "excludeGreaterThan30Days")
    {
      if (row.original.expires)
      {
        const components = row.original.expires.split("-");
        if (components.length === 3)
        {
          const expirationDate = new Date(components[0], components[1] - 1, components[2]);
          const now = new Date();
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          const oneDay = 24 * 60 * 60 * 1000;
          const daysAgo = (now - expirationDate) / oneDay;
          return daysAgo <= 30;
        }
      }
    }

    return true;
  });
}


const SortableDocumentTable = ({ entityId, entityType, refreshFileList, handleFilesAdded, uploading, isLoaded, error, documentList, canEdit, isH3AdminFile }) => {
  const data = documentList; 

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const { isH3Admin, authorities } = useContext(UserSettingsContext);
      let rawDocumentCategories = getRawDocumentCategories(isH3Admin, authorities, isH3AdminFile);
      
      return (
        <TableSelectColumnFilter filterValue={filterValue} setFilter={setFilter} rawCategories={rawDocumentCategories} />
      );
  }

  const allFilenames = React.useMemo(
    () => {
      return data.map((row) => row.filename);
    },
    [data]
  );

  const columns = React.useMemo(
    () => {
      var headers = [
      {
        Header: "Name",
        accessor: "filename",
        key: "filename",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Size",
        accessor: "size",
        key: "size",
      },
      {
        Header: "Category",
        accessor: "category",
        key: "category",
        Filter: SelectColumnFilter,
        filter: customCategoryFilter,
      },
      {
        Header: "Effective Date",
        accessor: "effectiveDate",
        key: "effectiveDate",
      },
      {
        Header: "Expires",
        accessor: "expires",
        key: "expires",
        Filter: ExpiresColumnCheckboxFilter,
        filter: customExpiresFilter
      }];

      // if (!isH3AdminFile)
      // {
      //   headers.push(
      //     {
      //       Header: "Reminder Sent",
      //       accessor: "reminderSent",
      //       key: "reminderSent",
      //     }
      //   );
      // }

      headers.push
      (
        {
          Header: "Notes",
          accessor: "notes",
          key: "notes",
        }
      );
      headers.push
      (
        {
          Header: "",
          accessor: "icons",
          key: "icons",
        }
      );

      return headers;
    },
    [isH3AdminFile]
  )
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetSortBy: false,
        autoResetFilters: false,
        initialState: {
          sortBy: [
              {
                id: 'category'
              },
              {
                  id: 'expires',
                  desc: true
              }
          ],
          filters: [
            {
                id: 'expires',
                value: 'excludeGreaterThan30Days',
            },
        ],
      }
      },
      useFilters,
      useSortBy,
    );

  // Extract the expires filter
  var expiresFilter = "";
  for (var i=0; i < headerGroups.length; i++)
  {
    for (var j=0; j < headerGroups[i].headers.length; j++)
    {
      var column = headerGroups[i].headers[j];
      if (column.id === "expires")
      {
        expiresFilter = column.render("Filter");
        break;
      }
    }
  }

  return (
    <>
      <Table striped bordered hover className="table-sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr {...restHeaderProps} key={key}>
                {headerGroup.headers.map((column) => {
                    const { key, ...restHeaderProps } = (column.id === "size" || column.id === "modified" ||  column.id === "effectiveDate" || column.id === "expires" || column.id === "reminderSent" || column.id === "category") ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps();
                    return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                    {(column.id === "size" || column.id === "modified" || column.id === "effectiveDate" || column.id === "expires" || column.id === "reminderSent" || column.id === "category") ? (
                      <>
                        {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                          {
                            (column.id === "category") && <>{column.render("Filter")}</>
                          }

                      </>
                      ) : (column.id === "filename" ? (
                          <>{column.render("Filter")}</>
                        ) : (
                        <></>
                      )
                    )}
                    </th>
                  );
                }
                )}
              </tr>
            );
            }
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          error ? (<tr><td colSpan={7}>Something went wrong; please reload the page...</td></tr>) : ( 
            !isLoaded ? (<tr><td colSpan={7}>Loading...</td></tr>) : (
            rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <tr {...restRowProps} key={key}>
                  <Document isH3AdminFile={isH3AdminFile} allFilenames={allFilenames} key={row.values.filename} filename={row.values.filename} modified={row.values.modified} effectiveDate={row.values.effectiveDate} expires={row.values.expires} size={row.values.size} category={row.values.category} reminderSent={row.values.reminderSent} notes={row.values.notes} entityId={entityId} entityType={entityType} refreshFileList={refreshFileList} canEdit={canEdit}/>
                </tr>
              );
            })))
          }
          {
            !uploading && !error && isLoaded && canEdit ? (
            <tr>
              <td colSpan={7}><Form.Control type="file" size="sm" multiple onChange={(e) => handleFilesAdded(e)} /></td>
            </tr>)
            : (
              uploading ? (
              <tr>
                <td colSpan={7} style={{textAlign:"center"}}><Spinner animation="border" size="sm" /></td>
              </tr>) : (
                <></>)
            )}
        </tbody>
      </Table>
      {expiresFilter}
    </>
    );
}


const DocumentList = ({ entityId, entityType, canEdit, isH3AdminFile=false }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [uploading, setUploading] = useState(false);
  
    useEffect(() => {
      refreshFileList();
    }, [entityId, entityType])
  
    function refreshFileList()
    {
      // Reload the file list
      authenticatedFetch({
        path: entityType + "/" + (isH3AdminFile ? "h3admin/" : "") + entityId + "/files",
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }

    function handleFilesAdded(e) {
      e.preventDefault();
      setUploading(true);
  
      if (e.target.files && e.target.files.length > 0)
      {
        var proceed = true;
        if (data && data.length > 0)
        {
          for (var j=0; j<e.target.files.length; j++)
          {
            for (var k=0; k < data.length; k++)
            {
              if (e.target.files[j].name === data[k].filename)
              {
                if (!window.confirm("Are you sure you want to overwrite the existing file: " + data[k].filename + "?"))
                {
                  proceed = false;
                  break;
                }
              }
            }
          }
        }
  
        if (!proceed)
        {
          setUploading(false);
          e.target.value = null;
          return;
        }
  
        for (var i=0; i<e.target.files.length; i++)
        {
          const name = e.target.files[i].name;
          const contentType = e.target.files[i].type;
          const file = e.target.files[i];
          authenticatedFetch({
            method: "POST",
            requestData: {filename: name, contentType: contentType},
            path: entityType + "/" + (isH3AdminFile ? "h3admin/" : "") + entityId + "/file/signeduploadurl",
            successHandler: function(result) {
              // Got a signed S3 url, PUT to it
              var requestMetadata = {
                method: 'PUT',
                headers: {
                    'Content-Type': contentType
                },
                body: file
              };
              
              fetch(result.url, requestMetadata).then(res => {
                setUploading(false);
                if (res.ok)
                {
                  refreshFileList();
                }
                else
                {
                  alert("Something went wrong; please try again.");
                }
              }).catch((error) => {
                setUploading(false);
                alert("Something went wrong; please try again.");
              });
            },
            errorHandler: function(error) {
              alert("Something went wrong; please try again.");
              setUploading(false);
            }
          });
        }
      }
      else
      {
        setUploading(false);
      }
    }
  
    var documentList = [];
    if (isLoaded)
    {
      documentList = data.map((file, i) => {
        return {key: file.filename, filename: file.filename, modified: file.modified, effectiveDate: file.effectiveDate, expires: file.expiration, size: file.size, category: file.category, reminderSent: file.secondEmailReminderSent != null ? file.secondEmailReminderSent : file.firstEmailReminderSent, notes: file.notes}
      }, this);
    }
  
    return (
      <SortableDocumentTable isH3AdminFile={isH3AdminFile} entityId={entityId} entityType={entityType} refreshFileList={refreshFileList} handleFilesAdded={handleFilesAdded} uploading={uploading} isLoaded={isLoaded} error={error} documentList={documentList} canEdit={canEdit} />
      );
  }

  export default DocumentList;
