import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Flag } from "react-feather";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;

const FppeProviderPatientInformation = ({token, data}) => {
  var initialValues = {patients:[]}
  // This is all ephemeral for now, so initial values will always be empty (unlike e.g. onboarding)

  if (data)
  {
    for (var i=initialValues.patients.length; i < data.patientCount; i++)
    {
      initialValues.patients.push({mrn: "", facility: "", admitDate: "", dos: "", dischargeDate: "", procedure: ""});
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        patients: Yup.array().of(
          Yup.object().shape({
            mrn: Yup.string().max(255).required("MRN is required"),
            facility: Yup.string(),
            admitDate: Yup.date().required("Admit Date is required"),
            dos: Yup.date(),
            dischargeDate: Yup.date(),
            procedure: Yup.string().max(2047).required("Procedure is required")
          })
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try {
          var formData = new FormData();
          formData.append("data", new Blob([JSON.stringify(
            {
              patients: values.patients
            })], {
              type: "application/json"
          }));

          // No documents right now

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "fppe/provider/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            window.location.reload();
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>FPPE with {data.facilityName}
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please provide the following information for <b>{data.patientCount}</b> patient{data.patientCount > 1 ? "s" : ""}</span>
            </Form.Label><br />
            {values.patients.map((patient, index) => {
              
              return (
                <div key={index}>
                <i><b>Patient #{index +1}</b></i>
                
                <br />
                <Form.Control
                  placeholder="MRN*"
                  {...getFieldProps(`patients[${index}].mrn`)}
                  isInvalid={Boolean(touched.patients?.[index]?.mrn && errors.patients?.[index]?.mrn)}
                />
                {!!touched.patients?.[index]?.mrn && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.mrn}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder="Procedure*"
                  {...getFieldProps(`patients[${index}].procedure`)}
                  isInvalid={Boolean(touched.patients?.[index]?.procedure && errors.patients?.[index]?.procedure)}
                />
                {!!touched.patients?.[index]?.procedure && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.procedure}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Control
                  placeholder={"Facility Name (if not " + data.facilityName + ")"}
                  {...getFieldProps(`patients[${index}].facility`)}
                  isInvalid={Boolean(touched.patients?.[index]?.facility && errors.patients?.[index]?.facility)}
                />
                {!!touched.patients?.[index]?.facility && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.facility}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Admit Date*</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`patients[${index}].admitDate`)} 
                  isInvalid={Boolean(touched.patients?.[index]?.admitDate && errors.patients?.[index]?.admitDate)}
                />
                {!!touched.patients?.[index]?.admitDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.admitDate}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Date of Service</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`patients[${index}].dos`)} 
                  isInvalid={Boolean(touched.patients?.[index]?.dos && errors.patients?.[index]?.dos)}
                />
                {!!touched.patients?.[index]?.dos && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.dos}
                  </Form.Control.Feedback>
                )}
                <br />
                <Form.Label style={{fontSize: "0.9em"}}>Discharge Date</Form.Label>
                <Form.Control
                  type="date"
                  {...getFieldProps(`patients[${index}].dischargeDate`)} 
                  isInvalid={Boolean(touched.patients?.[index]?.dischargeDate && errors.patients?.[index]?.dischargeDate)}
                />
                {!!touched.patients?.[index]?.dischargeDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.patients?.[index]?.dischargeDate}
                  </Form.Control.Feedback>
                )}
                <br />
                </div>
            );
            })}
          </Form.Group>
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
            <div id="submitButton" className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </>
          }
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default FppeProviderPatientInformation;
