import React, { useState, useEffect } from "react";
import {  Form } from "react-bootstrap";
import Select from "react-select";

import authenticatedFetch from "../utils/fetch"


const TaskPracticeSelect = ({ disabled, handlePracticesChanged, selectedPractices}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {
        authenticatedFetch({
        path: "task/availablepractices",
        successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
        },
        errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
        }
        });
    }, [])

    if (!isLoaded)
    {
        return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
        return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = [];
        for (var i=0; i<data.length; i++)
        {
            options.push({value: data[i].id, label:data[i].name});
        }

        return (
        <Select
            options={options}
            isDisabled={disabled} 
            onChange={(e) => {handlePracticesChanged(e)}}
            value={selectedPractices}
            placeholder="Filter by practice(s)..."
            isMulti
            theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
        />
        )
    }
}

export default TaskPracticeSelect;