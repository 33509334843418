import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import FppeReviewerSigning from "../../components/fppe/FppeReviewerSigning";

import logo from "../../assets/img/logo_dark.png";

const { REACT_APP_BACKEND_URL } = process.env;

const FppeReviewerPage = () => {
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let { token } = useParams();

  useEffect(() => {
    if (token)
    {
      fetch(REACT_APP_BACKEND_URL + "fppe/reviewer/" + token, {credentials: "include"})
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          if (parseInt(error.message) === 400 || parseInt(error.message) === 404)
          {
            setNotFound(true);
          }
          else if (parseInt(error.message) === 422)
          {
            setExpired(true);
          }
          
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(true);
      setNotFound(true);
    }
  }, [token])

  if (!isLoaded)
  {
    return (
      <React.Fragment>
        <Helmet title="FPPE | Loading..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
              <p className="lead">Loading...</p>
              <br />
              <Spinner animation="border" size="sm" />
              <br /><br />
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (notFound)
  {
    return (
      <React.Fragment>
        <Helmet title="FPPE | Not Found..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">That link doesn't appear to be valid. Please make sure to copy and paste the full URL from your email inbox.</p>
            <p className="lead">If you're still having trouble, please reach out to the practice administrator, or <a href="mailto:support@h3althcare.com">support@h3althcare.com</a> for assistance.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (expired)
  {
    return (
      <React.Fragment>
        <Helmet title="FPPE | Expired..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">That link has <b>expired</b></p>
            <p className="lead">Please reach out to the practice administrator for assistance.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (error || !data)
  {
    return (
      <React.Fragment>
        <Helmet title="FPPE | Something Went Wrong..." />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead">Something went wrong; please reload the page...</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }
  else if (data["isComplete"] === true)
  {
    return (
      <React.Fragment>
        <Helmet title="FPPE | Success!" />
        <div className="text-center mt-4">
          <br />
          <img
                  src={logo}
                  alt="H3"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
          <br /><br />
          <Card>
            <Card.Body>
            <p className="lead"><b>Thank you!</b><br /><br />The review process has been completed.</p>
            <p className="lead">Please reach out to the practice with any questions.</p>
            </Card.Body>
          </Card>
          <br />
        </div>
      </React.Fragment>
    );
  }

  let title = "FPPE | " + data.name;
  return (
    <React.Fragment>
      <Helmet title={title} />
      <div className="text-center mt-4">
        <img
                src={logo}
                alt="H3"
                className="img-fluid"
                width="80"
                height="80"
                style={{marginBottom: "10px"}}
              />
        <br />
        <p className="lead">Hi, <b>{data.name}</b>!</p>
      </div>

      <Card>
        <Card.Body>
          <>
          <div className="mb-3 float-end"></div>
          <div className="m-sm-4">
            <FppeReviewerSigning token={token} data={data}  />
          </div>
          </>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
  
};

export default FppeReviewerPage;
