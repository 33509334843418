import React, { useState, useEffect } from "react";
import { Card, Table, Form} from "react-bootstrap";

import authenticatedFetch from "./fetch"

import FacilityParticipation from "./FacilityParticipation";


const FacilityParticipationStatusTable = ({entityType, entityId, entityName, canEdit}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [showInactive, setShowInactive] = useState(false);

    useEffect(() => {
      reload();
    }, [entityType, entityId, entityName])
  
    function reload() {
      authenticatedFetch({
        path: entityType + "/participationstatus/facility/" + entityId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
  
    var participationList;
  
    if (error)
    {
      participationList = <tr><td colSpan="5">Something went wrong... please reload the page...</td></tr>;
    }
    else if (!isLoaded)
    {
      participationList = <tr><td colSpan="5">Loading...</td></tr>;
    }
    else
    {
      participationList = data.hubs && data.hubs.length > 0
        && data.hubs.map((participation, i) => {
        return (
          <FacilityParticipation key={participation.id} onSave={reload} showInactive={showInactive} facilityId={participation.id} entityType={entityType} entityId={entityId} entityName={entityName} facilityName={participation.name} portal={participation.portalUrl} phone={participation.phone} email={participation.email} hours={participation.hours} facilityType={participation.facilityType} address1={participation.address1} address2={participation.address2} city={participation.city} state={participation.state} zip={participation.zip} fax={participation.fax} effectiveDate={participation.effectiveDate} expires={participation.expires} privilegesType={participation.privilegesType} active={participation.isActive} primary={participation.isPrimary} pending={participation.isPending} canEdit={canEdit} />
        )
      }, this);
    }
    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
          <Card.Title>
            <div className="row">
              <div className="col-sm-12">
              Locations
              {showInactive ? 
              <Form.Label onClick={() => {setShowInactive(false)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "5px"}} className="float-end">
                - Hide Inactive
              </Form.Label>
              :
              <Form.Label onClick={() => {setShowInactive(true)}} style={{cursor: "pointer", display: "inline", fontSize: "0.8em", fontWeight: "normal", color: '#2B7A78', marginTop: "7px", marginRight: "5px"}} className="float-end">
                + Show Inactive
              </Form.Label>
              }
              </div>
              
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>&nbsp;</td>
              <td>Name</td>
              <td>Contact Info</td>
              <td>Active</td>
              <td>Primary</td>
              <td>Effective Date</td>
              <td>Next Renewal</td>
              <td>Privileges Type</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {participationList}   
          </tbody>
        </Table>
        </Card.Body>
      </Card>
    );
  };

export default FacilityParticipationStatusTable;