import { OverlayTrigger, Popover } from "react-bootstrap";

import ReadOnlyAddress from "./ReadOnlyAddress";

const ContactInfoPopover = ({ name, phone, email, fax, address1, address2, city, state, zip }) => {
    return (
      <>
      <OverlayTrigger triggers="[hover,focus]" placement="left" overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" style={{background: '#DEF2F1'}}>Contact info for: <b>{name}</b></Popover.Header>
          <Popover.Body>
            <ul style={{marginLeft: '-20px'}}>
              <li><b>Phone:</b> {phone}</li>
              <li><b>Fax:</b> {fax}</li>
              <li><b>Email:</b> {email}</li>
              {address1 && <li><b>Address: </b><ReadOnlyAddress address1={address1} address2={address2} city={city} state={state} zip={zip} /></li>}
            </ul>
          </Popover.Body>
        </Popover>
      }>
        <span>{name}</span></OverlayTrigger>
      </>
    );
  };

export default ContactInfoPopover;