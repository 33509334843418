import React, { useState } from "react";
import Select from "react-select";
import rawMaritalStatuses from "./maritalStatuses";

const MaritalStatusSelect = ({originalValue, name, onValueChanged }) => {
  const formattedLocalValue = []
  if (originalValue != null && originalValue !== "")
  {
    for (var i = 0 ; i < rawMaritalStatuses.length; i++)
    {
      if (rawMaritalStatuses[i].value === originalValue)
      {
        formattedLocalValue.push({ value: rawMaritalStatuses[i].value, label: rawMaritalStatuses[i].label });
        break;
      }
    }
  }

  const [localValue, setLocalValue] = useState(formattedLocalValue);

  const onLocalValueChanged = (newValue) => {
    setLocalValue(newValue)
    onValueChanged(name, newValue.value)
  }

  return (
    <div style={{width: "95%"}}>
      <Select
        options={rawMaritalStatuses}
        value={localValue}
        onChange={(e) => {onLocalValueChanged(e)}} 
        theme={(theme) => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary50: '#DEF2F1',
            primary25: '#DEF2F1',
            primary: '#3AAFA9',
          },
        })}
      />
    </div>
  );
}

export default MaritalStatusSelect;
