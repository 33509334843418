import React, { useState } from "react";
import {Form} from "react-bootstrap";

import InputMask from "react-input-mask";

const EditableDetail = ({originalValue, name, onValueChanged, mask, placeholder, width='95%', inputType='input' }) => {
  const [localValue, setLocalValue] = useState(originalValue == null ? "" : originalValue);

  const onLocalValueChanged = (e) => {
    if (e != null) {
      e.preventDefault();
    }

    const newValue = e.target.value;

    setLocalValue(newValue)
    onValueChanged(name, newValue)
  }


  if (mask)
  {
    return (
      <InputMask
        mask={mask}
        value={localValue} 
        onChange={(e) => {onLocalValueChanged(e)}} >

        {(inputProps) => <Form.Control placeholder={placeholder} {...inputProps} type="text" style={{width: width, display: "inline"}}/>}

      </InputMask>
    );
  }

  if (inputType === "date")
  {
    return (
      <Form.Control placeholder={placeholder} type={inputType} value={localValue} onChange={(e) => {onLocalValueChanged(e)}} style={{width: width}}/>
    );
  }

  return (
    <Form.Control maxLength={255} placeholder={placeholder} as={inputType} value={localValue} onChange={(e) => {onLocalValueChanged(e)}} style={{ display: (inputType !== 'textarea') ? "inline" : "block", minHeight: (inputType === 'textarea') ? '150px' : '', width: width}}/>
  );
}

export default EditableDetail;
