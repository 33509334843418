import React, { useState, useContext } from "react";
import { Card, Col, Row, Table, Form, Modal, Button, Spinner} from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import authenticatedFetch from "../../utils/fetch"
import LastRun from "../../utils/LastRun";
import EditableExpires from "../../utils/EditableExpires";
import InputMask from "react-input-mask";


const OpenTasksModal = ({ show, onClose, runSuccess}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [includeRecentlyCompleted, setIncludeRecentlyCompleted] = useState(false);
  const [completedDays, setCompletedDays] = useState("");

  const notyf = useContext(NotyfContext);

  const handleExportClicked = (e) => {
      e.preventDefault();
      setIsExporting(true);
  
      authenticatedFetch({
        method: "POST",
        path:  "report/opentasks",
        requestData: {
          includeRecentlyCompleted: includeRecentlyCompleted,
          completedDays: includeRecentlyCompleted ? completedDays.replace(/[^\d]/g, '') : 0,
        },
        successHandler: function(result) {
          window.open(result.url);
          setIsExporting(false);

          runSuccess("opentasks")
          onClose();
        },
        errorHandler: function(error) {
          setIsExporting(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Open Tasks</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                <td><Form.Check type="checkbox" inline name="group1" label="Include Recently Completed Tasks" checked={includeRecentlyCompleted} onChange={(e) => {setIncludeRecentlyCompleted(e.target.checked)}} /></td>
                {includeRecentlyCompleted ?
                  <td>
                    <InputMask
                      mask="99"
                      value={completedDays}
                      placeholder="Completed in Past Days..."
                      onChange={(e) => {setCompletedDays(e.target.value)}} >
                      {(inputProps) => <Form.Control {...inputProps} type="text"/>}
                    </InputMask>
                  </td>
                :
                  <td>&nbsp;</td>
                }
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}


const CompletedTasksModal = ({ show, onClose, runSuccess}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [searchByOption, setSearchByOption] = useState("createdDate");

  const notyf = useContext(NotyfContext);

  const handleFromDateChanged = (value) => {
      setFromDate(value);
  }

  const handleToDateChanged = (value) => {
      setToDate(value);
  }


  const handleExportClicked = (e) => {
      e.preventDefault();
      setIsExporting(true);
  
      authenticatedFetch({
        method: "POST",
        path:  "report/completedtasks",
        requestData: {
          searchByOption: searchByOption,
          fromDate: fromDate,
          toDate: toDate
        },
        successHandler: function(result) {
          window.open(result.url);
          setIsExporting(false);

          runSuccess("completedtasks")
          onClose();
        },
        errorHandler: function(error) {
          setIsExporting(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Configure Report | Completed Task Activity</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table> 
            <tbody>
              <tr>
                  <td>Search By:</td>
                  <td>
                    <Form.Select
                      style={{height: "40px"}}
                      value={searchByOption}
                      onChange={(e) => {setSearchByOption(e.target.value);}}
                      >
                        <option value="createdDate">Created Date</option>
                        <option value="completedDate">Completed Date</option>
                  </Form.Select>
                  </td>
              </tr>
              <tr>
                <td>Date Range:</td>
                <td>
                  <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="202px" warnWithColors={false} localExpires={fromDate} localActive={true} editingExpires={true} handleExpiresChanged={handleFromDateChanged} /></Form.Label>
                  &nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;
                  <Form.Label style={{display: "inline"}}><EditableExpires display="inline" size="lg" width="202px" warnWithColors={false} localExpires={toDate} localActive={true} editingExpires={true} handleExpiresChanged={handleToDateChanged} /></Form.Label>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
        {isExporting ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '15px', marginTop: '11px', marginBottom: '11px'}}/>
          :
          <>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={(e) => {handleExportClicked(e)}}>Run Report</Button>
          </>
          }
        </Modal.Footer>
      </Modal>
  );
}

const ReportList = () => {
    const [showCompletedTasksModal, setShowCompletedTasksModal] = useState(false);
    const [showOpenTasksModal, setShowOpenTasksModal] = useState(false);

    const [lastRunRefresh, setLastRunRefresh] = useState(0);

    const { isH3Admin, authorities } = useContext(UserSettingsContext);

    const runSuccess = (e) => {
      setLastRunRefresh(lastRunRefresh + 1);
    }

    return (
      <Card>
        <Card.Header style={{marginBottom: '-15px'}}>
            <Card.Title>
            Task Reports
            </Card.Title>
          </Card.Header>
        <Table striped hover>
          <thead>
            <tr>
              <td>Report Type</td>
              <td>Available Formats</td>
              <td>Last Run</td>
            </tr>
          </thead>
          <tbody>
            {(isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowOpenTasksModal(true)}}>
                <td><a href="#">Open Tasks</a></td>
                <td>.csv</td>
                <td><LastRun reportType="opentasks" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
            {(isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")) ?
            <tr style={{cursor:"pointer"}} onClick={(e) => {setShowCompletedTasksModal(true)}}>
                <td><a href="#">Completed Task Activity</a></td>
                <td>.csv</td>
                <td><LastRun reportType="completedtasks" refresh={lastRunRefresh}/></td>
            </tr>
             : <></>
            }
          </tbody>
        </Table>
        <OpenTasksModal show={showOpenTasksModal} onClose={() => setShowOpenTasksModal(false)} runSuccess={runSuccess} />
        <CompletedTasksModal show={showCompletedTasksModal} onClose={() => setShowCompletedTasksModal(false)} runSuccess={runSuccess} />
      </Card>
    )
  };

const TaskReports = () => {

    return (
      <Row>
        <Helmet title="Task Reports"/>
        <Col lg="12">
          <ReportList />
        </Col>
      </Row>
    )
  };
  
  export default TaskReports;
  