import React, {useEffect, useState, useContext} from "react";
import { Helmet } from "react-helmet-async";

import InputMask from "react-input-mask";
import authenticatedFetch from "../utils/fetch";
import NotyfContext from "../contexts/NotyfContext";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Button, Card, Col, Container, Row, Table, Form, Spinner } from "react-bootstrap";

import { Upload } from "react-feather";

import doctor1 from "../assets/img/avatars/logo_unknown_doctor.jpg";

function equalStrings(a, b)
{
  if (a === null && b === "")
  {
    return true;
  }
  if (a === "" && b === null)
  {
    return true;
  }

  return a === b;
}

const ProfileDetails = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [saving, setIsSaving] = useState(false);

  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [newPhotoFile, setNewPhotoFile] = useState(null);

  const notyf = useContext(NotyfContext);
  const { navbarRefresh, setNavbarRefresh } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "user",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);

        setEmail(result.email == null ? "" : result.email);
        setCellPhone(result.cellPhone == null ? "" : result.cellPhone);
        setPhotoUrl(result.photoUrl == null ? "" : result.photoUrl);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [])


  const handleCellPhoneChanged = (value) => {
    setCellPhone(value.replace(/_/g, ''));

  }

  const isCellPhoneValid = () => {
    if (cellPhone === "" || cellPhone === "() -")
    {
      return true;
    }
    
    var res = cellPhone.match(/^\((\d{3})\) (\d{3})-(\d{4})$/);
    return res != null;
  }

  const isEmailValid = () => {
    return email.indexOf("@") > -1 && email.indexOf(".") > -1 && email.length > 5;
  }

  const handleEmailChanged = (value) => {
    setEmail(value);
  }

  const handleUploadClicked = (e) => {
    e.preventDefault();

    document.getElementById('file-upload').click();
  }

  const handleProfilePhotoAdded = (e) => {
    if (e.target.files && e.target.files.length === 1)
    {
      setNewPhotoFile(e.target.files[0]);
      setPhotoUrl(URL.createObjectURL(e.target.files[0]));
    }
  }

  const handleSaveClicked = (e) => {
    e.preventDefault();

    setIsSaving(true);

    var formData = new FormData();
    formData.append("data", new Blob([JSON.stringify(
      {
        email: email,
        cellPhone: cellPhone
      })], {
        type: "application/json"
    }));

    if (newPhotoFile != null)
    {
      formData.append("newPhotoFile", newPhotoFile);
    }

    authenticatedFetch({
      path: "user",
      method: "POST",
      requestData: formData,
      formData: true,
      successHandler: function(result) {
        setNewPhotoFile(null);
        
        setData(result);

        setEmail(result.email == null ? "" : result.email);
        setCellPhone(result.cellPhone == null ? "" : result.cellPhone);
        setPhotoUrl(result.photoUrl == null ? "" : result.photoUrl);

        setNavbarRefresh(navbarRefresh + 1);
        setIsSaving(false);

        notyf.open({type: "success", message: "Changes saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  if (!isLoaded)
  {
    return (<Card><Card.Body>Loading...</Card.Body></Card>);
  }
  else if (error || !data)
  {
    return (<Card><Card.Body>Error... Please reload the page...</Card.Body></Card>);
  }
  else
  {
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mb-0">{data.firstName} {data.lastName}</Card.Title>
        </Card.Header>
        <Card.Body>
        <Row>
          <Col lg="8">
            <Table className="table-borderless">
              <tbody>
                <tr>
                  <td>Email*:</td>
                  <td><Form.Control type="input" value={email} onChange={(e) => {handleEmailChanged(e.target.value)}}/></td>
                </tr>
                <tr>
                  <td>Cell Phone:</td>
                  <td>
                    <InputMask
                      mask="(999) 999-9999"
                      value={cellPhone} 
                      onChange={(e) => {handleCellPhoneChanged(e.target.value)}} >
                      {(inputProps) => <Form.Control {...inputProps} type="text" />}
                    </InputMask>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg="4" className="text-center">
            <img
              src={photoUrl == null || photoUrl === '' ? doctor1 : photoUrl}
              alt="Profile"
              className="rounded-circle mb-2"
              width="128"
              height="128"
            />
            <br /><br />
            {!saving &&
              <>
                <Button variant="primary" size="sm" onClick={(e) => {handleUploadClicked(e)}}><Upload width={16} height={16}/> Upload</Button>
                <Form.Control id="file-upload" type="file" accept="image/*" style={{display: "none"}} onChange={(e) => handleProfilePhotoAdded(e)}/>
              </>
            }
          </Col>
        </Row>
        <br /><br />
        <Row>
          <Col lg="12">
            {saving ? <Spinner animation="border" size="sm" style={{marginLeft:"40px"}}/> :
              <Button variant="primary" type="submit" onClick={(e) => {handleSaveClicked(e)}} disabled={!(isEmailValid() && isCellPhoneValid() && (!equalStrings(data.email, email) || !equalStrings(data.cellPhone, cellPhone) || !equalStrings(data.photoUrl, photoUrl)))}>Save Changes</Button>
            }
          </Col>
        </Row>
        </Card.Body>
      </Card>
      );
  }
};

const Profile = () => (
  <React.Fragment>
    <Helmet title="Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Profile</h1>

      <Row>
        <Col lg="8">
          <ProfileDetails />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Profile;
