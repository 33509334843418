import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Table, Badge } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import {List, Grid} from "react-feather"

import useSidebar from "../hooks/useSidebar";
import useSettingsState from "../hooks/useSettingsState";

import { useTable, useSortBy, useFilters, useExpanded } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import TableMultiSelectColumnFilter from "../utils/TableMultiSelectColumnFilter";

import authenticatedFetch from "../utils/fetch"
import FppeTask from "../utils/FppeTask";
import FppeTaskModal from "../utils/FppeTaskModal";
import EditableExpires from "../utils/EditableExpires";
import rawTaskStatuses from "../utils/taskStatuses";
import rawFppeTaskCategories from "../utils/fppeTaskCategories";
import FppeTaskListRow from "../utils/FppeTaskListRow";

import getActivityDateTimeDisplay from "../utils/datetime"


const Lane = ({ name, status, className='', showAddNewTask=false, children, onContainerLoaded, reloadTaskLists }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  const [show, setShow] = useState(false);

  const handleAddNewTask = () => setShow(true);
  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadTaskLists();
  }

  return (

      <Card style={{minHeight: '200px'}}>
        <Card.Header>
          <Card.Title className={className}>{name}</Card.Title>
          {/* <h6 className="card-subtitle text-muted" >
            Nam pretium turpis et arcu. Duis arcu.
          </h6> */}
        </Card.Header>
        <Card.Body style={{marginTop: '-25px'}}>
          <div data-status={status} ref={handleContainerLoaded}>{children}</div>
          {showAddNewTask ? 
          <>
            <div className="d-grid">
              <Button variant="primary" onClick={(e) => handleAddNewTask()}>+ Add new task</Button>
            </div> 
            <FppeTaskModal show={show} onCancel={handleCancel} onSave={handleSave} />
          </>
          : ''
          }
          {status === 'COMPLETE' ?
          <>
          (Completed in past 30 days)
          </>
          : <></>
          }
        </Card.Body>
      </Card>
  );
};


const getStatusRankValueString = (value, desc) => {
  var intRes = 1;
  if (value.toString() === "IN_PROGRESS") {
    intRes = 2;
  } else if (value.toString() === "BLOCKED") {
    intRes = 3;
  } else if (value.toString() === "BLOCKED_INTERNALLY") {
    intRes = 4;
  } else if (value.toString() === "BLOCKED_SUBMITTED") {
    intRes = 5;
  } else if (value.toString() === "COMPLETE") {
    intRes = 6;
  }

  if (desc) {
    return (-1 * intRes).toString();
  }

  return intRes.toString();
};


const ProviderCell = ({ value, row }) => {

  const handleProviderClicked = (e, providerId) => {
    e.stopPropagation();
    window.location = "/provider/" + providerId;
  }

  if (row.original.task.providerIsDeleted) {
    return <>{value}</>
  }

  return (
    <a href="#" onClick={(e) => handleProviderClicked(e, row.original.task.providerId)}>{value}</a>
  )
};

const DescriptionCell = ({ value, row }) => {
  var descriptionDisplay = '';
  if (value !== null && value !== "") {
      descriptionDisplay = value.split("\n").map(function(item, idx) {
      return ( 
          <span key={idx}>
              {item}
              <br />
          </span>
        )
    });
  }

  return descriptionDisplay;
};

function StatusSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <TableMultiSelectColumnFilter filterValue={filterValue} setFilter={setFilter} rawCategories={rawTaskStatuses} showNoCategory={false}/>
  );
}

function CategorySelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <TableMultiSelectColumnFilter filterValue={filterValue} setFilter={setFilter} rawCategories={rawFppeTaskCategories} showNoCategory={false}/>
  );
}

function customStatusFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.status) {
        return true;
      }
    }
    
    return false;
  });
}

function customCategoryFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.category.value) {
        return true;
      }
    }
    
    return false;
  });
}

const FppeTaskList = ({taskList, taskToOpen, isLoaded, error, reloadTaskLists}) => {
  const data = taskList;

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "task.status",
        key: "status",
        Filter: StatusSelectColumnFilter,
        filter: customStatusFilter,
        Cell: ({ value, row }) => {
          var statusLabel = "";
          for (var i = 0; i < rawTaskStatuses.length; i++) {
            if (rawTaskStatuses[i].value === value) {
              statusLabel = rawTaskStatuses[i].label;
              break;
            }
          }
          if (value === "BLOCKED_INTERNALLY" || value === "BLOCKED_SUBMITTED")
          {
            return <span className="alert-warning">{statusLabel}</span>
          }
          else if (value === "BLOCKED")
          {
            return <span className="alert-danger">{statusLabel}</span>
          }

          return statusLabel;
        },
        sortType: (rowA, rowB, columnId, desc) => {
          return getStatusRankValueString(rowA.values[columnId], desc).localeCompare(getStatusRankValueString(rowB.values[columnId], desc));
        },
      },
      {
        Header: "Deadline",
        accessor: "task.deadline",
        key: "deadline",
        Cell: ({ value, row }) => (
          <EditableExpires localExpires={value} localActive={true} editingExpires={false} redDays={-1} yellowDays={4} warnWithColors={row.original.task.status !== "COMPLETE"} />
        )
      },
      {
        Header: "Category",
        accessor: "task.category.label",
        key: "category",
        Filter: CategorySelectColumnFilter,
        filter: customCategoryFilter,
        Cell: ({ value, row }) => (
            <Badge pill className="float-start" bg="secondary">
              {value}
            </Badge>
        )
      },
      {
        Header: "Provider",
        accessor: "task.providerLastNameFirstName",
        key: "providerName",
        Cell: ({ value, row }) => (
          <ProviderCell value={value} row={row}/>
        )
      },
      {
        Header: "Location",
        accessor: "task.facilityName",
        key: "facilityName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Number of Patients",
        accessor: "task.patientCount",
        key: "patientCount",
        Cell: ({ value, row }) => {
          return value != null && value > 0 ? <span>{value}</span> : ''
        }
      },
      {
        Header: "Description",
        accessor: "task.description",
        key: "description",
        Cell: ({ value, row }) => (
          <DescriptionCell value={value} row={row} />
        )
      },
      {
        Header: "Last Activity Date",
        accessor: item => item.task.activity.length > 0 ? item.task.activity[0].created : null,
        key: "lastActivityDate",
        Cell: ({ value, row }) => {
          return getActivityDateTimeDisplay(value, false, false, true);
        }
      }
    ],
  []
  )

  var initialStatusFilter = [];
  for (var i = 0; i < rawTaskStatuses.length; i++) {
    if (rawTaskStatuses[i].value === "CREATED" || rawTaskStatuses[i].value === "IN_PROGRESS" || 
    rawTaskStatuses[i].value === "BLOCKED" || rawTaskStatuses[i].value === "BLOCKED_INTERNALLY" ||
    rawTaskStatuses[i].value === "BLOCKED_SUBMITTED")
    {
      initialStatusFilter.push({"value": rawTaskStatuses[i].value, "label": rawTaskStatuses[i].label});
    } 
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { 
        sortBy: [
          {
            id: 'task.due',
            desc: false
          }
        ], 
        filters: [
          {
              id: 'task.status',
              value: initialStatusFilter
          },
        ], 
      },
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
  );

  return (
    <Card>
        <Table className="table-sm" striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = ( column.key === "description" || column.key === "category" || column.key === "status" || column.key === "category") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                      {(column.key !== "description" && column.key !== "category" && column.key !== "status") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : <></>
                      }
                      {(column.key === "status" || column.key === "category") ? <>{column.render("Filter")}</>
                        : <></>
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={5}>Loading...</td></tr>) : (
              rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <FppeTaskListRow showModal={taskToOpen && taskToOpen === row.original.task.id} key={key} row={row} reloadTaskLists={reloadTaskLists}/>
                );
              })))
            }
            <tr>
              <td colSpan={12}>Total: <b>{rows.length}</b></td>
            </tr>
          </tbody>
        </Table>
    </Card>
  )
};


const containers = [];

const FppeTasks = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [taskToOpen, setTaskToOpen] = useState(null);

  const [viewType, setViewType] = useSettingsState('fppeTaskViewType', 'grid')

  const [show, setShow] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("FPPE_TASK_EDIT");

  const onContainerReady = (container) => {
    containers.push(container);
  };

  const { setFppeBlockedBadge } = useSidebar();
  
  const [searchParams] = useSearchParams();
  
  if (searchParams.get("task") && searchParams.get("task") !== '')
  {
    var parsed = parseInt(searchParams.get("task"));
    if (parsed && !isNaN(parsed))
    {
      // Prevent infinite rendering loop
      setTimeout(() => {
        setTaskToOpen(parsed);
      }, 0);
      
    }
  }

  const handleAddNewTask = () => setShow(true);

  const handleCancel = () => {
    setShow(false);
  }
  

  const handleSave = () => {
    setShow(false);
    reloadTaskLists();
  }

  const handleViewTypeChanged = (viewType) => {
    setTaskToOpen(null);
    setViewType(viewType);
  }

  useEffect(() => {
    authenticatedFetch({
      path: "fppetask/revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "fppetask/blocked",
      successHandler: function(result) {
        setFppeBlockedBadge(result)
      }
    });
  }, [setFppeBlockedBadge]);

  const reloadTaskLists = () =>
  {
    authenticatedFetch({
      path: "fppetask/revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "fppetask/blocked",
      successHandler: function(result) {
        setFppeBlockedBadge(result)
      }
    });
  }

  if (!isLoaded) {
    return (
    <React.Fragment>
        <Helmet title="Tasks" />
        <h1 className="h3 mb-3">Tasks</h1>
        <div>Loading...</div>
      </React.Fragment>
    );
  }
  else if (error || !data) {
    return (
      <React.Fragment>
          <Helmet title="Tasks" />
          <h1 className="h3 mb-3">Tasks</h1>
          <div>Something went wrong... please reload the page...</div>
        </React.Fragment>
    );
  }
  else {
    var showArchived=false;
    
    const tasksNotStarted = data.filter(function(task) {
      if (task.status === 'CREATED' && (showArchived || !task.archived)) {
        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <FppeTask
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showProgress={false}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksInProgress = data.filter(function(task) {
      if (task.status === 'IN_PROGRESS' && (showArchived || !task.archived)) {
        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <FppeTask
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksBlocked = data.filter(function(task) {
      if ((task.status === 'BLOCKED' || task.status === 'BLOCKED_INTERNALLY' || task.status === 'BLOCKED_SUBMITTED') && (showArchived || !task.archived)) {
        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <FppeTask
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksComplete = data.filter(function(task) {
      if (task.status === 'COMPLETE' && (showArchived || !task.archived)) {
        return true;
      }
      return false;
    }).sort(function(taskA, taskB) {
      // We don't have a true completed date field, but sort completed tasks by updated date DESC
      if (taskA.updated < taskB.updated)
      {
        return 1;
      }
      if (taskA.updated > taskB.updated)
      {
        return -1;
      }

      return 0;
    }).map((task, i) => {
      return (
        <FppeTask
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showProgress={false}
          showDue={false}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    var taskList = [];
    if (isLoaded)
    {
      taskList = data.filter(function(task) {
        if (showArchived || !task.archived) {
          return true;
        }
        return false;
      }).map((task, i) => {
        return {task: task}
      }, this);
    }
  
    return (
      <React.Fragment>
        <Helmet title="Tasks" />
        <Container fluid className="p-0" style={{marginTop: "-20px"}}>
          <Row style={{marginBottom: "-10px"}}>
            <Col lg="3">
              <h1 className="h3 mb-3">FPPE Tasks</h1>
            </Col>
            <Col lg="8">&nbsp;</Col>
            <Col lg="1" style={{marginTop: window.innerWidth < 1300 ? '10px' : ''}}>
              <Grid className={viewType === "grid" ? "text-success" : ""} style={{cursor: viewType === "grid" ? "default" : "pointer"}} onClick={(e) => handleViewTypeChanged('grid')}/>
              &nbsp;
              <List className={viewType === "list" ? "text-success" : ""} style={{cursor: viewType === "list" ? "default" : "pointer"}} onClick={(e) => handleViewTypeChanged('list')}/>
            </Col>
          </Row>
          <Row style={{marginBottom: "20px"}}>
            {canEdit &&
            <Col style={{marginTop: window.innerWidth < 1300 ? '20px' : ''}}>
              <Button variant="primary" size="sm" onClick={(e) => handleAddNewTask()}>+ Add new task</Button>
              <FppeTaskModal show={show} onCancel={handleCancel} onSave={handleSave} />
            </Col>
            }
          </Row>
          {viewType === "grid" ?
          <Row>
            <Col sm="6" md="3">
              <Lane name="Inbox" status="CREATED" onContainerLoaded={onContainerReady} reloadTaskLists={reloadTaskLists} showAddNewTask={canEdit}>
                {tasksNotStarted}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="In Progress"  status="IN_PROGRESS" onContainerLoaded={onContainerReady}>
                {tasksInProgress}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="Waiting" status="BLOCKED" onContainerLoaded={onContainerReady} className="alert alert-danger">
                {tasksBlocked}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="Complete" status="COMPLETE" onContainerLoaded={onContainerReady}>
                {tasksComplete}
              </Lane>
            </Col>
          </Row>
          : <>
            <FppeTaskList taskList={taskList} taskToOpen={taskToOpen} isLoaded={isLoaded} error={error} reloadTaskLists={reloadTaskLists} />
            </>
          }
        </Container>
      </React.Fragment>
    );
  }
};

export default FppeTasks;
