import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Spinner, Container, Form, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import authenticatedFetch from "../../utils/fetch"
import NotyfContext from "../../contexts/NotyfContext";
import UsStateSelect from "../../utils/UsStateSelect";
import InputMask from "react-input-mask";
import { useTable, useSortBy, useFilters } from "react-table";

import SlimPracticePicker from "../../utils/SlimPracticePicker"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

function equalStrings(a, b)
{
  if (a === null && b === "")
  {
    return true;
  }
  if (a === "" && b === null)
  {
    return true;
  }

  return a === b;
}

const IsActiveDisplay = ({isActive, row, practiceId, reload}) => {
  const[localIsActive, setLocalIsActive] = useState(isActive);

  const notyf = useContext(NotyfContext);

  const onIsActiveChanged = (e) => {
    if (!e.target.checked && !window.confirm("Removing " + row.original.name + " from the practice will also delete any provider participation data (carrier level, and lines of business) for " + row.original.name + ", for the TIN + NPI combo.  Are you sure you want to remove this carrier from the practice?"))
    {
      return;
    }
    else if (e.target.checked && !window.confirm("Add " + row.original.name + " to the practice?"))
    {
      return;
    }

    setLocalIsActive(e.target.checked);
    
    authenticatedFetch({
      method: "PUT",
      requestData: {isActive: e.target.checked},
      path:  "carrier/admin/" + row.original.id + "/practice/" + practiceId,
      successHandler: function(result) {
        reload();
        var message = e.target.checked ? "Added!" : "Removed!";
        notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setLocalIsActive(!e.target.checked)
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return <Form.Check checked={localIsActive} onClick={(e) => {e.stopPropagation()}} onChange={(e) => {onIsActiveChanged(e)}} />;
}

const ActiveHeader = ({data}) => {
  if (data && data.length > 0)
  {
    let countActive = data.filter((item) => item.isActive).length;
    return "(" + countActive + ")";
  }
  
  return (
    "Active"
  )
}

const AlsoUsedByPopover = ({alsoUsedBy}) => {
  if (alsoUsedBy && alsoUsedBy.length > 0) {
    alsoUsedBy = alsoUsedBy.map(function(item, idx) {
      return ( 
          <span key={idx}>
              <li>{item}</li>
          </span>
        )
    });
  }

  return(
    <>
      <div style={{fontWeight: "bold", fontSize: "0.9em", marginTop: "-5px", marginBottom: "-5px"}}>Also Used By</div>
      <span style={{fontStyle: "italic", fontSize: "0.9em"}}><ul>{alsoUsedBy}</ul></span>
    </>
  );
}

const AlsoUsedByDisplay = ({alsoUsedBy}) => {
  var alsoUsedByDisplay = alsoUsedBy.join(', ');
  var showOverlay = false;
  if (alsoUsedByDisplay && alsoUsedByDisplay.length > 75)
  {
    showOverlay = true;
    alsoUsedByDisplay = alsoUsedBy.length + " other practices...";
  }

  if (!showOverlay)
  {
    return <span>{alsoUsedByDisplay}</span>
  }

  return (
    <OverlayTrigger
      triggers="[hover, focus]"
      placement="bottom"
      overlay={<Popover><Popover.Body><AlsoUsedByPopover alsoUsedBy={alsoUsedBy}/></Popover.Body></Popover>}
    >
      <span>{alsoUsedByDisplay}</span>
    </OverlayTrigger>
  )
}

const LineOfBusinessRow = ({practiceId, businessLineId, businessLineName, carrierName, isActive, refreshCarrierList}) => {
  const[localIsActive, setLocalIsActive] = useState(isActive);

  const notyf = useContext(NotyfContext);

  const handleActiveChanged = (e) => {
    if (!e.target.checked && !window.confirm("Removing business line: " + businessLineName + " from " + carrierName + " for this practice will also delete any provider business line participation data for the TIN + NPI + location combos.  Are you sure you want to remove this business line?"))
    {
      return;
    }
    else if (e.target.checked && !window.confirm("Add business line: " + businessLineName + " to " + carrierName + " for the practice?"))
    {
      return;
    }

    setLocalIsActive(e.target.checked);

    authenticatedFetch({  
      method: "PUT",
      requestData: {isActive: e.target.checked},
      path:  "carrier/admin/businessLine/" + businessLineId + "/practice/" + practiceId,
      successHandler: function(result) {
        refreshCarrierList();
        var message = e.target.checked ? "Added!" : "Removed!";
        notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setLocalIsActive(!e.target.checked)
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <tr>
      <td>
        <Form.Check type="checkbox" onChange={(e) => {handleActiveChanged(e)}} value={businessLineId} checked={localIsActive} />
      </td>
      <td>{businessLineName}</td>
    </tr>
  );
}

const LinesOfBusinessModal = ({ show, onClose, practiceId, carrierName, businessLines, refreshCarrierList}) => {

  const handleClose = () => {
    onClose();
  }

  // Build the business line checkboxes
  var businessLineRows = businessLines.map(function(item, idx) {
    return ( 
        <LineOfBusinessRow key={idx} refreshCarrierList={refreshCarrierList} practiceId={practiceId} businessLineId={item.id} businessLineName={item.name} carrierName={carrierName} isActive={item.isActive} />
      )
  });

  return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
            Lines of Business for {carrierName}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table size="sm" striped hover> 
            <thead>
              <tr>
                <th>Active</th>
                <th>Line of Business</th>
              </tr>
            </thead>
            <tbody>
              {businessLineRows}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}

const BusinessLinesDisplay = ({businessLines, practiceId, row, refreshCarrierList}) => {
  const [showLinesOfBusiness, setShowLinesOfBusiness] = useState(false);
  const handleCloseLinesOfBusiness = () => setShowLinesOfBusiness(false);

  let denominator = businessLines.length;
  let numerator = businessLines.filter((item) => item.isActive).length;
  
  const handleShowLinesOfBusiness = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowLinesOfBusiness(true);
  }

  if (!row.original.isActive)
  {
    return <small>(n/a)</small>
  }

  if (denominator === 0)
  {
    return <>0 of 0</>
  }

  return (
    <>
      <a href="#" onClick={(e) => handleShowLinesOfBusiness(e)}>{numerator} of {denominator}</a>
      <LinesOfBusinessModal refreshCarrierList={refreshCarrierList} businessLines={businessLines} practiceId={practiceId} carrierName={row.original.name} show={showLinesOfBusiness} onClose={handleCloseLinesOfBusiness} />
    </>
  )
}


function SearchColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  return (
    <Form.Control 
      size="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      onClick = {(e) => {e.stopPropagation()}}
      placeholder={`Search...`}
      className="mt-2"
      style={{width: "150px", display: "inline", marginLeft:"8px"}}
    />
  );
}

function containsAllWords(words, name)
{
  for (var i=0; i < words.length; i++)
  {
    if(!name || name.toLowerCase().indexOf(words[i].trim().toLowerCase()) < 0)
    {
      return false;
    }
  }

  return true;
}

function customNameFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
      if (!filterValue)
      {
        return true;
      }

      let words = filterValue.trim().split(" ");
      return containsAllWords(words, row.original.name);
  });
}

function customOthersUsedByFilter(rows, columnIds, filterValue) {
  return rows.filter((row) => {
      if (!filterValue)
      {
        return true;
      }

      if (!row.original.othersUsedBy || row.original.othersUsedBy.length === 0)
      {
        return false;
      }

      // Look across all practice names
      let words = filterValue.trim().split(" ");
      for (var i=0; i < words.length; i++)
      {
        var wordFound = false;
        for (var j=0; j < row.original.othersUsedBy.length; j++)
        {
          if(row.original.othersUsedBy[j] && row.original.othersUsedBy[j].toLowerCase().indexOf(words[i].trim().toLowerCase()) >= 0)
          {
            wordFound = true;
            break;
          }
        }

        if (!wordFound)
        {
          return false;
        }
      }

      return true;
  });
}

const CarrierModal = ({show, onHide, onSave, id, name, portalUrl, state, phone, fax, email, initialEnrollmentEmails, othersUsedBy, practiceId}) => {
  const[isSaving, setIsSaving] = useState(false);

  const notyf = useContext(NotyfContext);

  if (!id || id === null)
  {
    id = 0;
  }  
  if (!name || name === null)
  {
    name = "";
  }
  if (!portalUrl || portalUrl === null)
  {
    portalUrl = "";
  }
  if (!state || state === null)
  {
    state = "";
  }
  if (!phone || phone === null)
  {
    phone = "";
  }
  if (!fax || fax === null)
  {
    fax = "";
  }
  if (!email || email === null)
  {
    email = "";
  }
  if (!initialEnrollmentEmails || initialEnrollmentEmails === null)
  {
    initialEnrollmentEmails = "";
  }

  const [localName, setLocalName] = useState(name);
  const [localPortalUrl, setLocalPortalUrl] = useState(portalUrl);
  const [localState, setLocalState] = useState(state);
  const [localPhone, setLocalPhone] = useState(phone);
  const [localFax, setLocalFax] = useState(fax);
  const [localEmail, setLocalEmail] = useState(email);
  const [localInitialEnrollmentEmails, setLocalInitialEnrollmentEmails] = useState(initialEnrollmentEmails);
  const [localAddToPractice, setLocalAddToPractice] = useState(true);


  const reset = () => {
    setLocalName(!name || name === null ? "" : name);
    setLocalPortalUrl(!portalUrl || portalUrl === null ? "" : portalUrl);
    setLocalState(!state || state === null ? "" : state);
    setLocalPhone(!phone || phone === null ? "" : phone);
    setLocalFax(!fax || fax === null ? "" : fax);
    setLocalEmail(!email || email === null ? "" : email);
    setLocalInitialEnrollmentEmails(!initialEnrollmentEmails || initialEnrollmentEmails === null ? "" : initialEnrollmentEmails);
    setLocalAddToPractice(true);
  }

  const handleSave = () => {
    if (id > 0 && othersUsedBy && othersUsedBy.length > 0 && !window.confirm(name + " is used by " + othersUsedBy.length + " other practice" + (othersUsedBy.length > 1 ? "s" : "") + ".  Are you sure you want to update this carrier?"))
    {
      return;
    }

    setIsSaving(true);

    authenticatedFetch({
      method: "POST",
      requestData: {
        id: id,
        name: localName,
        portalUrl: localPortalUrl,
        state: localState,
        phone: localPhone,
        fax: localFax,
        email: localEmail,
        initialEnrollmentEmails: localInitialEnrollmentEmails,
        addToPracticeId: localAddToPractice ? practiceId : 0,
      },
      path:  "carrier/admin",
      successHandler: function(result) {
        setIsSaving(false);
        onSave();
        if (!id || id === null || id <= 0)
        {
          reset();
        }
        var message = id > 0 ? "Carrier Updated!" : "Carrier Added!";
        notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleCancel = () => {
    onHide();
    reset();
  }

  const handleNameChanged = (name) => {
    setLocalName(name);
  }

  const handlePortalUrlChanged = (portalUrl) => {
    setLocalPortalUrl(portalUrl);
  }

  const handleStateChanged = (name, state) => {
    setLocalState(state);
  }

  const handlePhoneChanged = (phone) => {
    setLocalPhone(phone.replace(/_/g, ''));
  }

  const handleFaxChanged = (fax) => {
    setLocalFax(fax.replace(/_/g, ''));
  }

  const handleEmailChanged = (email) => {
    setLocalEmail(email);
  }

  const handleInitialEnrollmentEmailsChanged = (emails) => {
    setLocalInitialEnrollmentEmails(emails);
  }

  const handleAddToPracticeChanged = (val) => {
    setLocalAddToPractice(val);
  }
    

  const isPhoneNumberValid = (value) => {
    if (value === "" || value === "() -")
    {
      return true;
    }
    
    var res = value.match(/^\((\d{3})\) (\d{3})-(\d{4})$/);
    return res != null;
  }

  const isNameValid = () => {
    return localName && localName.length > 1;
  }

  const isPortalUrlValid = () => {
    return localPortalUrl === "" || (localPortalUrl.indexOf("http://") === 0 || localPortalUrl.indexOf("https://") === 0);
  }

  const isPhoneValid = () => {
    return isPhoneNumberValid(localPhone);
  }

  const isFaxValid = () => {
    return isPhoneNumberValid(localFax);
  }

  const isEmailAddressValid = (emailAddress) => {
    return emailAddress === "" || (emailAddress.indexOf("@") > -1 && emailAddress.indexOf(".") > -1 && emailAddress.length > 5);
  }

  const isEmailValid = () => {
    return isEmailAddressValid(localEmail);
  }

  const areInitialEnrollmentEmailsValid = () => {
    if (localInitialEnrollmentEmails === "")
    {
      return true;
    }    

    const emailList = localInitialEnrollmentEmails.split(",").map(function(item) {
      return item.trim();
    });

    for (var i=0; i < emailList.length; i++)
    {
      if (emailList[i] === "")
      {
        return false;
      }

      if (!isEmailAddressValid(emailList[i]))
      {
        return false;
      }
    }
    

    return true;
  }

  var title = id > 0 ? "Edit Carrier: (" + id + ") " + localName : "Add Carrier: " + localName;

  return (
    <Modal show={show} onHide={handleCancel} centered>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
            {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table> 
                <tbody>
                <tr>
                    <td style={{width: "200px"}}>Name:</td>
                    <td><Form.Control style={{height: "38px", width: "95%"}} onChange={(e) => {handleNameChanged(e.target.value, e)}} value={localName} /></td>
                </tr>
                <tr>
                    <td>Portal URL:</td>
                    <td><Form.Control style={{height: "38px", width: "95%"}} onChange={(e) => {handlePortalUrlChanged(e.target.value, e)}} value={localPortalUrl} /></td>
                </tr>
                <tr>
                    <td>State:</td>
                    <td><UsStateSelect placeholder="State" originalValue={localState} name="state" onValueChanged={handleStateChanged}/></td>
                </tr>
                <tr>
                    <td>Phone:</td>
                    <td>
                      <InputMask
                        style={{height: "38px", width: "95%"}}
                        mask='(999) 999-9999'
                        value={localPhone} 
                        onChange={(e) => {handlePhoneChanged(e.target.value)}} >
                        {(inputProps) => <Form.Control {...inputProps} type="text" />}
                      </InputMask>
                    </td>
                </tr>
                <tr>
                    <td>Fax:</td>
                    <td>
                      <InputMask
                        style={{height: "38px", width: "95%"}}
                        mask='(999) 999-9999'
                        value={localFax} 
                        onChange={(e) => {handleFaxChanged(e.target.value)}} >
                        {(inputProps) => <Form.Control {...inputProps} type="text" />}
                      </InputMask>
                    </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td><Form.Control style={{height: "38px", width: "95%"}} onChange={(e) => {handleEmailChanged(e.target.value, e)}} value={localEmail} /></td>
                </tr>
                <tr>
                  <td>Initial Enrollment Emails (comma-separated):</td>
                  <td><Form.Control style={{height: "38px", width: "95%"}} onChange={(e) => {handleInitialEnrollmentEmailsChanged(e.target.value, e)}} value={localInitialEnrollmentEmails} /></td>
                </tr>
                { id == 0 &&
                <tr>
                  <td>Add to this Practice:</td>
                  <td><Form.Check checked={localAddToPractice} onChange={(e) => {handleAddToPracticeChanged(e.target.checked)}} /></td>
                </tr>
                }
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          { isSaving ? 
            <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '40px', marginTop: '11px', marginBottom: '11px'}}/>
            :
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleSave} disabled={!(isNameValid() && isPortalUrlValid() && isPhoneValid() && isFaxValid() && isEmailValid(localEmail) && areInitialEnrollmentEmailsValid(localEmail) && (!equalStrings(name, localName) || !equalStrings(portalUrl, localPortalUrl) || !equalStrings(state, localState) || !equalStrings(phone, localPhone) || !equalStrings(fax, localFax) || !equalStrings(email, localEmail) || !equalStrings(initialEnrollmentEmails, localInitialEnrollmentEmails)))}>{id > 0 ? "Update" : "Create"}</Button>
            </>
          }
        </Modal.Footer>
    </Modal>
  );
}

const CarrierRow = ({practiceId, row, refreshCarrierList}) => {
  const [show, setShow] = useState(false);

  const handleShow = (key) => {
    if (key === "isActive" || key==="businessLines")
    {
      return;
    }

    setShow(true);
  }

  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    refreshCarrierList();
  }

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td onClick={(e) => handleShow(cell.column.key)} {...cell.getCellProps()} style={{cursor: (cell.column.key==="isActive" || cell.column.key==="businessLines") ? "" : "pointer", whiteSpace: cell.column.key==="phone" || cell.column.key==="fax" ? "nowrap" : ""}}>
            {cell.render("Cell")}
          </td>
        );
      })}
      <CarrierModal key={row.original.id} show={show} onHide={handleCancel} onSave={handleSave} id={row.original.id} name={row.original.name} portalUrl={row.original.portalUrl} state={row.original.state} phone={row.original.phone} fax={row.original.fax} email={row.original.email} initialEnrollmentEmails={row.original.initialEnrollmentEmails} othersUsedBy={row.original.othersUsedBy} />
    </tr>           
  )
}



const CarrierList = ({practiceId}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  }

  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    refreshCarrierList();
  }

  useEffect(() => {
    refreshCarrierList();
  }, [practiceId])

  const refreshCarrierList = () => {
    authenticatedFetch({
      path: "carrier/admin/practice/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: (param1) => {
          return (
            <ActiveHeader data={param1.data}/>
          );
        },
        accessor: "isActive",
        key: "isActive",
        Cell: ({ value, row }) => (
          <IsActiveDisplay key={row.original.id} isActive={value} row={row} practiceId={practiceId} reload={refreshCarrierList}/>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          if (rowA.values[columnId] === rowB.values[columnId]) {
            return rowA.original.name.localeCompare(rowB.original.name);
          }

          if (rowA.values[columnId] && !rowB.values[columnId]) {
            return -1;
          }

          return 1;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        key: "id",
      },
      {
        Header: "Name",
        accessor: "name",
        key: "name",
        Filter: SearchColumnFilter,
        filter: customNameFilter,
      },
      {
        Header: "Lines of Business",
        accessor: "businessLines",
        key: "businessLines",
        Cell: ({ value, row }) => (
          <BusinessLinesDisplay refreshCarrierList={refreshCarrierList} businessLines={value} practiceId={practiceId} row={row} />
        ),
      },
      // {
      //   Header: "Portal URL",
      //   accessor: "portalUrl",
      //   key: "portalUrl",
      //   Cell: ({ value, row }) => {
      //     var valueDisplay = value;
      //     if (valueDisplay && valueDisplay.length > 35)
      //     {
      //       valueDisplay = valueDisplay.substring(0, 32) + "...";
      //     }
      //     return (
      //       <small>{valueDisplay}</small>
      //     )
      //   },
      // },
      {
        Header: "State",
        accessor: "state",
        key: "state",
      },
      {
        Header: "Phone",
        accessor: "phone",
        key: "phone",
      },
      {
        Header: "Fax",
        accessor: "fax",
        key: "fax",
      },
      {
        Header: "Email",
        accessor: "email",
        key: "email",
      },
      // {
      //   Header: "Initial Enrollment Email",
      //   accessor: "initialEnrollmentEmail",
      //   key: "initialEnrollmentEmail",
      // },
      {
        Header: "Used By",
        accessor: "othersUsedBy",
        key: "othersUsedBy",
        Cell: ({ value, row }) => (
          <AlsoUsedByDisplay alsoUsedBy={value} />
        ),
        Filter: SearchColumnFilter,
        filter: customOthersUsedByFilter,
      }
    ],
  []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        sortBy: [
          {
              id: 'isActive',
              desc: false
          }
        ]
      }
    },
    useFilters,
    useSortBy,
  );

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <h4 className="float-start card-title">All Carriers</h4>
        <Button className="float-end" variant="primary" size="sm" onClick={(e) => {handleShow()}}>+ Add New Carrier</Button>
        <br /><br />
      </Card.Header>
      <Table className="table-sm" striped bordered {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, style, ...restHeaderProps } = (column.id === "othersUsedBy" || column.id === "businessLines") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key} style={{...style, whiteSpace: (column.key !== "name" && column.key !== "othersUsedBy" && column.key !== "businessLines") ? "nowrap" : ""}}>
                      {column.render("Header")}
                      {(column.id !== "othersUsedBy" && column.id !== "businessLines") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : <></>
                      }
                      {
                        (column.key === "name" || column.key === "othersUsedBy") ? <>{column.render("Filter")}</>
                        :
                        <></>
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={8}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={8}>Loading...</td></tr>) : (
              rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                    <CarrierRow key={key} practiceId={practiceId} row={row} refreshCarrierList={refreshCarrierList}/>
                );
              })))
            }
          </tbody>
          <CarrierModal practiceId={practiceId} show={show} onHide={handleCancel} onSave={handleSave} />
        </Table>
    </Card>
  )
};


const CarrierManagement = () => {
  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "carrier/practices",
      successHandler: function(result) {
        if (result && result.length === 1)
        {
          // Skip the practice picker if there is only one practice
          setPracticeId(result[0].id);
          setPracticeName(result[0].name);
        }

        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, []);

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(parseInt(e.target.value));
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleSelectDifferentPractice = (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null)
  }

  const title = "Carrier Management" + (practiceName != null ? " | " + practiceName : "")

  return (
    <React.Fragment>
        <Helmet title={title} />
        <h1 className="h3 mb-3">{title}</h1>
        {practiceId > 0 && data.length > 1 ? <><Button variant="secondary" size="sm" onClick={(e) => {handleSelectDifferentPractice(e)}}>&lt; Select a different practice</Button><br /><br /></> : <></>}
        <Container fluid className="p-0">
          { practiceId > 0 ? <CarrierList practiceId={practiceId} /> : <SlimPracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
        </Container>
    </React.Fragment>
  )
};

export default CarrierManagement;
