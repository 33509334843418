import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import logo from "../../assets/img/logo_dark.png";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Sign In" />
    <div className="text-center mt-4" style={{marginBottom: '10px'}}>
      {/* <h2>H3 Elevate</h2> */}
      {/* <h4><i>Provider enrollment hub</i></h4> */}
      {/* <h2>Welcome back, Beka</h2> */}
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
          <p className="lead">Sign in to your account to continue</p>
          <br />
            <img
              src={logo}
              alt="H3"
              className="img-fluid"
              width="132"
              height="132"
            />
            <div style={{marginTop: '10px'}}>
              <h2>H3 Elevate</h2>
              <p><i>Bring your provider network to new heights</i></p>
            </div>
          </div>
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
