const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const getActivityDateTimeDisplay = (timestamp, compact=false, seconds=false, sortable=false) => {
    if (timestamp != null)
    {
      // Translate from UTC (returned by jackson) to local date time
      var lastActivityDateTime = new Date(timestamp);
      
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      
      var lastActivityDate;
      if (datesAreOnSameDay(lastActivityDateTime, new Date()))
      {
        lastActivityDate = "Today";
      }
      else if (datesAreOnSameDay(lastActivityDateTime, yesterday))
      {
        lastActivityDate = "Yesterday";
      }
      else
      {
        lastActivityDate = lastActivityDateTime.toLocaleDateString("en-US");
      }

      if (compact)
      {
        
        var lastActivityTime = lastActivityDateTime.toLocaleTimeString('en-US', {hour: 'numeric', hour12: false, minute:'2-digit'});
        return lastActivityDate + ' ' + lastActivityTime;
      }
      else if (seconds)
      {
        var lastActivityTime = lastActivityDateTime.toLocaleTimeString('en-US', {hour: 'numeric', minute:'2-digit', second: '2-digit'});
        return lastActivityDate + ' @ ' + lastActivityTime;
      }
      else if (sortable)
      {
        var lastActivityTime = lastActivityDateTime.toLocaleTimeString('en-US', {hour: 'numeric', minute:'2-digit'});
        return lastActivityDateTime.toISOString().slice(0, 19).replace('T', ' ');
      }
      
      var lastActivityTime = lastActivityDateTime.toLocaleTimeString('en-US', {hour: 'numeric', minute:'2-digit', timeZoneName: 'short'});
    
      return lastActivityDate + ' @ ' + lastActivityTime;
    }
  
    return '';
  }
  
  export default getActivityDateTimeDisplay;