import React, { useState, useEffect, useContext } from "react";
import { Table, Spinner, Form } from "react-bootstrap";
import authenticatedFetch from "./fetch";
import { ExternalLink} from "react-feather";
import NotyfContext from "../contexts/NotyfContext";


const AvailableEsignatureTemplate= ({ pathPrefix, id, name, isActive, onChange, canEdit }) => {
    const [localIsActive, setLocalIsActive] = useState(isActive);
    const [isDownloadingPreview, setIsDownloadingPreview] = useState(false)
  
    const notyf = useContext(NotyfContext);
  
    const handleIsActiveChanged = (e) => {
      setLocalIsActive(e.target.checked);
      onChange(id, e.target.checked);
    }
  
    const handlePreviewClicked = (e) => {
      e.preventDefault();
      setIsDownloadingPreview(true);
  
      authenticatedFetch({
        path:  pathPrefix + "/esignature/templates/" + id,
        successHandler: function(result) {
          for (var i = 0; i < result.length; i++)
          {
            window.open(result[i].url);
          }
          
          setIsDownloadingPreview(false);
        },
        errorHandler: function(error) {
          setIsDownloadingPreview(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
  }
  
    return (
      <tr>
        <td>
          <Form.Label><Form.Check checked={localIsActive} disabled={!canEdit} onChange={(e) => {handleIsActiveChanged(e)}} style={{display: "inline"}}/>&nbsp;&nbsp;{name.trim()}
          &nbsp;
          {isDownloadingPreview ?
            <Spinner animation="border" size="sm" variant="primary"/>
          :
            <a onClick={(e) => {handlePreviewClicked(e)}}><ExternalLink style={{'height': '16px', 'width': '16px', verticalAlign:"middle"}}/></a>
          }
          </Form.Label>
        </td>
      </tr>
    );
};

const AvailableESignatureTemplates = ({pathPrefix, canEdit, onESignatureTemplatesChanged, compact=false}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      authenticatedFetch({
        path: pathPrefix + "/esignature/templates",
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }, [])
    
    var templateList;
    if (error)
    {
      templateList = <tr><td colSpan="2">Error... please try again...</td></tr>;
    }
    else if (!isLoaded)
    {
      templateList = <tr><td colSpan="42">Loading...</td></tr>;
    }
    else if (!data || data.length === 0)
    {
      templateList = <tr><td colSpan="4">No templates found...</td></tr>;
    }
    else
    {
      templateList = data.map((template) => {
        return (
          <AvailableEsignatureTemplate key={template.id} pathPrefix={pathPrefix} id={template.id} name={template.name} isActive={false} onChange={onESignatureTemplatesChanged} canEdit={canEdit}/>
        )
      }, this);
    }
  
    return (
      <>
      <Table striped size={compact ? "sm" : ""} style={{width: compact ? "90%" : "", marginBottom: "5px"}}>
        <thead>
        {!compact &&
            <tr><td>Include Available E-Signature Template(s)</td></tr>
        }
        </thead>
        <tbody>
          {templateList}
        </tbody>
      </Table>
    </>
    )
  }
  

export default AvailableESignatureTemplates;
