import React, { useState, useEffect, useContext } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import {
  Search,
} from "react-feather";

import useSidebar from "../../hooks/useSidebar";

import authenticatedFetch from "../../utils/fetch"

import NavbarUser from "./NavbarUser";


const ProviderTinSearch = () => {
  const [data, setData] = useState([]);

  const { navbarRefresh } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "provider/searchlist",
      successHandler: function(result) {
        setData(result);
      }
    });
  }, [navbarRefresh])

  let optionList = [];
  if (data && data.length > 0) {
    optionList = data.map((searchItem, i) => {
      return (
        {id: searchItem.entityType + ":" + searchItem.id, label: "[" + searchItem.entityTypeLabel + "] " + searchItem.formattedName}
      )
    }, this);
  }

  return (
    <>
      <Typeahead
        style={{float: 'left', width: '350px'}}
        id="search-providers-navbar"
        onChange={(selected) => {
          if (selected[0].id.startsWith("practice"))
          {
            window.location = "/practice/" + selected[0].id.substring(9);
          }
          else
          {
            window.location = "/provider/" + selected[0].id.substring(9);
          }
        }}
        placeholder="Search practices and providers..."
        options={optionList}
        maxResults={5}
      />
      <Button variant="" style={{cursor: 'default'}}>
        <Search className="feather" />
      </Button>
    </>
  );
}

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();

  const { environment, isH3Admin, authorities } = useContext(UserSettingsContext);

  const isDev = (environment === "dev")

  return (
    <Navbar variant="light" expand className="navbar-bg" style={isDev ? {background : "#2196F3"} : {}}>
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      {/* Could ultimately implment a version of this that allows just provider/practice search, based on permissions */}
      {(isH3Admin || (
        (authorities.includes("PROVIDER_BASIC_VIEW") || authorities.includes("PROVIDER_BASIC_EDIT")) &&
        (authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
        )) ?
      <Form inline="true" className="d-none d-sm-inline-block" style={{width: '400px'}}>
        <InputGroup className="input-group-navbar">
          <ProviderTinSearch />
          
        </InputGroup>
      </Form>
      : <></>
      }
      
      {isDev?
      <h4>{environment}</h4>
      : <></>
      }
      <Navbar.Collapse>
        <Nav className="navbar-align">
          {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown>

          <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BellOff}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown> */}

          {/* <NavbarLanguages /> */}
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
