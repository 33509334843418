import React, {useContext} from "react";
import { Outlet } from "react-router-dom";
import { Navbar, Nav} from "react-bootstrap";

import Main from "../components/Main";
import NavbarUser from "../components/navbar/NavbarUser";
import Content from "../components/Content";
import Footer from "../components/Footer";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import logo from "../assets/img/logo_dark.png";

const H3Admin = ({ children }) => {
    const { environment } = useContext(UserSettingsContext);
    const isDev = (environment === "dev")

    return (
      <Main>
        <Navbar variant="light" expand className="navbar-bg" style={isDev ? {background : "#2196F3"} : {}}>
          <h2>        
            <img
            src={logo}
            alt="H3"
            className="img-fluid"
            width="28"
            height="28"
            style={{verticalAlign: "middle"}}
            />
            &nbsp;H3 Elevate | Admin Portal</h2>
          {isDev?
            <><h4>&nbsp;- {environment}</h4></>
            : <></>
            }
          <Nav className="navbar-align">
            <NavbarUser onRevenueTeamSelectionScreen={true} />
          </Nav>
        </Navbar>
        <Content>
          {children}
          <Outlet />
        </Content>
        <Footer />
      </Main>

)};

export default H3Admin;
