import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, Col, Row, Table, Container, Form, Button, Pagination, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import useSidebar from "../../hooks/useSidebar";
import Spinner from 'react-bootstrap/Spinner';

import authenticatedFetch from "../../utils/fetch"

import NotyfContext from "../../contexts/NotyfContext";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import getActivityDateTimeDisplay from "../../utils/datetime"

import SlimPracticePicker from "../../utils/SlimPracticePicker"
import rawReferralUrgencies from "../../utils/referralUrgencies"

import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExternalLink} from "react-feather";

import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";


const FaxModal = ({ show, onClose, onSaveAndNext, selectedFaxId }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);

  const [location, setLocation] = useState("");
  const [urgency, setUrgency] = useState("ROUTINE");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [referringPhysician, setReferringPhysician] = useState("");
  const [referringPractice, setReferringPractice] = useState("");

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setIsLoaded(false);
    setData([]);
    setLocation("");
    setUrgency("ROUTINE");
    setPatientFirstName("");
    setPatientLastName("");
    setReferringPhysician("");
    setReferringPractice("");

    if (selectedFaxId)
    {
      authenticatedFetch({
        path: "inbound_fax/details/" + selectedFaxId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
  }, [selectedFaxId]);

  const handleDiscard = (e) => {
    e.preventDefault();

    if (!saving && window.confirm("Are you sure you want to discard this fax?"))
    {
      setSaving(true);
      authenticatedFetch({
        path: "inbound_fax/discard/" + selectedFaxId,
        method: "PUT",
        successHandler: function(result) {
          setSaving(false);
          onSaveAndNext();
          notyf.open({type: "success", message: "Discarded!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function(error) {
          setSaving(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  const handleFullPreview = (e) => {
    e.preventDefault();
    if (isLoaded && data)
    {
      window.open(data.signedDownloadUrl, '_blank');
    }
  }

  const handleSaveAndNext = (e) => {
    e.preventDefault();

    if (saving)
    {
      return;
    }

    if (!urgency || urgency === "")
    {
      alert("Please select urgency");
      return;
    }

    if (!location || location === "")
    {
      alert("Please select a location");
      return;
    }

    if (!patientLastName || patientLastName === "")
    {
      alert("Please enter the patient's last name");
      return;
    }

    {
      setSaving(true);
      authenticatedFetch({
        path: "inbound_fax/" + selectedFaxId,
        method: "PUT",
        requestData: {
          location: location,
          urgency: urgency,
          patientFirstName: patientFirstName,
          patientLastName: patientLastName,
          referringPhysician: referringPhysician,
          referringPractice: referringPractice  // Temp sending string label
        },
        successHandler: function(result) {
          setSaving(false);
          onSaveAndNext();
          notyf.open({type: "success", message: "Saved! Now ready for scheduling.", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function(error) {
          setSaving(false);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }

  }

  let urgencyOptionList = rawReferralUrgencies.map((urgency, i) => {
    return (
      <option value={urgency.value} key={urgency.value}>{urgency.label}</option>
    )
  }, this);

  return (
      <Modal show={show} onHide={onClose} dialogClassName={"modal-1400"}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Inbound Fax | Quick Look {isLoaded && data != null && !error ? "| " + data.filename : ""}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Col md={9}>
            {!isLoaded ? <><Spinner animation="border" size="sm"/>&nbsp;&nbsp;Loading... </> : 
              (
                (error || !data) ? <>Something went wrong; please reload the page</> :
                <>
                <iframe src={data.signedDownloadUrl} style={{width: "700px", height: "500px" }} frameBorder="0"></iframe>
                <br /><br />
                </>
            )}
            </Col>
            <Col md={3}>
              <Table borderless>
                <tbody>
                  <tr><td><b>Urgency and Location:</b></td></tr>
                  <tr>
                    <td>
                    <Form.Select onChange={(e) => {setUrgency(e.target.value)}} value={urgency}>
                      {urgencyOptionList}
                    </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Select onChange={(e) => {setLocation(e.target.value)}} value={location}>
                        <option value="">Select a location...</option>
                        <option value="T-Tower">T-Tower</option>
                        <option value="ARH">ARH</option>
                        <option value="Mat-Su">Mat-Su</option>
                        <option value="Soldotna">Soldotna</option>
                        <option value="Homer">Homer</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr><td><b>Demographics:</b></td></tr>
                  <tr>
                    <td>
                      <Form.Control type="input" value={patientFirstName} onChange={(e) => {setPatientFirstName(e.target.value)}} placeholder="Patient First Name..." />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control type="input" value={patientLastName} onChange={(e) => {setPatientLastName(e.target.value)}} placeholder="Patient Last Name..."/>
                    </td>
                  </tr>
                  <tr><td><b>Referral Source:</b></td></tr>
                  <tr>
                    <td>
                      <Form.Select onChange={(e) => {setReferringPractice(e.target.value, e)}} value={referringPractice}>
                        <option value="">Select practice...</option>
                        <option value="Fairbanks Cardiology" >Fairbanks Cardiology</option>
                        <option value="Hillside Family Medicine">Hillside Family Medicine</option>
                        <option value="Mat-Su Family Medicine">Mat-Su Family Medicine</option>
                        <option value="The Alaska Hospitalist Group">The Alaska Hospitalist Group</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control type="input" value={referringPhysician} onChange={(e) => {setReferringPhysician(e.target.value)}} placeholder="Physician..."/>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1', marginTop: "-10px"}}>
            <Button className="me-auto btn-secondary" onClick={(e) => {handleDiscard(e)}}>Discard Fax</Button>
            <span className="float-start"><a href="#" onClick={(e) => {handleFullPreview(e)}}><ExternalLink style={{'height': '12px', 'width': '12px'}}/>&nbsp; Open Fax in New Tab</a></span>&nbsp;&nbsp;
            <Button onClick={onClose}>Close</Button>
            <Button onClick={(e) => {handleSaveAndNext(e)}}>Save &amp; Next</Button>
        </Modal.Footer>
      </Modal>
  );
}

const InboundFaxesPaginated = ({faxList, isLoaded, error, reloadInboundFaxes}) => {
  const [showFaxModal, setShowFaxModal] = useState(false);
  const [selectedFaxId, setSelectedFaxId] = useState(null);

  const handleFaxModalClose = () => setShowFaxModal(false);

  const data = faxList;

  const columns = React.useMemo(
    () => [
      // This UI only approach isn't working well - buggy across re-renders... would need the server to maintain view logs
      // {
      //   Header: "",
      //   accessor: "isNew",
      //   key: "isNew",
      //   Cell: ({ value, row }) => (
      //     row.original.isNew ? <Badge pill size="sm">New</Badge> : <>&nbsp;&nbsp;&nbsp;&nbsp;</>
      //   )
      // },
      {
        Header: "Received",
        accessor: "received",
        key: "received",
        Cell: ({ value, row }) => (
          getActivityDateTimeDisplay(value, false, true)
        )
      },
      {
        Header: "Filename",
        accessor: "filename",
        key: "filename",
      },
      {
        Header: "From",
        accessor: "fromNumber",
        key: "fromNumber"
      },
      {
        Header: "Sent To",
        accessor: "toNumber",
        key: "toNumber",
        // filter: customToNumberFilter,
        // Filter: ToNumberColumnFilter,
      }
    ],
  []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
              id: 'received',
              desc: true
          }
      ], },
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const handleFaxRowClicked = (e, original) => {
    e.preventDefault();
    setSelectedFaxId(original.key)
    setShowFaxModal(true);
  }

  const onSaveAndNext = () => {

    // Data is in descending order from the server
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === selectedFaxId) {
        if (i < data.length - 1) {
          setSelectedFaxId(data[i+1].key);
          reloadInboundFaxes();
          return;
        }
      }
    }

    // If we get here, we're at the end of the list, so close the modal
    setSelectedFaxId(null);
    setShowFaxModal(false);
    reloadInboundFaxes();
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
          <Card.Title>
          New Inbound Faxes
          </Card.Title>
        </Card.Header>
        <Table className="table-sm" striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = (column.key === "filename" || column.key === "isNew") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                      {(column.key !== "filename" && column.key !== "isNew") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : 
                      <></>
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={5}>Loading...</td></tr>) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr style={{fontFamily: "monospace", cursor:"pointer"}} onClick={(e) => {handleFaxRowClicked(e, row.original)}} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })))
            }
          </tbody>
        </Table>
        <br />
        <Row>
          <Col md="6">
            <span className="mx-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              min={1}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
        <FaxModal show={showFaxModal} onClose={handleFaxModalClose} onSaveAndNext={onSaveAndNext} selectedFaxId={selectedFaxId} />
    </Card>
  )
};

const PracticeInboundFaxes = ({practiceId}) => {
  // Handle state here so we can reload list after fax triaged
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const maxId = useRef(0);

  const { setNewFaxBadge, setPendingOutreachBadge } = useSidebar();

  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  useEffect(() => {
    reload();

    const refreshHistoryInterval = setInterval(() => reload(), 6000);
    return () => {
      // Equivalent to componentWillUnmount
      clearInterval(refreshHistoryInterval);
    };
  }, [practiceId])

  const reload = () => {
    authenticatedFetch({
      path: "inbound_fax/" + practiceId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "inbound_fax/new",
      successHandler: function(result) {
        setNewFaxBadge(result)
      }
    });

    if (isH3Admin || authorities.includes("REFERRAL_SCHEDULING_VIEW") || authorities.includes("REFERRAL_SCHEDULING_EDIT"))
    {
      authenticatedFetch({
        path: "referral/pendingoutreach",
        successHandler: function(result) {
          setPendingOutreachBadge(result)
        }
      });
    }
  }

  var faxList = [];
  if (isLoaded)
  {
    faxList = data.map((fax, i) => {
      var isNew = false;
      if (maxId.current > 0 && fax.id > maxId.current)
      {
        isNew = true;
      }
      
      return {key: fax.id, received: fax.received, toNumber: fax.toNumber, fromNumber:fax.fromNumber, fromCallerId:fax.fromCallerId, filename: fax.filename, isNew: isNew}
    }, this);

    for (var i = 0; i < faxList.length; i++)
    {
      if (faxList[i].key > maxId.current)
      {
        maxId.current = faxList[i].key;
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <InboundFaxesPaginated faxList={faxList} isLoaded={isLoaded} error={error} reloadInboundFaxes={reload} />
        </Col>
      </Row>
    </>
  )
};

const InboundFaxes = () => {
  const [practiceId, setPracticeId] = useState(0);
  const [practiceName, setPracticeName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "inbound_fax/practices",
      successHandler: function(result) {
        if (result && result.length === 1)
        {
          // Skip the practice picker if there is only one practice
          setPracticeId(result[0].id);
          setPracticeName(result[0].name);
        }
        else if (result)
        {
          for (var i = 0; i < result.length; i++)
          {
            if (result[i].id === 25)
            {
              // Demo company 25, short circuit to this practice for fewer clicks
              setPracticeId(result[i].id);
              setPracticeName(result[i].name);
              break;
            }
          }
        }

        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

  }, []);

  const onPracticeChanged = (e) => {
    e.preventDefault();
    setPracticeId(e.target.value);
    setPracticeName(e.target.options[e.target.selectedIndex].text)
  }

  const handleSelectDifferentPractice = (e) => {
    e.preventDefault();
    setPracticeId(0);
    setPracticeName(null)
  }

  const handleTempAddFaxButton = (e) => {
    authenticatedFetch({
      path: "inbound_fax/tempfax/" + practiceId,
      method: 'POST',
      errorHandler: function(error) {
        alert("Something went wrong: " + error);
      }
    });
  }

  const title = "Inbound Faxes" + (practiceName != null ? " | " + practiceName : "")

  return (
    <React.Fragment>
        <Helmet title={title} />
        <h1 className="h3 mb-3">{title}</h1>
        {practiceId > 0 && data.length > 1 ? <><Button variant="secondary" size="sm" onClick={(e) => {handleSelectDifferentPractice(e)}}>&lt; Select a different practice</Button><br /><br /></> : <></>}
        <Container fluid className="p-0">
          { practiceId > 0 ? <PracticeInboundFaxes practiceId={practiceId} /> : <SlimPracticePicker isLoaded={isLoaded} error={error} data={data} onPracticeChanged={onPracticeChanged} /> }    
        </Container>
        {isH3Admin && practiceId > 0 && <Button variant="primary" size="sm" className="float-end"  onClick={(e) => {handleTempAddFaxButton(e)}}>+</Button>}
    </React.Fragment>
  )
};

export default InboundFaxes;
