
import { Button, Modal } from "react-bootstrap";


const ConfirmationModal = ({ show, title, subject, message, onCancel, onConfirm}) => {

    return (
        <Modal show={show} onHide={onCancel}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>{title}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><b>Subject:</b>&nbsp;{subject}</p>
            <p><b>Message:</b></p>
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button className="btn-secondary" onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>OK</Button>
          </Modal.Footer>
        </Modal>
    );
  }
  
  
  export default ConfirmationModal;