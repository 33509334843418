import { ButtonGroup, ToggleButton } from "react-bootstrap";

const ReportFormatSelect = ({ selectedReportFormat, handleReportFormatChanged}) => {
    return (
        <ButtonGroup className="me-auto">
            <ToggleButton
                key="CSV"
                id="CSV"
                type="radio"
                variant="outline-primary"
                style={{color: selectedReportFormat === "CSV" ? "white" : ""}}
                value="CSV"
                checked={selectedReportFormat === "CSV"}
                onChange={(e) => {handleReportFormatChanged(e.target.value)}}
            >.csv</ToggleButton>
            <ToggleButton
                key="PDF"
                id="PDF"
                type="radio"
                variant="outline-primary"
                style={{color: selectedReportFormat === "PDF" ? "white" : ""}}
                value="PDF"
                checked={selectedReportFormat === "PDF"}
                onChange={(e) => {handleReportFormatChanged(e.target.value)}}
            >.pdf</ToggleButton>
        </ButtonGroup>
        
    )
  }

  export default ReportFormatSelect;