import React, { useState, useEffect } from "react";
import { Card,} from "react-bootstrap";

import authenticatedFetch from "../utils/fetch"

import Task from "./Task";

const OpenTasks = ({entityType, entityId}) => {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      authenticatedFetch({
        path: "task/open/" + entityType + "/" + entityId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function() {
          setIsLoaded(true);
          setError(true);
        }
      });
    }, [entityType, entityId])
  
    if (!isLoaded)
    {
      return (<Card><Card.Body>Loading...</Card.Body></Card>)
    }
    else if (error || !data)
    {
      return (<Card><Card.Body>Something went wrong... please reload the page...</Card.Body></Card>)
    }
    else
    {
      var taskList = data.length > 0 && data.map((task, i) => {
        return (
          <Task
            key={task.id}
            task={task}
            showProgress={false}
            hideProviderName={entityType === "provider"}
            hidePracticeName={entityType === "practice"}
            shortLastActivity={entityType === "practice"}
            allowModal={false}
          />
        )
  
      }, this);

      if (!taskList)
      {
        return <></>
      }
  
      return (
        <Card>
          <Card.Body>
            {taskList}
          </Card.Body>
        </Card>
      )
    }
}

export default OpenTasks;
