import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Form} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import { X, Check, Edit2, Trash2} from "react-feather";

import { useTable, useSortBy, useFilters} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import authenticatedFetch from "../utils/fetch"

import EditableExpires from "./EditableExpires";
import UsStateSelect from "./UsStateSelect";

import rawStateLicenseTypes from "./stateLicenseTypes"

const StateLicense = ({ id, providerId, state, stateLicenseType, effectiveDate, expirationDate, license, reminderSent, refreshList, canEdit, onCancelEdit, startInEditMode=false }) => {
  const [editing, setEditing] = useState(startInEditMode);
  const [localState, setLocalState] = useState(state);
  const [localStateLicenseType, setLocalStateLicenseType] = useState(stateLicenseType);
  const [localLicense, setLocalLicense] = useState(license);
  const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);
  const [localExpirationDate, setLocalExpirationDate] = useState(expirationDate);
  
  const notyf = useContext(NotyfContext);


  function handleDeleteClicked(e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?"))
    {
      authenticatedFetch({
        method: "DELETE",
        path: "statelicense/" + id,
        successHandler: function(result) {
          refreshList();
        },
        errorHandler: function(error) {
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }

  function handleEditClicked(e) {
    e.preventDefault();
    setEditing(true);
  }

  function cancelEdit(e) {
    e.preventDefault();
    setEditing(false);
    setLocalState(state);
    setLocalStateLicenseType(stateLicenseType);
    setLocalLicense(license);
    setLocalEffectiveDate(effectiveDate);
    setLocalExpirationDate(expirationDate);

    if (onCancelEdit)
    {
      onCancelEdit();
    }
  }

  function saveEdit(e) {
    e.preventDefault();
    if (localState === '' || localLicense === '')
    {
      alert("Please enter a state and license");
      return;
    }

    setEditing(false);

    if (state !== localState || stateLicenseType !== localStateLicenseType || license !== localLicense || effectiveDate !== localEffectiveDate || expirationDate !== localExpirationDate)
    {
      authenticatedFetch({
        method: id ? "PUT" : "POST",
        requestData: {providerId: parseInt(providerId), state: localState, stateLicenseType: localStateLicenseType, license: localLicense, effectiveDate: localEffectiveDate, expirationDate: localExpirationDate},
        path: id ? "statelicense/" + id  : "statelicense",
        successHandler: function(result) {
          refreshList();
          notyf.open({type: "success", message: id ? "Updated!" : "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setEditing(true);
          setLocalState(state);
          setLocalStateLicenseType(stateLicenseType);
          setLocalEffectiveDate(effectiveDate);
          setLocalExpirationDate(expirationDate);
          setLocalLicense(license);
          notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
        }
      });
    }
  }


  function handleStateChanged(name, newValue) {
    setLocalState(newValue);
  }
  
  function handleStateLicenseTypeChanged(e) {
    e.preventDefault();
    setLocalStateLicenseType(e.target.value);
  }

  function handleLicenseChanged(e) {
    e.preventDefault();
    setLocalLicense(e.target.value);
  }

  function handleEffectiveDateChanged(val, e) {
    e.preventDefault();
    setLocalEffectiveDate(val);
  }

  function handleExpirationDateChanged(val, e) {
    e.preventDefault();
    setLocalExpirationDate(val);
  }

  var reminderSentDisplay = '';
  if (reminderSent != null)
  {
    var parts = reminderSent.split("-");
    const reminderSentDateObj = new Date(parts[0], parts[1] - 1, parts[2]);  // Avoid the UTC->local timezone translation issue with js
    reminderSentDisplay = new Date(reminderSentDateObj).toLocaleDateString("en-US");
  }

  let stateLicenseTypeList = rawStateLicenseTypes.map((stateLicenseType, i) => {
    return (
      <option key={i} value={stateLicenseType.value}>{stateLicenseType.label}</option>
    )
  }, this);

  var selectedStateLicenseTypeLabel = '';
  if (localStateLicenseType !== '')
  {
      for (let i = 0; i < rawStateLicenseTypes.length; i++)
      {
          if (localStateLicenseType === rawStateLicenseTypes[i].value)
          {
            selectedStateLicenseTypeLabel = rawStateLicenseTypes[i].label;
            break;
          }
      }
  }

  return (
    <>
    {editing ?
    <td>
      <UsStateSelect originalValue={localState} name="licenseState" onValueChanged={handleStateChanged}/>
    </td>
    :
    <td>
      {localState}
    </td>
    }
    {editing ?
    <td>
      <Form.Select size="sm" value={localStateLicenseType} onChange={(e) => {handleStateLicenseTypeChanged(e)}} style={{width: "auto"}} >
          {stateLicenseTypeList}
      </Form.Select>
    </td>
      :
      <td>
        {selectedStateLicenseTypeLabel}
      </td>
    }
    {editing ?
    <td>
      <Form.Control size="sm" maxLength={255} value={localLicense} onChange={(e) => {handleLicenseChanged(e)}} />
    </td>
    :
    <td>
      {localLicense}
    </td>
    }
    <td>
    <EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleEffectiveDateChanged} />
    </td>
    <td>
    <EditableExpires localExpires={localExpirationDate} localActive={true} editingExpires={editing} handleExpiresChanged={handleExpirationDateChanged} />
    </td>
    <td>{reminderSentDisplay}</td>
    <td style={{width: '70px'}}>
      { canEdit?
        <>
        {editing ? 
        <>
          <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEdit(e)}} />
          &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveEdit(e)}} className="text-success"/>
        </>
        :
        <>
          <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditClicked(e)}}/>&nbsp;&nbsp;
          <Trash2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleDeleteClicked(e)}}/>
        </>
        }
        </>
        : <>&nbsp;</>
      }
    </td>
  </>
  )
}


const StateLicensesTable = ({providerId, addingNew, onCancelAddNew, list, isLoaded, error, canEdit, refreshList}) => {
  const data = list;

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        key: "id",
      },
      {
        Header: "State",
        accessor: "state",
        key: "state",
      },
      {
        Header: "Type",
        accessor: "stateLicenseType",
        key: "stateLicenseType",
      },
      {
        Header: "License",
        accessor: "license",
        key: "license",
      },
      {
        Header: "Issue Date",
        accessor: "effectiveDate",
        key: "effectiveDate",
      },
      {
        Header: "Expiration Date",
        accessor: "expirationDate",
        key: "expirationDate",
      },
      {
        Header: "Reminder Sent",
        accessor: "reminderSent",
        key: "reminderSent",
      },
      {
        Header: "",
        accessor: "icons",
        key: "icons",
      },
    ],
  []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: ['id'],
        sortBy: [
            {
                id: 'state',
                desc: false
            }
        ],
      }
    },
    useFilters,
    useSortBy,
  );

  return (
    <Table className="table-sm" striped bordered hover {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const { key, ...restHeaderProps } = (column.id === "icons" || column.id === "reminderSent") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
            return (
              <th {...restHeaderProps} key={key}>
                {column.render("Header")}
                {(column.id !== "icons" && column.id !== "reminderSent") ? (
                  <>
                  {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                  )}
                  </>
                ) : <></>
                }
              </th>
            )
          }
          )}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {
      error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
        !isLoaded ? (<tr><td colSpan={6}>Loading...</td></tr>) : (
          rows.map((row) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr {...restRowProps} key={key}>
              <StateLicense providerId={providerId} id={row.values.id} key={row.values.id} state={row.values.state ? row.values.state : ""} stateLicenseType={row.values.stateLicenseType ? row.values.stateLicenseType : ""} license={row.values.license ? row.values.license : ""} effectiveDate={row.values.effectiveDate} expirationDate={row.values.expirationDate} reminderSent={row.values.reminderSent} refreshList={refreshList} canEdit={canEdit}/>
            </tr>
          );
        })))
      }
      {
      addingNew ?
        <tr><StateLicense providerId={providerId} key={0} startInEditMode={true} state={""} stateLicenseType={""} license={""}  effectiveDate={null} expirationDate={null} onCancelEdit={onCancelAddNew} canEdit={canEdit} refreshList={refreshList}/></tr>
      : <></>
      }
    </tbody>
  </Table>
  )
}

const StateLicenses = ({providerId, canEdit}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [addingNew, setAddingNew] = useState(false);

  useEffect(() => {
    refreshList();
  }, [providerId])

  function refreshList()
  {
    setAddingNew(false);
    authenticatedFetch({
      path: "statelicense/provider/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function() {
        setIsLoaded(true);
        setError(true);
      }
    });
  }

  const handleAddNewClicked = (e) => {
    e.preventDefault();
  
    setAddingNew(true);
  }

  const onCancelAddNew = () => {
    setAddingNew(false);
  }

  var list = [];
  if (isLoaded)
  {
    list = data.map((item, i) => {
      return {key: item.id, id: item.id, state: item.state, stateLicenseType: item.stateLicenseType, license: item.license, effectiveDate: item.effectiveDate, expirationDate: item.expirationDate, updated: item.updated, updatedBy: item.updatedBy, reminderSent: item.secondEmailReminderSent != null ? item.secondEmailReminderSent : item.firstEmailReminderSent}
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-20px'}}>
        <Card.Title>State Licenses
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <StateLicensesTable list={list} addingNew={addingNew} onCancelAddNew={onCancelAddNew} providerId={providerId} error={error} isLoaded={isLoaded} canEdit={canEdit} refreshList={refreshList}/>
        {canEdit?
          <span className="float-end" style={{marginTop: '6px'}}>
            <Button size="sm" variant="primary" className="float-end" onClick={(e) => {handleAddNewClicked(e)}}>+ Add New State License</Button>
          </span>
          : <></>
          }
      </Card.Body>
    </Card>
  )
}

export default StateLicenses;
