import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";

import NotyfContext from "../contexts/NotyfContext";

import {Check, X, Edit2} from "react-feather";

import CopyToClipboardToggle from "./CopyToClipboardToggle";


const CredentialRow = ({ label, data, name, onNewValue, onCopyValue, mostRecentlyCopied, canEdit, obfuscate=false, copyable=true, inputType="input" }) => {
    const [editing, setEditing] = useState(false);
  
    var originalValue = data[name] == null ? "" : data[name];
    const [localValue, setLocalValue] = useState(originalValue);
    const handleEditClicked = () => setEditing(true);
  
    const notyf = useContext(NotyfContext);
  
    const cancelEdit = () => {
      setEditing(false);
      setLocalValue(originalValue);
    }
  
    const saveEdit = () => {
      setEditing(false);
      if (localValue !== originalValue)
      {
        onNewValue(name, localValue);
      }
      
      // TODO this is showing even in error case... need onNewValueToReturn success code
      notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
    }
  
    const onLocalValueChanged = (localValue) => {
      setLocalValue(localValue);
    }
  
    return (
      <tr>
        <td style={{width: "40%"}}>
          <Form.Label>{label}&nbsp;&nbsp;
            {editing || !canEdit ? <></>
              : <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={() => {handleEditClicked()}}/>}
          </Form.Label>
        </td>
        <td style={{width: "60%"}}>
          {editing ? 
          <>
          <Form.Control autoFocus={true} style={{width: "80%", display: "inline"}} as={inputType} value={localValue} onChange={(e) => {onLocalValueChanged(e.target.value)}}/>&nbsp;&nbsp;
          <X cursor="pointer" style={{'height': '16px', 'width': '16px'}} onClick={() => {cancelEdit()}}/>&nbsp;<Check cursor="pointer" style={{'height': '16px', 'width': '16px'}} onClick={() => {saveEdit()}} className="text-success"/></>
            : (obfuscate ? "*".repeat(localValue.length) : localValue)}
            &nbsp;&nbsp;
            {copyable && !editing && localValue !== "" ? <CopyToClipboardToggle name={name} onCopyValue={onCopyValue} mostRecentlyCopied={mostRecentlyCopied} text={localValue}/> : ""}</td>
      </tr>
    );
  }

export default CredentialRow;