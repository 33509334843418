const getAvailableCarrierSubmissionActionTypeDisplay = (availableCarrierSubmissionActionType) => {
    if (!availableCarrierSubmissionActionType)
    {
        return "";
    }
    if (availableCarrierSubmissionActionType === "EMAIL")
    {
        return "Send Automated Email";
    }
    if (availableCarrierSubmissionActionType === "WEB_FORM")
    {
        return "Auto-Submit Form";
    }
    if (availableCarrierSubmissionActionType === "FAX")
    {
        return "Send Automated Fax";
    }

    return "";
}

export default getAvailableCarrierSubmissionActionTypeDisplay;
