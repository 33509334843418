import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Table, Spinner, Form, Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import authenticatedFetch from "../../utils/fetch"
import { Trash2, ExternalLink} from "react-feather";
import EditableExpires from "../../utils/EditableExpires";
import NotyfContext from "../../contexts/NotyfContext";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import InputMask from "react-input-mask";
import AvailableESignatureTemplates from "../../utils/AvailableESignatureTemplates";
import { set } from "firebase/database";


const ProviderBasicFields = ({npi, setNpi, firstName, setFirstName, middleName, setMiddletName, lastName, setLastName, credentials, setCredentials, email, setEmail, sendOnboardingForms}) => {
  const handleNpiChanged = (value) => {
    setNpi(value.replace(/_/g, ''));
  }

  if (email == null)
  {
    email = "";
  }
  if (firstName == null)
  {
    firstName = "";
  }
  if (middleName == null)
  {
    middleName = "";
  }
  if (lastName == null)
  {
    lastName = "";
  }
  if (npi == null)
  {
    npi = "";
  }
  if (credentials == null)
  {
    credentials = "";
  }
  

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Personal Details
        </Card.Title>
      </Card.Header>
      <Card.Body>
    <Table>
      <tbody>
        <tr>
          <td style={{width: "160px"}}>Personal Email{sendOnboardingForms ? "*" : ""}:</td>
          <td><Form.Control type="input" value={email} onChange={(e) => {setEmail(e.target.value)}}/></td>
        </tr>
        <tr>
          <td>First Name*:</td>
          <td><Form.Control type="input" value={firstName} onChange={(e) => {setFirstName(e.target.value)}}/></td>
        </tr>
        <tr>
          <td>Middle Name:</td>
          <td><Form.Control type="input" value={middleName} onChange={(e) => {setMiddletName(e.target.value)}}/></td>
        </tr>
        <tr>
          <td>Last Name*:</td>
          <td><Form.Control type="input" value={lastName} onChange={(e) => {setLastName(e.target.value)}}/></td>
        </tr>
        <tr>
          <td>NPI:</td>
          <td>
            <InputMask
              mask="9999999999"
              value={npi} 
              onChange={(e) => {handleNpiChanged(e.target.value)}} >
              {(inputProps) => <Form.Control {...inputProps} type="text" />}
          </InputMask>
          </td>
        </tr>
        <tr>
          <td>Credentials:</td>
          <td><Form.Control type="input" value={credentials} onChange={(e) => {setCredentials(e.target.value)}}/></td>
        </tr>
      </tbody>
    </Table>
    </Card.Body>
    <br />
  </Card>
  )
}

const AvailableReminderPreference = ({ id, name, isActive, onChange, canEdit }) => {
  const [localIsActive, setLocalIsActive] = useState(isActive);

  const handleIsActiveChanged = (e) => {
    setLocalIsActive(e.target.checked);
    onChange(id, e.target.checked);
  }

  return (
    <tr>
        <td>{name}</td>
        <td><Form.Check checked={localIsActive} disabled={!canEdit} onChange={(e) => {handleIsActiveChanged(e)}} /></td>
    </tr>
  );
}

const ProviderReminderPreferenceAssignment = ({providerId, onReminderPreferenceChanged, canEdit, refresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: providerId && providerId > 0 ? "admin/provider/reminderpreferences/" + providerId : "admin/provider/reminderpreferences/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);

        for (var i = 0; i < result.length; i++)
        {
          onReminderPreferenceChanged(result[i].id, result[i].isActive);
        }
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [providerId, refresh])

  var preferenceList;

  if (error)
  {
    preferenceList = <tr><td colSpan="4">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    preferenceList = <tr><td colSpan="4">Loading...</td></tr>;
  }
  else if (!data || data.length === 0)
  {
    preferenceList = <tr><td colSpan="4">No hubs found...</td></tr>;
  }
  else
  {
    preferenceList = data.map((preference) => {
      return (
        <AvailableReminderPreference key={preference.id} id={preference.id} name={preference.name} isActive={preference.isActive} onChange={onReminderPreferenceChanged} canEdit={canEdit}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Reminder Preferences
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Name</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {preferenceList}
          </tbody>
        </Table>
      </Card.Body>
  </Card>
  )
}


const ProviderOnboarding = ({sendOnboardingForms, setSendOnboardingForms, onESignatureTemplatesChanged, canEdit}) => {
  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Onboarding
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Label><Form.Check type="switch" checked={sendOnboardingForms} style={{display: "inline"}}  onChange={(e) => {setSendOnboardingForms(e.target.checked)}}/>&nbsp;&nbsp;Send Onboarding Forms</Form.Label>
        <br />
        {sendOnboardingForms && <><AvailableESignatureTemplates pathPrefix="admin/provider" onESignatureTemplatesChanged={onESignatureTemplatesChanged} canEdit={canEdit}/><br /></>}
      </Card.Body>
  </Card>
  )
}


const AvailableHub= ({ id, name, isActive, onChange, canEdit }) => {
  const [localIsActive, setLocalIsActive] = useState(isActive);

  const handleIsActiveChanged = (e) => {
    setLocalIsActive(e.target.checked);
    onChange(id, e.target.checked);
  }

  return (
    <tr>
        <td>{name}</td>
        <td><Form.Check checked={localIsActive} disabled={!canEdit} onChange={(e) => {handleIsActiveChanged(e)}} />  </td>
        <td>{isActive && !localIsActive && <span className="text-warning" style={{fontSize: '0.85em'}}>Provider's portal login credentials will also be deleted!</span>}</td>
    </tr>
  );
}

const ProviderHubAssignment = ({providerId, onHubChanged, canEdit, refresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: providerId && providerId > 0 ? "admin/provider/hubs/" + providerId : "admin/provider/hubs/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);

        for (var i = 0; i < result.length; i++)
        {
          onHubChanged(result[i].id, result[i].isActive);
        }
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [providerId, refresh])

  var hubList;

  if (error)
  {
    hubList = <tr><td colSpan="4">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    hubList = <tr><td colSpan="4">Loading...</td></tr>;
  }
  else if (!data || data.length === 0)
  {
    hubList = <tr><td colSpan="4">No hubs found...</td></tr>;
  }
  else
  {
    hubList = data.map((hub) => {
      return (
        <AvailableHub key={hub.id} id={hub.id} name={hub.name} isActive={hub.isActive} onChange={onHubChanged} canEdit={canEdit}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Data Hubs
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Name</td>
              <td>Active</td>
              <td style={{width: '150px'}}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {hubList}
          </tbody>
        </Table>
      </Card.Body>
  </Card>
  )
}

const ProviderOnboardingUrl = ({providerId, sendOnboardingForms, setSendOnboardingForms, onESignatureTemplatesChanged, canEdit, refresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    authenticatedFetch({
      path: "admin/provider/onboarding/url/" + providerId,
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [providerId, refresh])

  if (canEdit && !error && isLoaded && (!data || !data.url))
  {
    return (
      <>
        <ProviderOnboarding sendOnboardingForms={sendOnboardingForms} setSendOnboardingForms={setSendOnboardingForms} onESignatureTemplatesChanged={onESignatureTemplatesChanged} canEdit={true}/>
      </>
    )
  }

  var url;

  if (error)
  {
    url = <tr><td>Error... please reload the page...</td></tr>;
  }
  else if (!isLoaded)
  {
    url = <tr><td>Loading...</td></tr>;
  }
  else if (!data || !data.url)
  {
      // No edit access
      url= <tr><td>No active onboarding forms</td></tr>;
  }
  else
  {
    url = <tr><td><a href={data.url} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/>&nbsp;Active onboarding forms</a><br /><br /></td></tr>;
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Onboarding
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table>
          <tbody>
            {url}
          </tbody>
        </Table>
      </Card.Body>
  </Card>
  )
}

const AvailablePractice = ({ id, name, isMember, isPrimary, startDate, endDate, existingProvider, onChange, canEdit }) => {
  const [localIsMember, setLocalIsMember] = useState(isMember);
  const [localIsPrimary, setLocalIsPrimary] = useState(isPrimary);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const handleIsMemberChanged = (e) => {
    // Prevent default here actually prevents the box from checking/unchecking

    if (existingProvider && isMember && !e.target.checked)
    {
      if (!window.confirm("Removing this provider's membership from " + name + " will delete any carrier and business line participation data for this TIN + NPI; any associated active tasks will be archived as well (upon clicking 'Save').  Are you sure you want continue?"))
      {
        return;
      }

      // Make it clear that we're wiping expires too (since server actually deletes this row)
      setLocalStartDate(null);
      setLocalEndDate(null);
      setLocalIsPrimary(false);
      
    }

    setLocalIsMember(e.target.checked);
    onChange(id, e.target.checked, localIsPrimary, localStartDate, localEndDate);
  }

  const handleIsPrimaryChanged = (e) => {
    setLocalIsPrimary(e.target.checked);
    onChange(id, localIsMember, e.target.checked, localStartDate, localEndDate);
  }

  const handleStartDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalStartDate(date);
    onChange(id, localIsMember, localIsPrimary, date, localEndDate);
  }

  const handleEndDateChanged = (date, e) => {
    e.preventDefault();
  
    // If they clear it out, date picker sends it back as "", not null
    if (date === "")
    {
      date = null;
    }

    setLocalEndDate(date);
    onChange(id, localIsMember, localIsPrimary, localStartDate, date);
  }

  return (
    <tr>
        <td>{name}</td>
        <td><Form.Check checked={localIsMember} disabled={!canEdit} onChange={(e) => {handleIsMemberChanged(e)}} /></td>
        <td><Form.Check disabled={!canEdit || !localIsMember} type="checkbox" checked={localIsPrimary} onChange={(e) => {handleIsPrimaryChanged(e)}} /></td>
        <td style={{minWidth: '160px', height: '70px'}}><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={localStartDate} localActive={localIsMember} editingExpires={canEdit} handleExpiresChanged={handleStartDateChanged} /></td>
        {existingProvider &&
        <td ><EditableExpires display="inline" size="lg" width="140px" warnWithColors={false} localExpires={localEndDate} localActive={localIsMember} editingExpires={canEdit} handleExpiresChanged={handleEndDateChanged} /></td>
        }
    </tr>
  );
}

const ProviderPracticeAssignment = ({providerId, onPracticeChanged, canEdit, refresh}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const existingProvider = providerId > 0;

  useEffect(() => {
    authenticatedFetch({
      path: providerId && providerId > 0 ? "admin/provider/practices/" + providerId : "admin/provider/practices/",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);

        for (var i = 0; i < result.length; i++)
        {
          onPracticeChanged(result[i].id, result[i].isMember, result[i].isPrimary, result[i].startDate, result[i].endDate);
        }
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [providerId, refresh])

  var practiceList;

  if (error)
  {
    practiceList = <tr><td colSpan="4">Error... please try again...</td></tr>;
  }
  else if (!isLoaded)
  {
    practiceList = <tr><td colSpan="4">Loading...</td></tr>;
  }
  else if (!data || data.length === 0)
  {
    practiceList = <tr><td colSpan="4">No practices found...</td></tr>;
  }
  else
  {
    practiceList = data.map((practice) => {
      return (
        <AvailablePractice key={practice.id} id={practice.id} name={practice.name} isMember={practice.isMember} isPrimary={practice.isPrimary} startDate={practice.startDate} endDate={practice.endDate} existingProvider={existingProvider} onChange={onPracticeChanged} canEdit={canEdit}/>
      )
    }, this);
  }

  return (
    <Card>
      <Card.Header style={{marginBottom: '-15px'}}>
        <Card.Title>
        Practice Associations
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <td>Name</td>
              <td>Member</td>
              <td>Primary</td>
              <td>Start Date</td>
              {existingProvider &&
              <td>End Date</td>
              }
            </tr>
          </thead>
          <tbody>
            {practiceList}
          </tbody>
        </Table>
      </Card.Body>
  </Card>
  )
}

const ProviderCreation = ({onCancel, onSuccess}) => {
  const [npi, setNpi] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddletName] = useState("");
  const [lastName, setLastName] = useState("");
  const [credentials, setCredentials] = useState("");
  const [email, setEmail] = useState("");
  const [sendOnboardingForms, setSendOnboardingForms] = useState(false);

  const [localHubData, setLocalHubData] = useState({})
  const [localReminderPreferenceData, setLocalReminderPreferenceData] = useState({})
  const [localPracticeData, setLocalPracticeData] = useState({});
  const [localESignatureTemplates, setLocalESignatureTemplates] = useState({});

  const[isSaving, setIsSaving] = useState(false);

  const notyf = useContext(NotyfContext);

  const onHubChanged = (hubId, isActive) => {
    localHubData[hubId] = isActive;
    setLocalHubData(localHubData);
  }

  const onReminderPreferenceChanged = (reminderPreferenceId, isActive) => {
    localReminderPreferenceData[reminderPreferenceId] = isActive;
    setLocalReminderPreferenceData(localReminderPreferenceData);
  }

  const onPracticeChanged =  (practiceId, isMember, isPrimary, startDate, endDate) => {
    localPracticeData[practiceId] = {isMember: isMember, isPrimary: isPrimary, startDate: startDate, endDate: endDate};
    setLocalPracticeData(localPracticeData);
  }

  const onESignatureTemplatesChanged = (templateId, isIncluded) => {
    localESignatureTemplates[templateId] = isIncluded;
    setLocalESignatureTemplates(localESignatureTemplates);
  }

  const handleSave = (e) => {
    e.preventDefault();

    if (isSaving)
    {
      return;
    }

    if (!firstName || firstName.length === 0 || !lastName || lastName.length === 0 || (sendOnboardingForms && (!email || email.length === 0)))
    {
      if (sendOnboardingForms)
      {
        alert("First name, last name, and email are required to add a new provider to Elevate.")
      }
      else
      {
        alert("First name and last name are required to add a new provider to Elevate.")
      }

      
      return;
    }

    var practiceMembership = false;
    for (var practiceId in localPracticeData)
    {
      if (localPracticeData[practiceId] && localPracticeData[practiceId].isMember)
      {
        practiceMembership = true;
        break;
      }
    }

    if (!practiceMembership)
    {
      alert("At least one practice must be selected for membership.")
      return;
    }    

    setIsSaving(true);

    authenticatedFetch({
      method: "POST",
      path:  "admin/provider/",
      requestData: {
        npi: npi,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        credentials: credentials,
        email: email,
        sendOnboardingForms: sendOnboardingForms,
        dataHubs: localHubData,
        reminderPreferences: localReminderPreferenceData,
        practiceAssociations: localPracticeData,
        includedESignatureTemplates: localESignatureTemplates,
      },
      successHandler: function(result) {
        setIsSaving(false);
        notyf.open({type: "success", message: "Provider created!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        onSuccess();
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
    <>
      <Row>
        <Col lg="6">
          <ProviderBasicFields npi={npi} setNpi={setNpi} firstName={firstName} setFirstName={setFirstName} middleName={middleName} setMiddletName={setMiddletName} lastName={lastName} setLastName={setLastName} credentials={credentials} setCredentials={setCredentials} email={email} setEmail={setEmail} sendOnboardingForms={sendOnboardingForms} />
        </Col>
        <Col lg="6">
          <ProviderOnboarding sendOnboardingForms={sendOnboardingForms} setSendOnboardingForms={setSendOnboardingForms} onESignatureTemplatesChanged={onESignatureTemplatesChanged} canEdit={true}/>
        </Col>
      </Row>
      <Row>
        <Col lg="5">
          <ProviderHubAssignment onHubChanged={onHubChanged} canEdit={true}/>
          <ProviderReminderPreferenceAssignment onReminderPreferenceChanged={onReminderPreferenceChanged} canEdit={true}/>
        </Col>
        <Col lg="7">
          <ProviderPracticeAssignment onPracticeChanged={onPracticeChanged} canEdit={true}/>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg="12">
          { isSaving ?
            <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/>
          :
          <>
            <Button onClick={(e) => {handleSave(e)}}>Add</Button>
            &nbsp;&nbsp;
            <Button variant="secondary" onClick={(e) => {onCancel(e)}}>Cancel</Button>
          </>
          }
        </Col>
      </Row>
    </>
  )
};

const ProviderUpdate = ({providerId, onCancel, canEdit, onRemove}) => {
  
  const[isSaving, setIsSaving] = useState(false);

  // Have to duplicate this to get the state up high enough
  const [localHubData, setLocalHubData] = useState({})
  const [localReminderPreferenceData, setLocalReminderPreferenceData] = useState({});
  const [localPracticeData, setLocalPracticeData] = useState({});
  const [localESignatureTemplates, setLocalESignatureTemplates] = useState({});
  const [sendOnboardingForms, setSendOnboardingForms] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(0);

  const notyf = useContext(NotyfContext);

  const onHubChanged = (hubId, isActive) => {
    localHubData[hubId] = isActive;
    setLocalHubData(localHubData);
  }

  const onReminderPreferenceChanged = (reminderPreferenceId, isActive) => {
    localReminderPreferenceData[reminderPreferenceId] = isActive;
    setLocalReminderPreferenceData(localReminderPreferenceData);
  }

  const onPracticeChanged =  (practiceId, isMember, isPrimary, startDate, endDate) => {
    localPracticeData[practiceId] = {isMember: isMember, isPrimary: isPrimary, startDate: startDate, endDate: endDate};
    setLocalPracticeData(localPracticeData);
  }

  const onESignatureTemplatesChanged = (templateId, isIncluded) => {
    localESignatureTemplates[templateId] = isIncluded;
    setLocalESignatureTemplates(localESignatureTemplates);
  }

  const handleSave = (e) => {
    e.preventDefault();

    // Check for end-dated practice(s) case... need to add another one before saving (or sending onboarding forms)
    var practiceMembership = false;
    for (var practiceId in localPracticeData)
    {
      if (localPracticeData[practiceId] && localPracticeData[practiceId].isMember)
      {
        practiceMembership = true;
        break;
      }
    }

    if (!practiceMembership)
    {
      alert("At least one practice must be selected for membership.")
      return;
    }

    if (sendOnboardingForms && !window.confirm("Are you sure you want to send new onboarding forms to this provider? This will grant them the ability to edit data from various sections of their profile, including peer references, work history, etc."))
    {
      return;
    }

    setIsSaving(true);

    authenticatedFetch({
      method: "PUT",
      path:  "admin/provider/" + providerId,
      requestData: {
        dataHubs: localHubData,
        reminderPreferences: localReminderPreferenceData,
        practiceAssociations: localPracticeData,
        sendOnboardingForms: sendOnboardingForms,
        includedESignatureTemplates: localESignatureTemplates,
      },
      successHandler: function(result) {
        setIsSaving(false);
        setForceRefresh(forceRefresh + 1);
        window.scrollTo(0, 0);
        notyf.open({type: "success", message: "Provider updated!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  const handleRemove = (e) => {
    if (!window.confirm("Are you sure you want to delete this provider?  Any associated active tasks will be archived and you will no longer have access to the provider's details or historical information. "))
    {
      return;
    }

    setIsSaving(true);

    authenticatedFetch({
      method: "DELETE",
      path:  "admin/provider/" + providerId,
      successHandler: function(result) {
        setIsSaving(false);
        notyf.open({type: "success", message: "Provider deleted!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        onRemove();
      },
      errorHandler: function(error) {
        setIsSaving(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }
  
  return (
    <>
    <Row>
      <Col lg="4">
        <ProviderHubAssignment providerId={providerId} onHubChanged={onHubChanged} canEdit={canEdit} refresh={forceRefresh}/>
        <ProviderReminderPreferenceAssignment providerId={providerId} onReminderPreferenceChanged={onReminderPreferenceChanged} canEdit={canEdit} refresh={forceRefresh}/>
        <ProviderOnboardingUrl providerId={providerId} sendOnboardingForms={sendOnboardingForms} setSendOnboardingForms={setSendOnboardingForms} onESignatureTemplatesChanged={onESignatureTemplatesChanged} canEdit={canEdit} refresh={forceRefresh}/>
      </Col>
      <Col lg="8">
        <ProviderPracticeAssignment providerId={providerId} onPracticeChanged={onPracticeChanged} canEdit={canEdit} refresh={forceRefresh}/>
      </Col>
    </Row>
    <br />
    <Row>
      <Col lg="12">
        { isSaving ?
          <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: '15px'}}/>
        :
        <>
          {canEdit && <Button onClick={(e) => {handleSave(e)}}>Save</Button>}
          &nbsp;&nbsp;
          <Button variant="secondary" onClick={(e) => {onCancel(e)}}>Cancel</Button>
          {canEdit && <Button variant="danger" className="float-end" onClick={(e) => {handleRemove(e)}}><Trash2 style={{'height': '14px', 'width': '14px'}}/>&nbsp;Delete Provider</Button>}
        </>
        }
      </Col>
    </Row>
    </>
  );
};


const ProviderPicker = ({onProviderChanged, isLoaded, error, data}) => {
  var providerList;
  if (!isLoaded)
  {
    providerList = [<option value={0} key={0}>Loading...</option>];
  }
  else if (error || !data)
  {
    providerList = [<option value={0} key={0}>Error... Please reload the page...</option>];
  }
  else
  {
    providerList = [<option value={0} key={0}>Select a provider...</option>];
    providerList.push(data.map((provider, i) => {
      return (
        <option value={provider.id} key={provider.id}>{provider.formattedName}</option>
      )
    }, this));
  }

  return (
    <Row>
      <Col lg="12">
        <Form.Select onChange={(e) => {onProviderChanged(e)}} style={{width: "500px"}} >
          {providerList}
        </Form.Select>
      </Col>
    </Row>
  )
};

const ProviderManagement = () => {

  const [providerId, setProviderId] = useState(0);
  const [providerName, setProviderName] = useState(null);
  
  const [addingNewProvider, setAddingNewProvider] = useState(false);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { isH3Admin, authorities, navbarRefresh, setNavbarRefresh } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("PROVIDER_MANAGEMENT_EDIT");

  useEffect(() => {
    reloadList();
  }, []);


  const reloadList= () => {
    authenticatedFetch({
      path: "admin/provider/providers",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }

  const onProviderChanged = (e) => {
    e.preventDefault();
    setProviderId(e.target.value);
    setProviderName(e.target.options[e.target.selectedIndex].text)
  }

  const handleBack= (e) => {
    e.preventDefault();
    setProviderId(0);
    setProviderName(null);
    setAddingNewProvider(false);
  }

  const handleAddNewProvider= (e) => {
    e.preventDefault();
    setProviderId(0);
    setProviderName(null);
    setAddingNewProvider(true);
  }

  const handleCreateSuccess= () => {
    setProviderId(0);
    setProviderName(null);
    setAddingNewProvider(false);
    reloadList();
    setNavbarRefresh(navbarRefresh + 1);
  }

  const handleRemove= () => {
    setProviderId(0);
    setProviderName(null);
    setAddingNewProvider(false);
    reloadList();
    setNavbarRefresh(navbarRefresh + 1);
  }

  var title = "Provider Management";
  var helmetTitle = title;
  if (providerId > 0)
  {
    title = <>Provider Management | <a href={"/provider/" + providerId}>{providerName}</a></>
    helmetTitle = "Provider Management | " + providerName;
  }
  else if (addingNewProvider)
  {
    title = "Provider Management | Add New Provider";
    helmetTitle = title;
  }

  return (
    <React.Fragment>
      <Helmet title={helmetTitle}/>
      <h1 className="h3 mb-3">{title} 
      
      {!addingNewProvider && providerId === 0 && canEdit &&
      <Button className="float-end btn btn-primary" onClick={(e) => {handleAddNewProvider(e)}}>+ Add New Provider</Button>}
      
      </h1>
      
      {(providerId > 0 && data.length > 1) || addingNewProvider ? <><Button variant="secondary" size="sm" onClick={(e) => {handleBack(e)}}>&lt; Back</Button><br /><br /></> : <></>}
      
      {addingNewProvider ? 
        <ProviderCreation onCancel={handleBack} onSuccess={handleCreateSuccess}/> 
        :
        <>
        <Container fluid className="p-0">
          { providerId > 0 ? <></> : <ProviderPicker isLoaded={isLoaded} error={error} data={data} onProviderChanged={onProviderChanged} /> }    
        </Container>

        {providerId > 0 && <ProviderUpdate providerId={providerId} onCancel={handleBack} onRemove={handleRemove} canEdit={canEdit}/>}
      </>
      }
    </React.Fragment>
  )
};

export default ProviderManagement;
