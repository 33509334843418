import React, { useState } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "./i18n";
import routes from "./routes";

import { UserSettingsProvider } from "./contexts/UserSettingsContext"
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";


const App = () => {
  const content = useRoutes(routes);
  const [user, setUser] = useState({isAuthenticated: false, isH3Admin: false, authorities: [], environment: "dev"})

  const setIsH3Admin = (isH3Admin) => {
    user.isH3Admin = isH3Admin;
    setUser(user);
  };

  const setAuthorities = (authorities) => {
    user.authorities = authorities;
    setUser(user);
  }

  const setEnvironment = (environment) => {
    user.environment = environment;
    setUser(user);
  }

  const setIsAuthenticated = (isAuthenticated) => {
    user.isAuthenticated = isAuthenticated;
    setUser(user);
  }

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="H3 Elevate | %s"
        defaultTitle="H3 Elevate"
      />
      <UserSettingsProvider value={{ user, setIsH3Admin, setAuthorities, setEnvironment, setIsAuthenticated }}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <ChartJsDefaults />
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
        </UserSettingsProvider>
    </HelmetProvider>
  );
};

export default App;
