const stateLicenseTypes =[
  {"value": "", "label": ""},
  {"value":"DO", "label":"DO"},  
  {"value":"EMT", "label":"EMT"},
  {"value":"MD", "label":"MD"},
  {"value":"NP", "label":"NP"},
  {"value":"NURSE_OTHER", "label":"Nurse (Other)"},
  {"value":"OTHER", "label":"Other"},
  {"value":"PA", "label":"PA"},
  {"value":"PT", "label":"PT"},
  {"value":"RESIDENT_TRAINING", "label":"Resident Training"},
  {"value":"RN", "label":"RN"}
  ];

export default stateLicenseTypes;