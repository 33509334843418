import React, { useState, useEffect, useContext } from "react";
import NotyfContext from "../contexts/NotyfContext";

import { Key, ExternalLink} from "react-feather";

import authenticatedFetch from "./fetch"
import LoginCredentialsModal from "./LoginCredentialsModal"
import ContactInfoPopover from "./ContactInfoPopover";
import EditableExpires from "./EditableExpires";
import EditableExpiresControls from "./EditableExpiresControls";

const HubParticipation = ({ onSave, entityType, entityId, entityName, participationId, participationName, phone, fax, email, effectiveDate, expires, portal, canEdit}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
  
    const [editingExpires, setEditingExpires] = useState(false);
    const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);
    const [localExpires, setLocalExpires] = useState(expires);
    
    const notyf = useContext(NotyfContext);
  
    useEffect(() => {
      setLocalEffectiveDate(effectiveDate);
      setLocalExpires(expires);
      setEditingExpires(false);
    }, [participationId, entityType, entityId, effectiveDate, expires]);
  
    function handleClick(portal, e) {
      e.preventDefault();
      window.open(portal, 'newwindow', 'width=800,height=600');
      handleShow();
    }
  
    const handleEditExpiresClicked = (e) =>
    {
      e.preventDefault();
      setEditingExpires(true);
    }
  
    const saveExpires = (e) =>
    {
      e.preventDefault();
      setEditingExpires(false);
  
      authenticatedFetch({
        path: entityType + "/participationstatus/hub/" + entityId + "/" + participationId,
        method: "POST",
        requestData: {effectiveDate: localEffectiveDate, expiration: localExpires},
        successHandler: function() {
          onSave(); // easiest way to update expires (so undo works the next time) is just to have parent re-render us
  
          notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setLocalEffectiveDate(effectiveDate);
          setLocalExpires(expires);
          alert("Something went wrong; please try again");
        }
      });
    }
  
    const cancelEditExpires = (e) => {
      e.preventDefault();
      setEditingExpires(false);
      setLocalEffectiveDate(effectiveDate);
      setLocalExpires(expires);
    }
  
    const handleEffectiveDateChanged = (date, e) => {
      e.preventDefault();
    
      // If they clear it out, date picker sends it back as "", not null
      if (date === "")
      {
        date = null;
      }
  
      setLocalEffectiveDate(date);
    }

    const handleExpiresChanged = (date, e) => {
      e.preventDefault();
    
      // If they clear it out, date picker sends it back as "", not null
      if (date === "")
      {
        date = null;
      }
  
      setLocalExpires(date);
    }

    return (
      <tr>
          <td style={{maxWidth: '110px', paddingRight: '0'}}>
            {portal == null || portal === ''
            ?
            <ContactInfoPopover name={participationName} phone={phone} fax={fax} email={email} />
            :
            <a href="#" onClick={(e) => handleClick(portal, e)}>
              <ExternalLink style={{'height': '12px', 'width': '12px'}}/>&nbsp;<ContactInfoPopover name={participationName} phone={phone} fax={fax} email={email} />
            </a>
            }
          </td>
          <td><Key style={{cursor: 'pointer'}}  onClick={(e) => handleShow()}/></td>
          <td>
            <EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={true} editingExpires={editingExpires} handleExpiresChanged={handleEffectiveDateChanged} />
          </td>
          <td>
            <EditableExpires localExpires={localExpires} localActive={true} editingExpires={editingExpires} handleExpiresChanged={handleExpiresChanged} />
          </td>
          <td>
            {canEdit ?
            <EditableExpiresControls editingExpires={editingExpires} cancelEditExpires={cancelEditExpires} saveExpires={saveExpires} handleEditExpiresClicked={handleEditExpiresClicked} />
            : <>&nbsp;</>
            }
          </td>
          <LoginCredentialsModal isHub={true} hubType="hub" show={show} onClose={handleClose} entityType={entityType} entityId={entityId} entityName={entityName} carrierName={participationName} carrierId={participationId} canEdit={canEdit} />
      </tr>
    );
  };

export default HubParticipation;