import React, {useState} from "react";
import Select from "react-select";

const SelectAll = ({options, selectedItems, handleOptionChanged, placeholder="Search..."}) => {
    const SELECT_ALL = "(Select All)";
    const DESELECT_ALL = "(Deselect All)";

    const [selectAllLabel, setSelectAllLabel] = useState(SELECT_ALL);

    const customStyles = {
        valueContainer: (styles) => ({
            ...styles,
            maxHeight: "100px",
            overflowY: "auto"
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0 2px 0 0",
        }),
        clearIndicator: (styles) => ({
            ...styles,
            padding: 0,
        }),
    }

    // Prepend a select-all option to the list
    options.unshift({value: 0, label: selectAllLabel});

    return (
        <div style={{width: "418px"}}>
            <Select
            options={options}
            styles={customStyles}
            onChange={(selectedOptions, actionMeta) => {
                const { action, option, removedValue } = actionMeta;

                if (action === "select-option" && option.value === 0) 
                {
                    if (selectAllLabel === SELECT_ALL)
                    {
                        // Select all, except for the first option
                        selectedOptions = options.slice(1);
                        setSelectAllLabel(DESELECT_ALL);
                    }
                    else
                    {
                        selectedOptions = [];
                        setSelectAllLabel(SELECT_ALL);
                    }

                }
                else if (action === "select-option" && selectedOptions.length === options.length - 1)
                {
                    setSelectAllLabel(DESELECT_ALL);
                }
                else if (action === "remove-value" || action === "pop-value" || action === "clear")
                {
                    if (selectAllLabel === DESELECT_ALL)
                    {
                        // We had all selected, but now we're removing something, so re-enable the select-all option
                        setSelectAllLabel(SELECT_ALL);
                    }
                }

                handleOptionChanged(selectedOptions);
            }}
            value={selectedItems}
            placeholder={placeholder}
            isMulti
            theme={(theme) => ({
                ...theme,
                colors: {
                ...theme.colors,
                  primary50: '#DEF2F1',
                  primary25: '#DEF2F1',
                  primary: '#3AAFA9',
                },
              })}
            />
    </div>
    )
};


export default SelectAll;
