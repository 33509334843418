
import { Check, X, Edit2} from "react-feather";


const EditableExpiresControls = ({ editingExpires, cancelEditExpires, saveExpires, handleEditExpiresClicked }) => {
    return (
      <>
      { editingExpires
      ?
      <>
        <X style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {cancelEditExpires(e)}} />
        &nbsp;<Check style={{'height': '16px', 'width': '16px'}} cursor="pointer" onClick={(e) => {saveExpires(e)}} className="text-success"/>
      </>
      :
      <Edit2 style={{'height': '12px', 'width': '12px'}} cursor="pointer" onClick={(e) => {handleEditExpiresClicked(e)}}/>
      }
      </>
    );
  };

export default EditableExpiresControls;