import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "react-bootstrap";

import { UserSettingsContext } from "../../contexts/UserSettingsContext";

import authenticatedFetch from "../../utils/fetch"

import { Settings, User } from "react-feather";

import doctor1 from "../../assets/img/avatars/logo_unknown_doctor.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";

const NavbarUser = ({onRevenueTeamSelectionScreen=false}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { setIsAuthenticated, setIsH3Admin, setAuthorities, isH3Admin, navbarRefresh } = useContext(UserSettingsContext);

  useEffect(() => {
    authenticatedFetch({
      path: "user",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });
  }, [navbarRefresh])

  const handleLogout = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    authenticatedFetch({
      method: 'POST',
      path: "auth/logout",
      successHandler: function(result) {
        setIsAuthenticated(false);
        setIsH3Admin(false);
        setAuthorities([]);
        window.location = "/auth/sign-in";
      },
      errorHandler: function(error) {
        setIsAuthenticated(false);
        setIsH3Admin(false);
        setAuthorities([]);
        window.location = "/auth/sign-in";
      }
    });
    
  }

  const handleRevenueTeamChooser = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    
    window.location = "/h3admin/revenueteams"
  }

  const handleProfile = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    
    window.location = "/profile"
  }

  const handlePrivacy = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    
    window.open('https://artifacts.h3althcare.com/privacy.html', '_blank').focus();
    
  }

  if (!isLoaded)
  {
    return (<Dropdown.Toggle as="a" className="nav-link"><span>Loading...</span></Dropdown.Toggle>);
  }
  else if (error || !data)
  {
    return (<Dropdown.Toggle as="a" className="nav-link"><span>Error... Please reload the page...</span></Dropdown.Toggle>);
  }
  else
  {
    return (
      <Dropdown className="nav-item" align="end">
        <span className="d-inline-block d-sm-none">
          <Dropdown.Toggle as="a" className="nav-link">
            <Settings size={18} className="align-middle" />
          </Dropdown.Toggle>
        </span>
        <span className="d-none d-sm-inline-block">
          <Dropdown.Toggle as="a" className="nav-link">
            <img
              src={data.photoUrl == null || data.photoUrl === '' ? doctor1 : data.photoUrl}
              className="avatar img-fluid rounded-circle me-1"
              alt={data.firstName + " " + data.lastName}
            />
            <span className="text-dark">{data.firstName} {data.lastName}</span>
          </Dropdown.Toggle>
        </span>
        <Dropdown.Menu drop="end">
          { ! onRevenueTeamSelectionScreen &&
          <Dropdown.Item onClick={(e) => handleProfile()}>
            <User size={18} className="align-middle me-2" />
            Profile
          </Dropdown.Item>
          }
          {isH3Admin && !onRevenueTeamSelectionScreen ? 
          <Dropdown.Item onClick={(e) => handleRevenueTeamChooser()}>
            <FontAwesomeIcon icon={faFunnelDollar} fixedWidth className="align-middle me-2" />
            Revenue Teams</Dropdown.Item>
          : <></>
          }
          { ! onRevenueTeamSelectionScreen &&
          <Dropdown.Divider />
          }
          <Dropdown.Item onClick={(e) => handlePrivacy()}>Settings & Privacy</Dropdown.Item>
          {/* <Dropdown.Item>Help</Dropdown.Item> */}
          <Dropdown.Item onClick={(e) => handleLogout()}>Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default NavbarUser;
