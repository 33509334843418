import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form, Spinner, ButtonGroup, ToggleButton } from "react-bootstrap";
import TaskAssigneeSelect from "./TaskAssigneeSelect";
import SelectAll from "./SelectAll";

import rawTaskStatuses from "./taskStatuses";

import authenticatedFetch from "../utils/fetch";

const PracticeSelect = ({ handlePracticeChanged, selectedPractices}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        authenticatedFetch({
            path: "practice/revenueteam",
                successHandler: function(result) {
                setIsLoaded(true);
                setData(result);
            },
            errorHandler: function(error) {
                setIsLoaded(true);
                setError(error);
            }
        });
    }, [])
  
    if (!isLoaded)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = data.map((practice) => {
            return {value: practice.id, label: practice.name};
        });

        return (
            <SelectAll options={options} selectedItems={selectedPractices} handleOptionChanged={handlePracticeChanged} placeholder="Search practice(s)..." />
        )
    }
}

const TaskLevelSelect = ({ selectedTaskLevel, handleTaskLevelChanged}) => {
    return (
        <ButtonGroup className="me-auto">
            <ToggleButton
                key="PRACTICE"
                id="PRACTICE"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskLevel === "PRACTICE" ? "white" : "", zIndex: 0}}
                value="PRACTICE"
                checked={selectedTaskLevel === "PRACTICE"}
                onChange={(e) => {handleTaskLevelChanged(e.target.value)}}
            >Practice</ToggleButton>
            <ToggleButton
                key="PROVIDER"
                id="PROVIDER"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskLevel === "PROVIDER" ? "white" : "", zIndex: 0}}
                value="PROVIDER"
                checked={selectedTaskLevel === "PROVIDER"}
                onChange={(e) => {handleTaskLevelChanged(e.target.value)}}
            >Provider</ToggleButton>
        </ButtonGroup>
    )
}

const ProviderSelect = ({ practiceIds, handleProviderChanged, selectedProviders}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
        authenticatedFetch({
            path: "provider/practices/" + practiceIds.join(","),
            successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
            },
            errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
            }
        });
    }, [practiceIds])
  
    if (!isLoaded)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = data.map((provider) => {
            return {value: provider.id, label: provider.lastName + ", " + provider.firstName + " " + (provider.middleName === null ? "" : provider.middleName)};
        });

        return (
            <SelectAll options={options} selectedItems={selectedProviders} handleOptionChanged={handleProviderChanged} placeholder="Search provider(s)..." />
        )
    }
}

const TaskTypeSelect = ({ selectedTaskType, handleTaskTypeChanged}) => {
    return (
        <ButtonGroup className="me-auto">
            <ToggleButton
                key="CARRIER"
                id="CARRIER"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskType === "CARRIER" ? "white" : "", zIndex: 0}}
                value="CARRIER"
                checked={selectedTaskType === "CARRIER"}
                onChange={(e) => {handleTaskTypeChanged(e.target.value)}}
            >Carrier</ToggleButton>
            <ToggleButton
                key="FACILITY"
                id="FACILITY"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskType === "FACILITY" ? "white" : "", zIndex: 0}}
                value="FACILITY"
                checked={selectedTaskType === "FACILITY"}
                onChange={(e) => {handleTaskTypeChanged(e.target.value)}}
            >Facility</ToggleButton>
            <ToggleButton
                key="DATA_HUB"
                id="DATA_HUB"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskType === "DATA_HUB" ? "white" : "", zIndex: 0}}
                value="DATA_HUB"
                checked={selectedTaskType === "DATA_HUB"}
                onChange={(e) => {handleTaskTypeChanged(e.target.value)}}
            >Data Hub</ToggleButton>
            <ToggleButton
                key="OTHER"
                id="OTHER"
                type="radio"
                variant="outline-primary"
                style={{color: selectedTaskType === "OTHER" ? "white" : "", zIndex: 0}}
                value="OTHER"
                checked={selectedTaskType === "OTHER"}
                onChange={(e) => {handleTaskTypeChanged(e.target.value)}}
            >Other</ToggleButton>
        </ButtonGroup>
    )
}

const CarrierSelect = ({ practiceIds, handleCarrierChanged, selectedCarriers}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
        authenticatedFetch({
            path: "carrier/practices/" + practiceIds.join(","),
            successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
            },
            errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
            }
        });
    }, [practiceIds])
  
    if (!isLoaded)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = data.map((carrier) => {
            return {value: carrier.id, label: carrier.name};
        });

        return (
            <SelectAll options={options} selectedItems={selectedCarriers} handleOptionChanged={handleCarrierChanged} placeholder="Search carrier(s)..." />
        )
    }
}

const DataHubSelect = ({ taskLevel, ids, handleDataHubChanged, selectedDataHubs}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    const path = taskLevel === "PRACTICE" ? "hub/practices/" : "hub/providers/";

    useEffect(() => {
        authenticatedFetch({
            path: path + ids.join(","),
            successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
            },
            errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
            }
        });
    }, [ids])
  
    if (!isLoaded)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = data.map((hub) => {
            return {value: hub.id, label: hub.name};
        });

        return (
            <SelectAll options={options} selectedItems={selectedDataHubs} handleOptionChanged={handleDataHubChanged} placeholder="Search data hub(s)..." />
        )
    }
}

const FacilitySelect = ({ practiceIds, handleFacilityChanged, selectedFacilities}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
        authenticatedFetch({
            path: "facility/practices/" + practiceIds.join(","),
            successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
            },
            errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
            }
        });
    }, [practiceIds])
  
    if (!isLoaded)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
    }
    else if (error || !data)
    {
      return (<Form.Select style={{height: "40px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
    }
    else
    {
        const options = data.map((facility) => {
            return {value: facility.id, label: facility.name};
        });

        return (
            <SelectAll options={options} selectedItems={selectedFacilities} handleOptionChanged={handleFacilityChanged} placeholder="Search facility(s)..." />
        )
    }
}


const TaskBuilderModal = ({show, onHide, onSave}) => {
    const [practiceIds, setPracticeIds] = useState([]);
    const [selectedPractices, setSelectedPractices] = useState([]);
    const [taskLevel, setTaskLevel] = useState("PRACTICE");
    const [providerIds, setProviderIds] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [taskType, setTaskType] = useState("CARRIER");
    const [carrierIds, setCarrierIds] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [facilityIds, setFacilityIds] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [dataHubIds, setDataHubIds] = useState([]);
    const [selectedDataHubs, setSelectedDataHubs] = useState([]);
    const [other, setOther] = useState("");
    const [status, setStatus] = useState("CREATED");
    const [due, setDue] = useState(null);
    const [deadline, setDeadline] = useState("");
    const [assignees, setAssignees] = useState(null);
    const [note, setNote] = useState("");
    const [category, setCategory] = useState("");

    const [isSaving, setIsSaving] = useState(false);


    if (due === null)
    {
        let twoBizDays = new Date();
        twoBizDays.setDate(twoBizDays.getDate() + 2);

        if (twoBizDays.getDay() === 6 || twoBizDays.getDay() === 0)
        {
            twoBizDays.setDate(twoBizDays.getDate() + 2);
        }

        setDue(twoBizDays.getFullYear() + "-" + String(twoBizDays.getMonth() + 1).padStart(2, '0') + "-" + String(twoBizDays.getDate()).padStart(2, '0'));
    }

    const reset = () => {
        setPracticeIds([]);
        setSelectedPractices([]);
        setTaskLevel("PRACTICE");
        setProviderIds([]);
        setSelectedProviders([]);
        setTaskType("CARRIER");
        setCarrierIds([]);
        setSelectedCarriers([]);
        setFacilityIds([]);
        setSelectedFacilities([]);
        setDataHubIds([]);
        setSelectedDataHubs([]);
        setOther("");
        setStatus("CREATED");
        setDue(null);
        setDeadline("");
        setAssignees(null);
        setNote("");
        setCategory("");
    }

    const handlePracticeChanged = (practices, e) => {
        setSelectedPractices(practices);
  
        let newPracticeIds = []
        for (var i=0; i<practices.length; i++)
        {
          newPracticeIds.push(practices[i].value);
        }
  
        setPracticeIds(newPracticeIds);

        // Leave the level and task type, but have to clear the lists that are driven by practice IDs

        setProviderIds([]);
        setSelectedProviders([]);
        setCarrierIds([]);
        setSelectedCarriers([]);
        setFacilityIds([]);
        setSelectedFacilities([]);
        setDataHubIds([]);
        setSelectedDataHubs([]);
        setOther("");
        setStatus("CREATED");
        setDue(null);
        setDeadline("");
        setAssignees(null);
        setNote("");
        setCategory("");
    }

    const handleTaskLevelChanged = (value) => {
        setTaskLevel(value);

        setProviderIds([]);
        setSelectedProviders([]);
        setTaskType("CARRIER");
        setCarrierIds([]);
        setSelectedCarriers([]);
        setFacilityIds([]);
        setSelectedFacilities([]);
        setDataHubIds([]);
        setSelectedDataHubs([]);
        setOther("");
        setStatus("CREATED");
        setDue(null);
        setDeadline("");
        setAssignees(null);
        setNote("");
        setCategory("");
    }

    const handleProviderChanged = (providers, e) => {
        setSelectedProviders(providers);
  
        let newProviderIds = []
        for (var i=0; i<providers.length; i++)
        {
            newProviderIds.push(providers[i].value);
        }
  
        setProviderIds(newProviderIds);
    }

    const handleTaskTypeChanged = (value) => {
        setTaskType(value);

        setCarrierIds([]);
        setSelectedCarriers([]);
        setFacilityIds([]);
        setSelectedFacilities([]);
        setDataHubIds([]);
        setSelectedDataHubs([]);
        setOther("");
        setStatus("CREATED");
        setDue(null);
        setDeadline("");
        setAssignees(null);
        setNote("");
        setCategory("");
    }

    const handleCarrierChanged = (carriers, e) => {
        setSelectedCarriers(carriers);
  
        let newCarrierIds = []
        for (var i=0; i<carriers.length; i++)
        {
            newCarrierIds.push(carriers[i].value);
        }
  
        setCarrierIds(newCarrierIds);
    }

    const handleFacilityChanged = (facilities, e) => {
        setSelectedFacilities(facilities);
  
        let newFacilityIds = []
        for (var i=0; i<facilities.length; i++)
        {
            newFacilityIds.push(facilities[i].value);
        }
  
        setFacilityIds(newFacilityIds);
    }

    const handleDataHubChanged = (dataHubs, e) => {
        setSelectedDataHubs(dataHubs);
  
        let newDataHubIds = []
        for (var i=0; i<dataHubs.length; i++)
        {
            newDataHubIds.push(dataHubs[i].value);
        }
  
        setDataHubIds(newDataHubIds);
    }

    const handleOtherChanged = (value, e) => {
        setOther(value);
    }

    const handleStatusChanged = (status, e) => {
        setStatus(status);
    }

    const handleDueChanged = (due, e) => {
        if (e != null) {
          e.preventDefault();
        }
    
        setDue(due);
      }
  
    const handleDeadlineChanged = (deadline, e) => {
        if (e != null) {
          e.preventDefault();
        }
    
        setDeadline(deadline);
    }

    const handleAssigneesChanged = (assignees) => {
        setAssignees(assignees);
    }

    const handleNoteChanged = (note, e) => {
        setNote(note);
    }

    const handleCategoryChanged = (category, e) => {
        setCategory(category);
    }

    const handleCancel = () => {
        reset();
        onHide();
    }

    const handleSave = () => {

        setIsSaving(true);

        if (!(practiceIds.length > 0 && (taskLevel === "PRACTICE" || (taskLevel === "PROVIDER" && providerIds.length > 0))))
        {
            // Shouldn't happen - we're checking below before we render the UI; don't bother with a message
            setIsSaving(false);
            return;
        }

        if (status === "")
        {
            alert("Please select a status");
            setIsSaving(false);
            return;
        }

        if (category === "")
        {
            alert("Please select a category");
            setIsSaving(false);
            return;
        }

        if (taskType === "CARRIER" && carrierIds.length === 0)
        {
            alert("Please select at least one carrier");
            setIsSaving(false);
            return;   
        }
        if (taskType === "FACILITY" && facilityIds.length === 0)
        {
            alert("Please select at least one facility");
            setIsSaving(false);
            return;   
        }
        if (taskType === "DATA_HUB" && dataHubIds.length === 0)
        {
            alert("Please select at least one data hub");
            setIsSaving(false);
            return;   
        }
        if (taskType === "OTHER" && other === "")
        {
            alert("Please enter a value for other");
            setIsSaving(false);
            return;
        }

        if (!window.confirm("Create task(s)?"))
        {
            setIsSaving(false);
            return;
        }

        const requestData = {
            practiceIds: practiceIds,
            taskLevel: taskLevel,
            providerIds: providerIds,
            taskType: taskType,
            carrierIds: carrierIds,
            category: category,
            facilityIds: facilityIds,
            dataHubIds: dataHubIds,
            other: other,
            status: status,
            nextAction: due,
            deadline: deadline,
            assignees: assignees,
            note: note
          };
      
          authenticatedFetch({
            path: "task/builder",
            method: "POST",
            requestData: requestData,
            successHandler: function(result) {
                var message = result.countSuccessful + " task(s) were created!";

                if (result.unsuccessful.length > 0)
                {
                    message += "\n\nThe following combinations were not possible:\n";
                    for (var i=0; i<result.unsuccessful.length; i++)
                    {
                        message += result.unsuccessful[i] + "\n";
                    }
                }

                alert(message);

                setIsSaving(false);
                reset();
                onSave();
            },
            errorHandler: function() {
              alert("Something went wrong; please try again");
              setIsSaving(false);
            }
          });
    }

    const readyForTaskTypeOptions = practiceIds.length > 0 && (taskLevel === "PRACTICE" || (taskLevel === "PROVIDER" && providerIds.length > 0));

    let statusOptionList = rawTaskStatuses.map((status, i) => {
        return (
          <option value={status.value} key={status.value}>{status.label}</option>
        )
      }, this);

    return (
        <Modal show={show} onHide={handleCancel} centered>
            <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
                <Modal.Title>
                Task Builder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table> 
                    <tbody>
                    <tr>
                        <td style={{width: "125px"}}>Practice(s):</td>
                        <td><PracticeSelect handlePracticeChanged={handlePracticeChanged} selectedPractices={selectedPractices}/></td>
                    </tr>
                    {
                        practiceIds.length > 0 &&
                        <tr>
                            <td>Level:</td>
                            <td><TaskLevelSelect selectedTaskLevel={taskLevel} handleTaskLevelChanged={handleTaskLevelChanged}/></td>    
                        </tr>
                    }
                    {
                        practiceIds.length > 0 && taskLevel === "PROVIDER" &&
                        <tr>
                            <td>Provider(s):</td>
                            <td><ProviderSelect practiceIds={practiceIds} handleProviderChanged={handleProviderChanged} selectedProviders={selectedProviders}/></td>
                        </tr>
                    }
                    {
                        readyForTaskTypeOptions &&
                        <tr>
                            <td>Type:</td>
                            <td><TaskTypeSelect selectedTaskType={taskType} handleTaskTypeChanged={handleTaskTypeChanged}/></td>
                        </tr>
                    }
                    {
                        readyForTaskTypeOptions && taskType === "CARRIER" &&
                        <tr>
                            <td>Carrier(s):</td>
                            <td><CarrierSelect practiceIds={practiceIds} handleCarrierChanged={handleCarrierChanged} selectedCarriers={selectedCarriers}/></td>
                        </tr>
                    }
                    {
                        readyForTaskTypeOptions && taskType === "FACILITY" &&
                        <tr>
                            <td>Facility(s):</td>
                            <td><FacilitySelect practiceIds={practiceIds} handleFacilityChanged={handleFacilityChanged} selectedFacilities={selectedFacilities}/></td>
                        </tr>
                    }
                    {
                        readyForTaskTypeOptions && taskType === "DATA_HUB" &&
                        <tr>
                            <td>Data Hub(s):</td>
                            <td><DataHubSelect taskLevel={taskLevel} ids={taskLevel === "PRACTICE" ? practiceIds : providerIds} handleDataHubChanged={handleDataHubChanged} selectedDataHubs={selectedDataHubs}/></td>
                        </tr>
                    }
                    {readyForTaskTypeOptions &&
                        <>
                        <tr>
                            <td>Category:</td>
                            <td>
                            <Form.Select style={{height: "40px"}} onChange={(e) => {handleCategoryChanged(e.target.value, e)}} value={category}>
                                <option value="" key="">Select task category...</option>
                                <option value="INITIAL_ENROLLMENT" key="INITIAL_ENROLLMENT">Initial Enrollment</option>
                                <option value="ANNUAL_ENROLLMENT" key="ANNUAL_ENROLLMENT">Re-enrollment</option>
                                <option value="ATTESTATION" key="ATTESTATION">Attestation</option>
                                <option value="INQUIRY" key="INQUIRY">Inquiry</option>
                                <option value="TERMINATION" key="TERMINATION">Termination</option>
                                <option value="OTHER" key="OTHER">Other</option>
                            </Form.Select>
                            </td>
                        </tr>
                        <tr>
                            <td>Other:</td>
                            <td><Form.Control style={{height: "38px"}} onChange={(e) => {handleOtherChanged(e.target.value, e)}} value={other} /></td>
                        </tr>
                        <tr>
                        <td>Status:</td>
                        <td>
                            <Form.Select style={{height: "40px"}} onChange={(e) => {handleStatusChanged(e.target.value, e)}} value={status}>
                            {statusOptionList}
                            </Form.Select>
                        </td>
                        </tr>
                        <tr>
                            <td>Next Action:</td>
                            <td>
                                <Form.Control onChange={(e) => {handleDueChanged(e.target.value, e)}} type="date" value={due} placeholder="Next action date" />
                            </td>
                        </tr>
                        <tr>
                            <td>Deadline:</td>
                            <td>
                                <Form.Control onChange={(e) => {handleDeadlineChanged(e.target.value, e)}} type="date" value={deadline} placeholder="Deadline" />
                            </td>
                        </tr>
                        <tr>
                            <td>Assignees:</td>
                            <td><TaskAssigneeSelect autoSelectFirstAssignee={true} disabled={false} selectedAssignees={assignees} handleAssigneesChanged={handleAssigneesChanged} /></td>
                        </tr>
                        <tr>
                            <td>Initial Note:</td>
                            <td><Form.Control key={0} onChange={(e) => {handleNoteChanged(e.target.value, e)}} value={note} as="textarea" placeholder="Add new note..."/></td>
                        </tr>
                        </>
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            { isSaving ? 
              <Spinner animation="border" size="sm" variant="primary" className="float-end" style={{marginRight: '40px', marginTop: '11px', marginBottom: '11px'}}/>
              :
              <>
                <Button onClick={handleCancel}>Cancel</Button>
                {readyForTaskTypeOptions && <Button onClick={handleSave}>Create Task(s)</Button>}
              </>
            }
          </Modal.Footer>
        </Modal>
    )
}

export default TaskBuilderModal;