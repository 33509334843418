import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Flag } from "react-feather";
import getOnboardingStages from "../../utils/onboardingStages";
import Spinner from 'react-bootstrap/Spinner';

const { REACT_APP_BACKEND_URL } = process.env;


const OnboardingImmunizations = ({stage, token, data}) => {
  let onboardingStages = getOnboardingStages(data.preferences);
  
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        immunizationFilenames: data.immunizationFilenames == null ? "" : data.immunizationFilenames,
        immunizationFiles: null,
      }}
      validationSchema={Yup.object().shape({
        immunizationFiles: Yup.mixed()
        .test(
          "fileSize",
          "File size(s) too large; max total of 50MB",
          (files) => {
            var sum = 0;
            if (files) 
            {
              for (var i = 0; i < files.length; i++) {
                sum += files[i].size;
              }
              return sum <= (10485760 * 5);  /// Cap it at ~50MB total
              
            }
            return true;
          }
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, resetForm, setSubmitting }) => {
        try 
        {
          var formData = new FormData();
          if (values.immunizationFiles)
          {
            for (var i = 0; i < values.immunizationFiles.length; i++) 
            {
              formData.append("immunizationFiles", values.immunizationFiles[i]);
            }
          }

          const requestMetadata = {
            method: 'POST',
            body: formData
          };

          const res = await fetch(REACT_APP_BACKEND_URL + "onboarding/" + stage + "/" + token, requestMetadata);

          if (res.status >= 200 && res.status <= 299)
          {
            setStatus({success: true})

            window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) + 1] + "/" + token
          }
          else
          {
            throw Error(res.statusText);
          }
        } catch (error) {
          const message = "Something went wrong; please try again";

          setStatus({ success: false });
          alert(message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status,
        touched,
        values,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label style={{fontWeight: "bold"}}>Immunizations
            <br /><span style={{fontSize: '0.9em', fontWeight: "normal"}}><Flag style={{height: "16px", width: "16px", color: "#2B7A78"}} /> Please upload documentation of the following immunizations, if available:</span>
            <ul>
              <li>MMR</li>
              <li>Hepatitis B</li>
              <li>Varicella</li>
              <li>COVID-19</li>
              <li>Tuberculosis</li>
              <li>Influenza</li>
            </ul>
            </Form.Label>
            <br />
            {values.immunizationFilenames && 
              <Form.Label>(Current: <span style={{wordBreak: "break-all"}}>{values.immunizationFilenames.join(", ")}</span>)</Form.Label>
            }
            <Form.Control
              type="hidden"
              {...getFieldProps("immunizationFilenames")}
            />
            <Form.Control
              multiple
              type="file"
              name="immunizationFiles"
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue("immunizationFiles", event.currentTarget.files);
              }}
              isInvalid={Boolean(touched.immunizationFiles && errors.immunizationFiles)}
            />
            {!!touched.immunizationFiles && (
              <Form.Control.Feedback type="invalid">
                {errors.immunizationFiles}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <br />
          {isSubmitting ?
          <div className="text-center">
             <br />
            <Spinner size="sm" animation="border" />
          </div>
          :
          <>
          <div className="text-center mt-3 float-start">
            <Button size="lg" className="btn-secondary" onClick={() => {window.location = "/onboarding/" + onboardingStages[onboardingStages.indexOf(stage) - 1] + "/" + token}}>Back</Button>
          </div>
          <div id="submitButton" className="text-center mt-3 float-end">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}  // Don't check touched, otherwise you end up with this button disabled for, e.g. a peer reference first name missing that has never been touched, so it's not in red; let them click this to touch everything
            >
              Save and Continue
            </Button>
          </div>
          </>
          }
          <br /><br /><br />
          {
            Object.keys(errors).length > 0 && Object.keys(touched).length > 0 &&
            <div className="text-center mt-3"><span className="invalid-feedback" style={{display: "inline-block"}}>Please correct the errors above before moving forward</span></div>
          }
        </Form>
      )}
    </Formik>
  );
}

export default OnboardingImmunizations;
